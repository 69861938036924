import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap"
import Images from "../../helpers/images"
import { Link } from "react-router-dom"
import BottomBar from "./bottomBar"
import { isMobile } from "react-device-detect";
import { useState } from "react";
import { hasError, showToast } from "../../helpers/api/utils";
import { subscribeEmail } from "../../helpers";
import toast from "react-hot-toast";

const Footer = () => {

    const [email, setEmail] = useState('');
    const [validationErrors, setValidationErrors] = useState([]);
    const [inlineLoader, setInlineLoader] = useState(false);

    const handleChanges = (value) => {
        setValidationErrors([]);
        setEmail(value)
    }


    const checkValidations = (data) => {
        let errors = [];
        const RegexEmailValidation = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;;
        if (!(RegexEmailValidation.test(data))) {
            const error = {
                error: "Please provide your valid email ",
                key: "email"
            };
            errors.push(error);
        }
        if (errors.length > 0) {
            setValidationErrors(errors);
        }
        return errors;

    }

// used to handle the subscribe button
    const handleSubscribe = async () => {
        toast.dismiss();
        const hasError = checkValidations(email);
        if (hasError?.length > 0) {
            return false;
        }
        setInlineLoader(true);
        try {
          const {data} =   await subscribeEmail({ email });
          console.log(data);
          if(data?.status){
            showToast("success", "Subscribed successfully");
          }
          else{
            showToast("success", data?.Message);
          }
            setEmail('');
            setInlineLoader(false);
         
        }
        catch (error) {
            setInlineLoader(false);
            if (error.status === 422) {
                setValidationErrors([error?.error]);
                showToast('error', "Please enter valid email")
            } else {
                showToast('error', error);
            }
        }
    }
  return (
        <>
            <div className="footer-wrapper">
                <Container>
                    <Row>
                        <Col md={6} lg={6}>
                            <div className="footer-logo">
                                <Link to='/'>
                                    <img src={Images.LogoWhite} className='img-fluid' alt='' />
                                </Link>
                                <p>Subscribe for Deals & Offers​</p>
                            </div>
                            <div className="email-form">
                                <Form.Control type="email" value={email} placeholder="Enter your email" onChange={(e) => handleChanges(e.target.value)} />
                                <Button onClick={handleSubscribe}>  {!inlineLoader ? 'Subscribe' : (<Spinner animation="border" size="sm" />)}</Button>

                            </div>
                            {hasError(validationErrors, "email")}
                            <div className="footer-link">
                                <ul>
                                    <li><Link to='/'> Home</Link></li>
                                    <li><Link to='/about-us'> About us</Link></li>
                                    <li><Link to='/faq'> FAQs</Link></li>
                                    <li><Link to='/reviews'> Reviews</Link></li>
                                    <li><Link to='/products'> Shop</Link></li>
                                    <li><Link to='/contact-us'> Contact us</Link></li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={3} lg={3}>
                            <div className="social-link">
                                <ul>
                                    <li> <span> <img src={Images.whatsapp} className='img-fluid' alt='' /></span> <a href='https://wa.me/8792911987'>+91-8792911987</a></li>
                                    <li> <span><img src={Images.instagram} className='img-fluid' alt='' /></span> <a target="_blank" href='https://www.instagram.com/furrfrendzindia/'>@FurrFrendz</a></li>
                                    <li> <span><img src={Images.facebook} className='img-fluid' alt='' /></span> <a target="_blank" href='https://www.facebook.com/people/furrfrendzindia/100094438221419/'>@FurrFrendz</a></li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={3} lg={3}>
                            <div className="footer-dog">
                                <img src={Images.dogFooter} className='img-fluid' alt='' />
                            </div>
                        </Col>
                    </Row>
                    <div className="footer-bottom">
                        <Row>
                            <Col md={6} lg={6}>
                                <p> © 2023 FurrFrendz | Life is better with pets</p>
                            </Col>
                            <Col lg={6} md={6}>
                                <ul>
                                    <li><Link to='/legal?title=privacy-policy'>Privacy Policy</Link></li>
                                    <li><Link to='/legal?title=shipping-policy'>Shipping Policy</Link></li>
                                    <li><Link to='/legal?title=refund-policy'>Refund policy</Link></li>
                                </ul>
                            </Col>
                            
                        </Row>
                    </div>
                </Container>
            </div>
            {
                isMobile ? (
                    <BottomBar />
                ) : (
                    <></>
                )
            }
        </>
    )
}

export default Footer