import React from "react";
import { Suspense } from "react";
import PageLoader from "../../comman/PageLoader";
import DiscountModel from "../../comman/DiscountModel";
import { useEffect } from "react";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import { clearAction, resetAuth } from "../../../redux/actions";
import { APICore } from "../../../helpers/api/apiCore";
const Footer = React.lazy(() => import("../../comman/footer"));
const Header = React.lazy(() => import("../../comman/header"));
const HomeSlider = React.lazy(() => import("../../comman/homeSlider"));
const CustomersReview = React.lazy(() => import("./customersReview"));
const EatingHealthy = React.lazy(() => import("./eatingHealthy"));
const ReferFriendAndWinDiscount = React.lazy(() =>
  import("./referFriendAndWinDiscount")
);
const ShopByCategory = React.lazy(() => import("./shopByCategory"));
const TrendingProducts = React.lazy(() => import("./trendingProducts"));
const VeterinarianTestimonials = React.lazy(() =>
  import("./veterinarianTestimonials")
);
const WorkPlan = React.lazy(() => import("./workPlan"));

const Home = () => {



  return (
    <>
      <Suspense fallback={<PageLoader loading={true}></PageLoader>}>
        <Header />
        <HomeSlider />
        <ReferFriendAndWinDiscount />
        <ShopByCategory />
        {/* <TrendingProducts /> */}
        <EatingHealthy />
        <DiscountModel />
        <CustomersReview limit={3} />
        <VeterinarianTestimonials />
        <WorkPlan />
        <Footer />
      </Suspense>
    </>
  );
};
export default Home;
