// constants

import { ProductActionTypes } from "./constants";



const INIT_STATE = {
  products: {},
  message: {},
  name:'',
  actionPerformed: false,
  loading: false,
};

const Product = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ProductActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload?.actionType) {
        case ProductActionTypes.PRODUCT_LIST: {

          return {
            ...state,
            products: action.payload?.responseData?.data,
            page: action.payload?.responseData?.page,
            name: action.payload?.responseData?.name,
            limit: action.payload?.responseData?.limit,
            totalPages: action.payload?.responseData?.totalPages,
            totalResults: action.payload?.responseData?.totalResults,
          };
        }

        case ProductActionTypes.RESET_ACTION: {
          return {
            ...state,
            message: {},
            actionPerformed: false,
            loading: false,
            error: [],
          };
        }
        case ProductActionTypes.API_RESPONSE_ERROR:
          switch (action.payload?.actionType) {
            case ProductActionTypes.PRODUCT_LIST: {
              return {
                ...state,
                error: action.payload.error,
                hasErrors: true,
                loading: true,
                submitted: false,
              };
            }
            case ProductActionTypes.UPDATE_PRODUCT: {
              return {
                ...state,
                error: action.payload?.error,
                validationError: action.payload?.error,
                loading: false,
                message: {},
                actionPerformed: false,
              };
            }
            case ProductActionTypes.UPDATE_CURRENT_PROFILE: {
              return {
                ...state,
                error: action.payload?.error,
                validationError: action.payload?.error,
                hasErrors: true,
                loading: true,
                submitted: true,
              };
            }
            case ProductActionTypes.UPDATE_ALL_PRODUCT: {
              return {
                ...state,
                error: action.payload?.error,
                validationError: action.payload?.error,
                hasErrors: true,
                loading: false,
                submitted: true,
              };
            }
            default:
              return { ...state };
          }
        default:
          return { ...state };
      }

    case ProductActionTypes.API_RESPONSE_ERROR:
      switch (action.payload?.actionType) {
        case ProductActionTypes.PRODUCT_LIST: {
          return {
            ...state,
            error: action.payload.error,
            hasErrors: true,
            loading: true,
            submitted: false,
          };
        }
        default:
          return { ...state };
      }
    default:
      return { ...state };
  }
};

export default Product;
