import { Modal } from "react-bootstrap";
import Images from "../../helpers/images";
import { useState } from "react";
import { Form } from 'react-bootstrap';
import ReactDatePicker from "react-datepicker";
import { hasError } from "../../helpers/api/utils";
import { discountCoupon } from "../../helpers";
import moment from "moment";



const DiscountUserDetails = ({ handleChange, formData, handleNext, validationErrors, setCurrentStep, showModel, setShowModel, loading }) => {
  const currentDate = new Date(); // Get the current date
  const maxDateStringForDisplay = moment(currentDate).format("DD-MM-YYYY")// Format as "dd-mm-yyyy"
  const maxDateStringForInput = moment(currentDate).format("YYYY-MM-DD");// Format as "YYYY-MM-DD"// Format as "YYYY-MM-DD"
  return (
    <>
      (
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModel}
      >
        <Modal.Body>
          <button
            onClick={() => setShowModel(false)}
            type="button"
            class="btn-close discountModel"
            aria-label="Close"
          ></button>
          <div className="displayDiscountModel">
            <div style={{ marginTop: "0PX" }} className="discountModelText">
              <div className="dicountHeading">
                <h6 className="discount-model-heading">
                  Avail Extra
                </h6>
                <h1 style={{ fontWeight: "700" }}>20% OFF How?</h1>
              </div>
              <div className="discount-model-bottom-content">
                <div className="discountinputForm">
                  <Form.Label >
                    <div className="discountModelBodyText">
                      <p className="discount-heading">
                        Enter your
                        &nbsp;<span className="discount-sub-heading">

                          Mobile Number
                        </span>
                      </p>
                    </div>
                    <div className='phone-number-discount'>
                      <div className='col-md-12 coloumn d-flex'>
                        <span className='col-md-3 phone'>+91</span>
                        <Form.Control className="col-md-8"
                          onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                          value={formData?.phone || ''}
                          type="text"
                          name="phone"
                          maxLength={10}
                          placeholder="Enter mobile number" />
                      </div>
                    </div>
                    {hasError(validationErrors, 'phone')}
                  </Form.Label>


                  <Form.Label><div className="discountModelBodyText">
                    <p className="discount-heading-one">
                      Enter your
                      &nbsp;<span className="discount-sub-heading" >

                        Pet Birthday
                      </span>
                    </p>
                  </div>

                  </Form.Label>
                  <div className="reactDatePicker">
                    <div className="reactDatePicker-inner">
                    
                      <Form.Control
                        className="discount-user-datepicker"
                        type="date"
                        placeholderText={`Please select a date (not after ${maxDateStringForDisplay})`}
                        placeholder="Birthday"
                        name="petDob"
                        value={formData?.petDob || ''}
                        onChange={(e) => { handleChange("petDob", e.target.value) }}
                        max={maxDateStringForInput}
                        onKeyDown={(e) => e.preventDefault()} // Disable manual input of dates
                      />
                      {hasError(validationErrors, 'petDob')}
                    </div>
                  </div>

                </div>

                <button className="discount-nextbtn" onClick={() => { !loading && handleNext(setCurrentStep(2)); }}>{loading ? "Please wait..." : "Next"}</button>
              </div>
            </div>

            <div className="discount-img">
              <img className="discount-img-inner"
                src={Images.secondDiscountModelImg}
                alt="pet-image"
                height={387}
                width={378}
              />
            </div>
          </div>

          {/* <h4>Centered Modal</h4>
        <p>
          Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
          dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
          consectetur ac, vestibulum at eros.
        </p> */}
        </Modal.Body>
      </Modal>
      )
    </>
  );
};

export default DiscountUserDetails;
