import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Images from "../../../helpers/images";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../redux/actions";
import { useEffect, useState } from "react";
import { hasError, showToast } from "../../../helpers/api/utils";
import PageLoader from "../../comman/PageLoader";
import { toast } from "react-hot-toast";

const PetUserDetails = () => {

  const dispatch = useDispatch();

  const { user, hasErrors, submitted, error, isDeleted, isEdit, validationError } = useSelector((state) => ({
    user: state.Auth.user,
    submitted: state.Auth.submitted,
    hasErrors: state.Auth.hasErrors,
    isEdit: state.Auth.isEdit,
    error: state.Auth.error,
    validationError: state.Auth.validationError,
  }));
  const [formData, setFormData] = useState(user ? user : {});
  const [validationErrors, setValidationErrors] = useState([]);
  const [isLoading,setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);


  const handleOnChange = (name, value) => {
    const errors = validationErrors?.filter(err => err.key !== name);
    setValidationErrors(errors);
    if(name === "pinCode"){
          if(value.length <= 6 ){
            setFormData((prevstate) => ({
              ...prevstate,
              [name]: value
            }))
          }
    }
    else{
      setFormData((prevstate) => ({
        ...prevstate,
        [name]: value
      }))
    }
  
  }

  useEffect(() => {
    if (submitted) {
      if (hasErrors) {
        if (!Array.isArray(error))
          showToast('error', error);
        else {
          showToast('error', validationError[0].error);
        }
        setValidationErrors(validationError);
      }
      // if (!hasErrors && !isDeleted ) {
      //   showTost('success', `User Profile ${isEdit ? 'Updated' : 'Created'} successfully`);
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasErrors, submitted, validationError, error, isEdit, isDeleted])

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    toast.dismiss();

    if(!(formData.hasOwnProperty('name')&&formData.hasOwnProperty('email')&&formData.hasOwnProperty('pinCode'))){
                  showToast("error","Please fill all form values");
                  setLoading(false)
                  return false;
    }

    const hasError = checkValidations(formData);
    if (hasError?.length > 0) {
      setLoading(false)
      return false;
    }
    dispatch(updateUser(formData));

  };

  const checkValidations = (data) => {
    const errors = []
   
    if (data.name === '') {
      const error = {
        error: "Please provide your name",
        key: "name"
      };
      errors.push(error);
    }
    if (data.pinCode === null) {
      const error = {
        error: "Please provide your Pin code",
        key: "pinCode"
      };
      errors.push(error);
    }

    if (data.pinCode?.length < 6) {
      const error = {
        error: "Please provide your valid Pin code",
        key: "pinCode"
      };
      errors.push(error);
    }
    if (data.email === '') {
      const error = {
        error: "Please provide your email",
        key: "email"
      };
      errors.push(error);
    }




    if (errors.length > 0) {
    
      setValidationErrors(errors);
    }
    return errors;
  }

  return (
    <>
    <PageLoader loading={isLoading}/>
      <div className="about-yourself-section">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="about-yourself-inner">
                <div className="images-section">
                  <div className="dog"><img src={Images.Dog1} className='img-fluid' alt='' /></div>
                  <div className="cat"><img src={Images.Cat} className='img-fluid' alt='' /></div>
                </div>
                <div className="about-yourself-form">
                  <div className="heading">
                    <h1>Tell us about yourself</h1>
                  </div>
                  <Form >
                    <Row>
                      <Form.Group as={Col}>
                        <Form.Label>What's your name?</Form.Label>
                        <Form.Control type="text" name="name" placeholder="Name"
                          value={formData?.name}
                          onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                        />
                        {formData?.name && formData?.name !== '' && (<span className="bottom-label">Nice to meet you, {formData?.name}</span>)}
                        {hasError(validationErrors, 'name')}
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group as={Col}>
                        <Form.Label>What's your email?</Form.Label>
                        <Form.Control type="email" name="email" placeholder="Email"
                          value={formData?.email}
                          onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                        />
                         {hasError(validationErrors, 'email')}
                        <span className="bottom-label">We ask for your email in case you want to save your progress and come back later.</span>
                       
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group as={Col}>
                        <Form.Label>And where does your pet live?</Form.Label>
                        <Form.Control type="number" name="pinCode" placeholder="Pin code"
                          value={formData?.pinCode}
                          onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                        />
                      
                         {hasError(validationErrors, 'pinCode')}
                    
                        <span className="bottom-label">We need to make sure we can ship to their location.</span>
                      </Form.Group>
                    </Row>
                    <Row>
                      <div className="continue-btn" onClick={(e) => {!(loading) && handleSubmit(e)} } ><Button>{loading ? 'Please Wait...' : 'Continue'}</Button></div>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};


export default PetUserDetails;
