import React from 'react';
import AppRoutes from './appRoutes';
import { Toaster } from 'react-hot-toast';
import ScrollToTop from './components/comman/ScrollToTop';


function App() {
  return (
    <>
    <Toaster/>
    <ScrollToTop/>
    <AppRoutes />
    </>
  );
}

export default App;
