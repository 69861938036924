// constants
import { PetProfileActionTypes } from './constants';




// common success
export const petProfileApiResponseSuccess = ({ actionType, responseData }) => (
    {
        type: PetProfileActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, responseData },
    });

export const petProfileApiResponseError = (actionType, error) => ({
    type: PetProfileActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});



export const getPetProfile = () => ({
    type: PetProfileActionTypes.PET_PROFILE_LIST,
    payload: {},
});

export const addPetProfiles = (payload) => ({
    type: PetProfileActionTypes.ADD_PET_PROFILE,
    payload,
});

export const showPetProfile = (id) => ({
    type: PetProfileActionTypes.SHOW_PET_PROFILE,
    payload: {id},
});

export const updatePetProfile = (id, params, nextPetProfileStep) => ({
    type: PetProfileActionTypes.UPDATE_PET_PROFILE,
    payload: {
        id,
        params,
        nextPetProfileStep
    },
});
export const deletePetProfile = (petProfileId) => ({
    type: PetProfileActionTypes.DELETE_PET_PROFILE,
    payload: {
        petProfileId,
    },
});


export const moveToProfileStep = (nextPetProfileStep) => ({
    type: PetProfileActionTypes.SET_UP_PET_PROFILE,
    payload: {
        nextPetProfileStep
    },
});

export const updateStep = (step) => ({
    type: PetProfileActionTypes.UPDATE_STEP,
    payload: {
        step
    },
});

export const addMealPlansForCurrentProfile = (id, params, nextPetProfileStep) => ({
    type: PetProfileActionTypes.ADD_MEAL_PLAN_PET_PROFILE,
    payload: {
        id,
        params,
        nextPetProfileStep
    },
});
export const resetAction = () => ({
    type: PetProfileActionTypes.RESET_ACTION,
    payload: { },
});
export const isLoadingPet = () => ({
    type: PetProfileActionTypes.IS_LOADING_PET_PROFILE,
    payload: { },
});


export const updateCurrentProfile = (profile,step) => ({
    type: PetProfileActionTypes.UPDATE_CURRENT_PROFILE,
    payload: {
        profile,
        step,
    },
});
export const updateAllPetProfiles = (profiles) => ({
    type: PetProfileActionTypes.UPDATE_ALL_PET_PROFILE,
    payload: {
        profiles,
    },
});
export const proceedToCheckout = (id, params, nextPetProfileStep) => ({
    type: PetProfileActionTypes.PROCEED_TO_CHECKOUT,
    payload: {
        id,
        params,
        nextPetProfileStep
    },
});


