/* eslint-disable no-unused-vars */
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';

// actions
import { cartApiResponseSuccess, cartApiResponseError } from "./actions";

// constants
import { CartActionTypes } from './constants';
import { APICore } from '../../helpers/api/apiCore';
import {
    carts as cartsApi,
    updateCart as updateCartApi,
    addCart as addCartApi,
    deleteCart as deleteCartApi,
    
} from '../../helpers';

const api = new APICore();

// helpers
/**
 * Cart Item list
 */
function* cartItemList() {
  try {
    const cartItems = api.getCartItems();
    const responseData = {
      data: cartItems,
    };
    yield put(
      cartApiResponseSuccess({
        actionType: CartActionTypes.ITEM_LIST,
        responseData,
      })
    );
  } catch (error) {
    yield put(cartApiResponseError(CartActionTypes.PET_PROFILE_LIST, error));
  }
}

/**
 * Update Item in Cart
 */
function* updateCartItem({ payload: {
    item } }) {
    try {
        const { data } = yield call(updateCartApi, { cartItem: item, totalAmount: item?.totalAmount },item?.cartId);
        if(data.status){
            let cartData = {...data.data.cartItem, cartId : data.data.id };
            const cartItems = api.getCartItems();
            const updatedItems = cartItems?.map((cartItem) => cartItem?.cartId === data.data?.id  ? cartData : cartItem);
            api.setCartItems(updatedItems);
            const responseData = {
                data: updatedItems,
            }
            yield put(cartApiResponseSuccess({
                actionType: CartActionTypes.UPDATE_ITEM,
                responseData
            }));
        }else{

            yield put(cartApiResponseError(CartActionTypes.UPDATE_ITEM, 'Someting went wrong'));
        }
        
    } catch (error) {
        
        yield put(cartApiResponseError(CartActionTypes.UPDATE_ITEM, error));
    }
}

/**
 * Add Item to Cart
 */
function* addItemToCart({ payload }) {
    try {
        const { data } = yield call(addCartApi, { cartItem: payload, totalAmount: payload?.totalAmount });
        if (data.status) {
            let cartData = {...data.data.cartItem, cartId : data.data.id };
            const responseData = {
                data: cartData,
            }
            const cartItems = api.getCartItems();
            const updtedItems = [...cartItems, cartData];
            api.setCartItems(updtedItems);
            yield put(cartApiResponseSuccess({
                actionType: CartActionTypes.ADD_ITEM,
                responseData
            }));
        } else {
            yield put(cartApiResponseError(CartActionTypes.ADD_ITEM, 'Someting went wrong'));
        }
    } catch (error) {
        yield put(cartApiResponseError(CartActionTypes.ADD_ITEM, error));
    }
}
/**
 * Delete Item Cart
 */
function* removeItemFromCart({ payload: { cartId } }) {
    try {
        yield call(deleteCartApi, cartId);
        const cartItems = api.getCartItems();
        const remainingItems =   cartItems?.filter(item => item.cartId !== cartId);
        const responseData = {
            data: remainingItems,
        }
        api.setCartItems(remainingItems);
        yield put(cartApiResponseSuccess({
            actionType: CartActionTypes.DELETE_ITEM,
            responseData
        }));
    } catch (error) {
        yield put(cartApiResponseError(CartActionTypes.DELETE_ITEM, error));

    }
}
/**
 * cartOffcanvasToggle Item Cart
 */
function* cartOffcanvasToggle({ payload: { toggle } }) {
  try {
    const responseData = {
      data: toggle,
    };
    yield put(
      cartApiResponseSuccess({
        actionType: CartActionTypes.TOOGLE_SHOW_CART,
        responseData,
      })
    );
  } catch (error) {
    yield put(cartApiResponseError(CartActionTypes.TOOGLE_SHOW_CART, error));
  }
}
/**
 * Empty Cart
 */
function* emptyCart() {
  try {
    api.setCartItems([]);
    const responseData = {
      data: [],
    };
    yield put(
      cartApiResponseSuccess({
        actionType: CartActionTypes.EMPTY_CART,
        responseData,
      })
    );
  } catch (error) {
    yield put(cartApiResponseError(CartActionTypes.EMPTY_CART, error));
  }
}

export function* watchCartItemList() {
  yield takeEvery(CartActionTypes.ITEM_LIST, cartItemList);
}

export function* watchUpdateCartItem() {
  yield takeEvery(CartActionTypes.UPDATE_ITEM, updateCartItem);
}

export function* watchRemoveItemFromCart() {
  yield takeEvery(CartActionTypes.DELETE_ITEM, removeItemFromCart);
}
export function* watchAddItemToCart() {
  yield takeEvery(CartActionTypes.ADD_ITEM, addItemToCart);
}
export function* watchCartOffcanvasToggle() {
  yield takeEvery(CartActionTypes.TOOGLE_SHOW_CART, cartOffcanvasToggle);
}

export function* watchEmptyCart() {
  yield takeEvery(CartActionTypes.EMPTY_CART, emptyCart);
}

function* cartSaga() {
  yield all([
    fork(watchCartItemList),
    fork(watchUpdateCartItem),
    fork(watchRemoveItemFromCart),
    fork(watchAddItemToCart),
    fork(watchCartOffcanvasToggle),
    fork(watchEmptyCart),
  ]);
}

export default cartSaga;
