/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import PageLoader from "../../comman/PageLoader";
import { getProducts, orderList } from "../../../helpers";
import { addWishlist as addWishlistApi } from "../../../helpers";
import { showToast } from "../../../helpers/api/utils";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import { useLocation, useSearchParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
const Footer = React.lazy(() => import("../../comman/footer"));
const Header = React.lazy(() => import("../../comman/header"));
const ListView = React.lazy(() => import("./listView"));
const GridView = React.lazy(() => import("./gridView"));

const Products = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const subCategory = searchParams.get("sub-category");
  const category = searchParams.get("category");
  const [product, setProduct] = useState({});
  const [filterProduct, setFilterProduct] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [currentFilterType, setCurrentFilterType] = useState("");
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const loader = useRef();

  const { globalSearchString, products , user } = useSelector((state) => ({
    user: state.Auth.user,
    userLoggedIn: state.Auth.userLoggedIn,
    userLogout: state.Auth.userLogout,
    message: state.Auth.message,
    error: state.Auth.error,
    actionPerformed: state.Auth.actionPerformed,
    globalSearchString: state.Product.name,
    products: state.Product.products,
  }));

  const leastPrice = (prices) => {
    const listPrices = prices?.map((item) => item.price);
    return Math.min(...listPrices);
  };
  const maxPrice = (prices) => {
    const listPrices = prices?.map((item) => item.price);
    return Math.min(...listPrices);
  };

  useEffect(() => {
    let filterParms = {};

    if (
      location.state?.searchName === undefined ||
      location.state?.searchName === ""
    ) {
      if (category != null || subCategory != null) {
        if (category != undefined) {
          filterParms = { ...filterParms, productCategory: category };
        }
        if (subCategory != undefined) {
          filterParms = { ...filterParms, productSubCategory: subCategory };
        }
      }
      if (totalPage >= page) {
        getProduct(filterParms);
      }
    }
  }, [location.pathname, subCategory, category, page]);

  useEffect(() => {
    setPage(1);
    setFilterProduct([]);
  }, [location.pathname,category,subCategory]);

  // useEffect(() => {
  //   const updatedData = searchProduct(products.results);
  //   setProduct({ ...products, results: updatedData });
  //   setFilterProduct(updatedData);
  // }, [globalSearchString]);

  // useEffect(() => {
  //   if (
  //     location.state?.searchName===''  &&
  //     category == null &&
  //     subCategory == null
  //   ) {
  //     getProduct(null);
  //   }

  // }, []);

  const getProduct = async (filterParms) => {
    setPageLoading(true);
    try {
      toast.dismiss();
      const { data } = await getProducts({ ...filterParms, page });
      setTotalPage(data.totalPages);
      const updatedData = searchProduct(data?.results);
      setProduct({ ...data, results: updatedData });
      setFilterProduct(filterProduct.concat(updatedData));
      setPageLoading(false);
    } catch (error) {
      toast.dismiss();
      showToast("error", error?.error);
      setPageLoading(false);
    }
  };

  const searchProduct = (results) => {
    const updatedData = [];
    results?.forEach((product) => {
      const min = leastPrice(product?.prices);
      const max = maxPrice(product?.prices);
      const updatedProduct = { ...product, minPrice: min, maxPrice: max };
      updatedData.push(updatedProduct);
    });
    return updatedData;
  };

  const [activeTab, setActiveTab] = useState("gridView");
  const handleChange = (type) => {
    setActiveTab(type);
  };

  const filter = (type) => {
    const allproducts = [...product?.results];
    let sorted;
    switch (type) {
      case "Name, A to Z":
        sorted = allproducts?.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case "Name, Z to A":
        sorted = allproducts?.sort((a, b) => b.name.localeCompare(a.name));
        break;
      case "Price, low to high":
        sorted = allproducts?.sort((a, b) => a.minPrice - b.minPrice);
        break;
      case "Price, high to low":
        sorted = allproducts?.sort((a, b) => b.minPrice - a.minPrice);
        break;

      default:
        sorted = allproducts;
        break;
    }
    setCurrentFilterType(type);
    setFilterProduct(sorted);
  };
     //used to add the items in wishList
     const addToWishlist = async (item) => {
      toast.dismiss();
      
      if(!(user?.phone===undefined)){
        if (item.isLoading) {
          return;
        }
        const updatedData = filterProduct.map((product) =>
          product.id !== item.id
            ? { ...product, isLoading: false }
            : { ...product, isLoading: true }
        );
        setFilterProduct(updatedData);
        try {
          const params = { product: item.id };
          await addWishlistApi(params);
    
          const updatedData = filterProduct.map((product) => {
            const updatedProduct = { ...product, isLoading: false };
            return updatedProduct;
          });
          setFilterProduct(updatedData);
    
          showToast("success", "Successfully added to wishlist");
        } catch (error) {
          if (error.status === 401) {
            showToast("", "Please login first");
          } else {
            showToast("error", "Already added to wishlist");
          }
          const updatedData = filterProduct.map((product) => {
            const updatedProduct = { ...product, isLoading: false };
            return updatedProduct;
          });
          setFilterProduct(updatedData);
        }
      }
      else{
  
        showToast("", "Please login first");
      }
   
    };


  // Observer to handle scroll to bottom
  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {   
      setPage((prv) => prv + 1);
    }
  }, []);
  console.log(page)

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver]);

  return (
    <>
      <Header />
      <PageLoader loading={pageLoading} />
      <div className="product-banner ">
        <Container>
          <Row>
            <Col lg="12">
              <h1>Pet Food Products</h1>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="product-view-section">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="product-view-inner">
                <div className="grid-list">
                  <ul>
                    <li
                      className={activeTab === "listView" ? "active" : ""}
                      onClick={() => {
                        handleChange("listView");
                      }}
                    >
                      {" "}
                      <i className="icon-list"></i>{" "}
                    </li>
                    <li
                      className={activeTab === "gridView" ? "active" : ""}
                      onClick={() => {
                        handleChange("gridView");
                      }}
                    >
                      {" "}
                      <i className="icon-grid"></i>{" "}
                    </li>
                  </ul>
                  <p>{`There ${filterProduct.length > 1 ? "are" : "is"} ${
                    product?.totalResults
                  } products`}</p>
                </div>
                <div className="sort-by-search-section">
                  <div className="label">Sort by:</div>
                  <div className="search-section">
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic">
                        {currentFilterType !== ""
                          ? currentFilterType
                          : "Relevance"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            filter("Name, A to Z");
                          }}
                        >
                          Name, A to Z
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            filter("Name, Z to A");
                          }}
                        >
                          Name, Z to A
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            filter("Price, low to high");
                          }}
                        >
                          Price, low to high
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            filter("Price, high to low");
                          }}
                        >
                          Price, high to low
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="tab-data">
        {activeTab === "listView" ? (
          <ListView
            filterProduct={filterProduct}
            addToWishlist={addToWishlist}
          />
        ) : (
          <GridView
            filterProduct={filterProduct}
            addToWishlist={addToWishlist}
          />
        )}
      </div>
      <div ref={loader}></div>
      <Footer />
    </>
  );
};
export default Products;
