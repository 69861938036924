import React from "react";
import {  Navigate,  } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute=({component})=>{
  
    const { user, userLoggedIn} = useSelector((state) => ({
        user: state.Auth.user,
        userLoggedIn: state.Auth.userLoggedIn,
    }));

    return   user?.status==="ACTIVE" ?  component : (<Navigate to={"/"}/> ) 
  
}

export default PrivateRoute;



