import React, { Suspense, useState } from "react";
import { Col, Container, Row } from "react-bootstrap"
import PageLoader from "../../comman/PageLoader";
import Images from "../../../helpers/images";
import CopyToClipboard from "react-copy-to-clipboard";
import ShareIt from "../../comman/share";
import { useSelector } from "react-redux";
import config from "../../../config";

const Footer = React.lazy(() => import('../../comman/footer'));
const Header = React.lazy(() => import('../../comman/header'));


const ReferAndEarn = () => {
  const { user } = useSelector((state) => ({
    user: state.Auth.user,
  }));
  console.log(user);
  const [copiedLink, setCopiedLink] = useState(false);
  
  const handleCopy = () => {
    setCopiedLink(true); setTimeout(() => {
      setCopiedLink(false)
    }, 3000);
  }

  return (
    <>
      <Suspense fallback={<PageLoader loading={true}></PageLoader>}>
        <Header />

        <div className="refer-earn-wrapper">
          <Container>
            <Row>
              <Col md={4}>
                <div className="refer-earn-static-info">
                  <div className="refer-earn-image">
                    <img src={Images.referAndEarn} width="50%" alt="share" className="img-fluid" />
                  </div>
                </div>
              </Col>
              <Col md={8}>
                <div className="refer-earn-share">
                  <div className="refer-earn-link text-center">
                    <div className="title">
                      <h1>Refer your friends</h1>
                      <p>Invite your friends to join Furrfrendzz and earn ₹ 20 credit for every person who creates an account</p>
                    </div>
                    <div className="refer-earn-link">
                      <div className="link">{`${config.REFFERAL_URL_PREFIX}?referral=${user.referralCode}`}</div>
                      <CopyToClipboard text={`${config.REFFERAL_URL_PREFIX}?referral=${user.referralCode}`}
                        onCopy={handleCopy}>
                        <span className="copy"><img src={Images.Copy} className='img-fluid' alt='' />Copy</span>
                      </CopyToClipboard>
                    </div>

                    {copiedLink && (
                      <div className="refer-earn-social-link">
                        <span className="badge badge-success">Refferal Link has been copied</span>
                      </div>
                    )}

                    <div className="refer-earn-social-link">
                      <ShareIt share={true} shareUrl={`${config.REFFERAL_URL_PREFIX}?referral=${user.referralCode}`} title='Register on Furrfrendz using this link and get refferal points .' />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </Suspense>
    </>
  )
}
export default ReferAndEarn