import { all } from 'redux-saga/effects';

import authSaga from './auth/saga';
import petProfileSaga from './petProfile/saga';
import cartSaga from './cart/saga';
import productSaga from './product/saga';


export default function* rootSaga() {
    yield all([
        authSaga(),
        petProfileSaga(),
        cartSaga(),
        productSaga()
    ]);
}
