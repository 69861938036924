import { Container } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { homeFlashBarSliderConfig } from "../../helpers/sliderConfig";
import { useEffect, useState } from "react";
import { homePageFlashBars } from "../../helpers";

const FlashBar = () => {
  var sliderConfig = homeFlashBarSliderConfig;
  const [flashBarTexts, setFlashBarTexts] = useState([]);
  useEffect(() => {
    getFlashbarTexts();
  }, []);

  // used to get the flash bar texts offers 
  const getFlashbarTexts = async () => {
    try {
      const { data } = await homePageFlashBars();
      setFlashBarTexts(data?.data);
    } catch (error) {
      setFlashBarTexts([]);
    }
  };

  return (
    <>
      {flashBarTexts?.length > 0 && (
        <div className="header-top flash-header">
          <Container>
            <Slider {...sliderConfig}>
              {flashBarTexts?.map((item, i) => (
                <div key={`flash-item-${i}`} className="slider-section">
                  <p>
                    {item?.text}{" "}
                    <strong>
                      <a href={item?.url}>REDEEM NOW</a>
                    </strong>
                  </p>
                </div>
              ))}
            </Slider>
          </Container>
        </div>
      )}
    </>
  );
};
export default FlashBar;
