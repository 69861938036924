/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { Offcanvas } from "react-bootstrap";
import Images from "../../helpers/images";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  removeCartItem,
  toggleCart,
  updateCartItem,
} from "../../redux/actions";
import config from "../../config";
import { showToast } from "../../helpers/api/utils";
import emptyCartAnimation from "../../helpers/cartEmpty.json";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";

const Cart = () => {
  const navigate = useNavigate();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: emptyCartAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const dispatch = useDispatch();

  const { showCart, actionPerformed, cartItems, message, user, userLoggedIn } =
    useSelector((state) => ({
      showCart: state.Cart.showCart,
      actionPerformed: state.Cart.actionPerformed,
      cartItems: state.Cart.cartItems,
      message: state.Cart.message,
      userLoggedIn: state.Auth.userLoggedIn,
      user: state.Auth.user,
    }));
  useEffect(() => {
    if (actionPerformed) {
      // showToast('success', message);
      dispatch(toggleCart(true));
      setTimeout(() => {
        toast.dismiss();
      }, 400);
    }
  }, [actionPerformed]);

  const cartClose = () => {
    dispatch(toggleCart(false));
  };
  const cartModalShow = () => {
    dispatch(toggleCart(true));
  };

  const totalPrice = () => {
    let totalPrice = 0.0;
    if (cartItems?.length > 0) {
      cartItems?.forEach((cartItem) => {
        totalPrice =
          totalPrice +
          (parseInt(cartItem?.selectedQuantity) *
            parseFloat(cartItem?.selectedPrice?.salePrice) ||
            parseFloat(cartItem?.totalAmount));
      });
    }

    return totalPrice;
  };
  const removeItem = (cartId) => {
    dispatch(removeCartItem(cartId));
  };

  const decreaseQuantity = (item, index) => {
    if (parseInt(item?.selectedQuantity) === 1) {
      dispatch(removeCartItem(item.cartId));
    } else {
      const totalAmount =
        (parseInt(item.selectedQuantity) - 1) *
        parseFloat(item?.selectedPrice?.salePrice);
      const data = {
        ...item,
        selectedQuantity: parseInt(item.selectedQuantity) - 1,
        quantity: parseInt(item?.quantity - 1),
        totalAmount,
      };

      dispatch(updateCartItem(data));
    }
  };

  const increaseQuantity = (item, index) => {
    const totalAmount =
      (parseInt(item.selectedQuantity) + 1) *
      parseFloat(item?.selectedPrice?.salePrice);
    const data = {
      ...item,
      selectedQuantity: parseInt(item.selectedQuantity) + 1,
      quantity: parseInt(item?.quantity + 1),
      totalAmount,
    };
    dispatch(updateCartItem(data));
  };

  const goToCheckout = () => {
    dispatch(toggleCart(false));
    navigate("/checkout");
  };

  return (
    <div className="cart-wrapper">
      <div className="cart-image">
        <img
          onClick={cartModalShow}
          width={"27px"}
          src={Images.Cart}
          className="img-fluid"
          alt=""
        />
        {(userLoggedIn || user) && cartItems?.length > 0 && (
          <div className="items">
            <span>{cartItems?.length}</span>
          </div>
        )}
      </div>
      <Offcanvas
        className="offcanvas-floating-cart"
        placement="end"
        backdrop={false}
        show={showCart}
        onHide={cartClose}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>YOUR CART</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {cartItems?.length > 0 ? (
            cartItems?.map((cartItem, index) => (
              <div className="cart-product" key={`cartItem-${index}`}>
                <div className="cart-image-wrapper">
                  <div className="cart-image">
                  <Link to={`/product/detail/${cartItem.id}`}  onClick={()=>cartClose()}><img
                      src={`${config.PRODUCT_IMG_URL}/${cartItem?.images[0]?.filename}`}
                      className="img-fluid"
                      alt=""
                    /></Link> 
                  
                  </div>
                </div>
                <div className="product-details">
                <Link to={`/product/detail/${cartItem.id}`}  onClick={()=>cartClose()}> <h2>{cartItem?.name}</h2> </Link>
                  <p>
                    <b>{`Packs:${
                      cartItem?.selectedPrice?.packageSize || cartItem?.packSize
                    }`}</b>
                  </p>
                  <h3>
                    <i className="icon-rupee"></i>
                    <b>
                      
                      {parseInt(cartItem?.selectedQuantity) *
                        parseFloat(cartItem?.selectedPrice?.salePrice)}
                    </b>
                  </h3>
                  <div className="quantity-wrapper-section">
                    <div className="quantity-section">
                      <ul>
                        <li>
                          
                          <span
                            onClick={(e) => {
                              e.preventDefault();
                              (cartItem.quantity > 1 ||
                                cartItem.selectedQuantity > 1) &&
                                decreaseQuantity(cartItem, index);
                            }}
                            className="minus"
                          >
                            
                            -
                          </span>
                        </li>
                        <li>
                          
                          <span className="qty">
                            
                            {cartItem?.selectedQuantity ||
                              cartItem?.quantity}
                          </span>
                        </li>
                        <li>
                          
                          <span
                            onClick={(e) => {
                              e.preventDefault();
                              increaseQuantity(cartItem, index);
                            }}
                            className="plus"
                          >
                            
                            +
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="product-remove">
                      
                        
                        <span
                          onClick={(e) => {
                            e.preventDefault();
                            removeItem(cartItem?.cartId);
                          }}
                        >
                          <img
                            style={{ height: "18px" }}
                            className="img-fluid"
                            src={Images.deleteIcon}
                          />
                        </span>
                      
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>
              <Lottie options={defaultOptions} height={100} width={100} />
              
              <div className="customers-review-section ">
              <h5 className="emptyCartText">No item found</h5>
              <div className="review-btn cartButton">
                <Link className="buttonText" to={"/products"} onClick={()=>cartClose()}> Return to Shop</Link>
              </div>
              </div>
            </div>
          )}
          {/* Similiar Products 
                     <div className="flavours-product">
                        <h4>Add out other flavours too...</h4>
                        <div className="cart-product">
                            <div className="cart-image-wrapper">
                                <div className="cart-image">
                                    <img src={Images.product1} className='img-fluid' alt='' />
                                </div>
                            </div>
                            <div className="product-details">
                                <h2>Beef and millet</h2>
                                <p>Single / 100gm</p>
                                <h3><i className="icon-rupee"></i> 550</h3>
                            </div>
                        </div>
                        <div className="cart-product">
                            <div className="cart-image-wrapper">
                                <div className="cart-image">
                                    <img src={Images.product1} className='img-fluid' alt='' />
                                </div>
                            </div>
                            <div className="product-details">
                                <h2>Beef and millet</h2>
                                <p>Single / 100gm</p>
                                <h3><i className="icon-rupee"></i> 550</h3>
                            </div>
                        </div>
                     </div> 
                    */}
        </Offcanvas.Body>
        {cartItems?.length > 0 && (
          <div className="checkout-section">
            <h5>Add Order Note</h5>
            <p>Shipping & taxes calculated at checkout</p>
            <div
              onClick={(e) => {
                e.preventDefault();
                goToCheckout();
              }}
              className="checkout-btn"
            >
             
              <span className="text"> CHECKOUT</span>
              <span className="total-price">
                <i className="icon-rupee"></i> {totalPrice()}
              </span>
            </div>
          </div>
        )}
      </Offcanvas>
    </div>
  );
};
export default Cart;
