import { APICore } from './apiCore';
const api = new APICore();

// user
// eslint-disable-next-line no-empty-pattern


function addPetProfile(params) {
    const baseUrl = `/pet-profile`;
    return api.create(`${baseUrl}`, params);

}

function updatePetProfile(params, id) {
    const baseUrl = `/pet-profile/${id}`;
    return api.updatePatch(`${baseUrl}`, params);
}
function deletePetProfile(id) {
    const baseUrl = `/pet-profile/${id}`;
    return api.delete(`${baseUrl}`);
}
function updateCurrentProfile(params) {
    const baseUrl = `/pet-profile/update-current-profile`;
    return api.create(`${baseUrl}`, params);
}
function addMealToPetProfile(params) {
    const baseUrl = `/pet-profile/add-meal-to-profile`;
    return api.create(`${baseUrl}`, params);
}
function petProfiles(params) {
    const baseUrl = `/pet-profile`;
    return api.get(`${baseUrl}`, params);

}

function petProfile(id) {
    const baseUrl = `/pet-profile/${id}`;
    return api.getById(`${baseUrl}`);
}



function proceedToCheckout(params) {
    const baseUrl = `/pet-profile/proceed-to-checkout`;
    return api.create(`${baseUrl}`,params);
}


function uploadProfilePic(formData) {
    const baseUrl = `/pet-profile/uploadPetProfileImage`;
    return api.uploadFile(`${baseUrl}`, formData);
}

export { addPetProfile, petProfiles, updatePetProfile, petProfile ,updateCurrentProfile, addMealToPetProfile ,uploadProfilePic,proceedToCheckout ,deletePetProfile };
