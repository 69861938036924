import { APICore } from './apiCore';
const api = new APICore();

function getMeals(params) {
    const baseUrl = `/customize-meals`;
    return api.get(`${baseUrl}`, params);
}

export {
    getMeals
};
