/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Container, Dropdown, Modal, Row, Tab, Tabs } from "react-bootstrap"
import {  useNavigate } from "react-router-dom"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { groomingPackagesSliderConfig } from "../../../helpers/sliderConfig";
import { useEffect, useState } from "react"
import PackageItem from "./partials/PackageItem"
import { groomingServiceList as groomingServiceListApi } from "../../../helpers"
import { showToast } from "../../../helpers/api/utils"
import PageLoader from "../../comman/PageLoader"


const DogGroomingService = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [packages, setPackages] = useState([]);
  const [seeMoreModal, setSeeMoreModal] = useState(false);
  const [currentSelectedPlan, setCurrentSelectedPlan] = useState({});

 

  // Filter data based on breed size   
  // const filterListData = async (key) => {
  //   const filteredData = listData.filter((item) => item.breedSize === key);
  //   setPackages(filteredData)
  // }
  useEffect(() => {
    setLoading(true);
    getGroomingList("SMALL");
  }, [])

  // get defualt selected data afor currentPackage
  const getDefultSelectedPlanAndDuration = (filteredData) => {
    let updatedPlanData = []
    filteredData.forEach((plan, index) => {
      let durations = [];
      plan?.durations?.forEach((duration, durationIndex) => {
        let defaultSelect = false;
        if (durationIndex === 0) {
          defaultSelect = true;
        }
        durations.push({ ...duration, isSelected: defaultSelect })

      });
      updatedPlanData.push({ ...plan, isSelected: false, durations, isOpen: false });
    });
    return updatedPlanData;
  }

  // API to get package Data 
  const getGroomingList = async (key) => {
    try {
      const { data } = await groomingServiceListApi('Dog')
      const filteredData = data.data.filter((item) => item.breedSize===key  );
      const updatedPlanData = getDefultSelectedPlanAndDuration(filteredData);
      setPackages(updatedPlanData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showToast('error', error)
    }
  }

  // Handle close modal 
  const packageClose = () => setSeeMoreModal(false);

  // onSelect Plan duration type handle 
  const handleOnSelectGroomingPlanDuration = (planPackage, duration) => {
    const currentDuration = { ...duration, isSelected: true };
    const updatedDurations = planPackage.durations?.map(item => item._id === duration._id ? currentDuration : { ...item, isSelected: false });
    const updatedPlan = { ...planPackage, durations: updatedDurations };
    setCurrentSelectedPlan(updatedPlan);
    const updatedPlans = packages?.map(item => item.id === planPackage.id ? updatedPlan : item);
    setPackages(updatedPlans);
  }

  // open show modal   
  const handleOpenCurrentPlanInfo = (planPack) => {
    setCurrentSelectedPlan(planPack);
    setSeeMoreModal(true);
  }

  // get Selected plan duration 
  const getSelectedDurationPlan = (durationPlans) => {
    const selectedDurationPlan = durationPlans?.find(item => item.isSelected);
    if (selectedDurationPlan === undefined) {
      return durationPlans[0]
    } else return selectedDurationPlan;
  }

  // navigate to bookinng
  const onPlanSelect = (item) => {
    const duration = item.durations.find((item) => item.isSelected);
    const updatedItem = {...item, selectedDuration:duration}
    navigate(`/book-grooming-appointment`, { state: updatedItem });
  }

  var groomingPackages = groomingPackagesSliderConfig;
  return (
    <>
      
      <PageLoader loading={loading} />
      <div className="grooming-services-wrapper">
        <Container>
          <Row>
            <Col lg={12}>
              <h2>Dog Grooming Packages</h2>
              <Tabs defaultActiveKey="SMALL" onSelect={(key) => getGroomingList(key)}>
                <Tab eventKey="SMALL" title="Small">
                  <div className="package-box-wrapper">
                    <Slider {...groomingPackages}>
                      {
                        packages.map((item, index) => (
                          <PackageItem
                            key={`PackageItem_${index}`}
                            item={item}
                            handleOnSelectGroomingPlanDuration={(plan, duration) => handleOnSelectGroomingPlanDuration(plan, duration)}
                            packageShow={(item) => handleOpenCurrentPlanInfo(item)}
                            onPlanSelect={() => onPlanSelect(item)}
                          />
                        ))
                      }
                    </Slider>
                  </div>
                </Tab>
                <Tab eventKey="MEDIUM" title="Medium"  onSelect={(key) => getGroomingList(key)}>
                  <div className="package-box-wrapper">
                    <Slider {...groomingPackages}>
                      {
                        packages.map((item, index) => {
                          return (
                            <PackageItem
                              key={`PackageItem_${index}`}
                              item={item}
                              handleOnSelectGroomingPlanDuration={(plan, duration) => handleOnSelectGroomingPlanDuration(plan, duration)}
                              packageShow={(item) => handleOpenCurrentPlanInfo(item)}
                              onPlanSelect={() => onPlanSelect(item)}
                            />
                          )
                        }
                        )
                      }
                    </Slider>
                  </div>
                </Tab>
                <Tab eventKey="LARGE" title="Large"  onSelect={(key) => getGroomingList(key)}>
                  <div className="package-box-wrapper">
                    <Slider {...groomingPackages}>
                      {
                        packages.map((item, index) => (
                          <PackageItem
                            key={`PackageItem_${index}`}
                            item={item}
                            handleOnSelectGroomingPlanDuration={(plan, duration) => handleOnSelectGroomingPlanDuration(plan, duration)}
                            packageShow={(item) => handleOpenCurrentPlanInfo(item)}
                            onPlanSelect={() => onPlanSelect(item)}
                          />
                        ))
                      }
                    </Slider>
                  </div>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </div>
      {
        seeMoreModal &&
        <Modal size="sm" show={seeMoreModal} className="grooming-services-modal" centered>
          <Modal.Body>
            <div className="close-btn"> <i onClick={packageClose} className="icon-close"></i></div>
            <div className="grooming-services-wrapper">
              <div className="package-box-wrapper">
                <div className="card-box">
                  <div className="card-body">
                    <div className="title-section">
                      <h3>{currentSelectedPlan?.title}</h3>
                    </div>
                    <div className="price-section-wrapper">
                      <div className="price-section">
                        <h4>₹ {getSelectedDurationPlan(currentSelectedPlan?.durations).salePrice}</h4>
                        <div className="select-price">
                          <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic"> for {getSelectedDurationPlan(currentSelectedPlan?.durations).durationTitle} </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {
                                currentSelectedPlan?.durations.map((itm, i) => {
                                  return (
                                    <Dropdown.Item
                                      key={`durations_${i}`}
                                      onClick={(e) => { e.preventDefault(); handleOnSelectGroomingPlanDuration(currentSelectedPlan, itm) }}
                                    >
                                      <div className="plan-select-wrapper">
                                        <div className="price">
                                          <h5>₹ {itm.salePrice}
                                            {
                                              itm.hasDiscount &&
                                              <span className="original-price"> ₹({itm.price})</span>
                                            }
                                            <span> for {itm.durationTitle} </span></h5>
                                        </div>
                                        {
                                          itm?.hasDiscount && itm?.discountType === "PERCENTAGE" &&
                                          <div className="plan-price-discount">
                                            <h5>{itm?.discount}% </h5>
                                            <p>off</p>
                                          </div>
                                        }

                                      </div>
                                    </Dropdown.Item>
                                  )
                                }
                                )
                              }
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                      {
                        getSelectedDurationPlan(currentSelectedPlan?.durations).hasDiscount && getSelectedDurationPlan(currentSelectedPlan?.durations).discountType === "PERCENTAGE" &&
                        <div className="discount"> {getSelectedDurationPlan(currentSelectedPlan?.durations).discount}% <span>off</span></div>
                      }
                    </div>
                    {
                      getSelectedDurationPlan(currentSelectedPlan?.durations).hasDiscount &&
                      <p className="price-section-if-discount">
                        ₹
                        {
                          getSelectedDurationPlan(currentSelectedPlan?.durations).price
                        }
                      </p>
                    }
                  </div>
                  <div className="plan-list">
                    <ul>
                      {
                        currentSelectedPlan?.servicesIncluded.map((itm, i) => {
                          return (
                            <li
                              key={`servicesIncluded_${i}`}>
                              <span>{itm.serviceTitle}</span>
                            </li>
                          )
                        }
                        )
                      }
                    </ul>
                  </div>
                  <div className="btn_link" onClick={() => onPlanSelect(currentSelectedPlan)}><span>Book this Package</span></div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      }

   
    </>
  )
}
export default DogGroomingService