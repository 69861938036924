import React from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import config from "../../../config";

const MealOrderDetails = ({ orderDetail }) => {
  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <div className="order-details-inner">
              <div className="order-reference">
                <div className="order-reference-placed">
                  <h4>
                    Order Reference {orderDetail?.orderRefNo} - placed on{" "}
                    {moment(orderDetail?.createdAt).format("DD-MM-YYYY")}
                  </h4>
                  <div className="action-btn">
                    <span></span>
                  </div>
                </div>
              </div>
              <div className="order-reference">
                <h3>
                  {" "}
                  <strong>Carrier</strong> My carrier
                </h3>
                <h3>
                  {" "}
                  <strong>Payment method</strong> Razorpay
                </h3>
              </div>
              <div className="order-reference">
                <div className="card-box">
                  <h4 className="mb-3">
                    FOLLOW YOUR ORDER'S STATUS STEP-BY-STEP
                  </h4>
                  <div className="table-responsive">
                    <Table bordered>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {moment(orderDetail?.createdAt).format(
                              "DD-MM-YYYY"
                            )}
                          </td>
                          <td>
                            <div className="status-bg">
                              {orderDetail?.status}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
              <div className="order-reference">
                <h4> Delivery address My Address</h4>
                <div className="address">
                  <p>
                    {orderDetail?.shippingInfo?.firstName}{" "}
                    {orderDetail?.shippingInfo?.lastName}
                  </p>
                  <p>
                    {orderDetail?.shippingInfo?.address} ,
                    {orderDetail?.shippingInfo?.city}{" "}
                    {orderDetail?.shippingInfo?.state} -{" "}
                    {orderDetail?.shippingInfo?.pinCode}{" "}
                  </p>
                  <p>India(Bharat)</p>
                </div>
              </div>
              <div className="order-reference">
                <div className="card-box">
                  <div className="table-responsive">
                    <Table bordered>
                      <thead>
                        <tr>
                          <th></th>
                          <th>Product</th>
                          <th>Quantity</th>
                          <th>Unit price</th>
                          <th>Total price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderDetail?.orderInfo?.map((order) => (
                          <tr key={`order-detail-${order?.id}`}>
                            <td>
                              <img
                                style={{ textAlign: "center" }}
                                src={`${config.SUBSCRIPTION_PLAN_IMG_URL}/${order?.image}`}
                                height={50}
                                width={50}
                              />
                            </td>
                            <td>
                              <div>
                                <strong>
                                  {" "}
                                  {order?.title} (
                                  {order?.selectedDuration?.durationTitle}){" "}
                                </strong>{" "}
                              </div>
                            </td>
                            <td>1</td>
                            <td className="text-end">
                              ₹ {order?.selectedDuration?.salePrice}
                            </td>
                            <td className="text-end">
                              ₹ {order?.selectedDuration?.salePrice}
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td className="text-end" colSpan={"4"}>
                            Shipping and handling
                          </td>
                          <td className="text-end">
                            ₹ {orderDetail?.shippingCharges}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-end" colSpan={"4"}>
                            Subtotal
                          </td>
                          <td className="text-end">
                            ₹{" "}
                            {parseFloat(orderDetail?.totalAmount) +
                              parseFloat(orderDetail?.discount)}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-end" colSpan={"4"}>
                            Tax
                          </td>
                          <td className="text-end">
                            ₹ {parseFloat(orderDetail?.totalTax)}
                          </td>
                        </tr>
                        {orderDetail?.discount > 0 && (
                          <tr>
                            <td className="text-end" colSpan={"4"}>
                              Promocode Discount (Coupon :{" "}
                              {orderDetail?.couponCode})
                            </td>
                            <td className="text-end">
                              {" "}
                              - ₹{orderDetail?.discount}
                            </td>
                          </tr>
                        )}
                        {orderDetail?.referralDiscount > 0 && (
                          <tr>
                            <td className="text-end" colSpan={"4"}>
                              Referral Discount ( Referral point used :{" "}
                              {orderDetail?.referralPointsUsed})
                            </td>
                            <td className="text-end">
                              {" "}
                              - ₹{orderDetail?.referralDiscount}
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td className="text-end" colSpan={"4"}>
                            Total
                          </td>
                          <td className="text-end">
                            ₹ {orderDetail?.totalAmount>0 ? parseFloat(orderDetail?.totalAmount).toFixed(2) : 0}{" "}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
              {/* <div className="order-reference">
                  <div className="card-box">
                    <div className="table-responsive">
                      <Table bordered >
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Carrier</th>
                            <th>Weight</th>
                            <th>Shipping cost</th>
                            <th>Tracking number</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>09/12/2023</td>
                            <td>My carrier</td>
                            <td>-</td>
                            <td>$7.00</td>
                            <td>-</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div> */}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default MealOrderDetails;
