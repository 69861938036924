import { APICore } from './apiCore';
const api = new APICore();

function reviewsList(params) {
    const baseUrl = `/review`;
    return api.get(`${baseUrl}`,params);
}

function customerReview(params) {
    const baseUrl = `/customer-review`;
    return api.create(`${baseUrl}`,params);
}


export {
    reviewsList,
    customerReview
};
