/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap"
import { useEffect, useState } from "react";
import { createRequestGroomingService as createRequestGroomingServiceApi } from "../../../helpers"
import {  showToast } from "../../../helpers/api/utils";
import { Link, useLocation, useNavigate } from "react-router-dom";
import config from "../../../config";
import PageLoader from "../../comman/PageLoader";
import { toast } from "react-hot-toast";
import Lottie from "react-lottie";
import submitSuccess from "../../../helpers/submitSuccess.json"


const Footer = React.lazy(() => import('../../comman/footer'));
const Header = React.lazy(() => import('../../comman/header'));

const PetDetails = React.lazy(() => import('./PetDetails'));
const BasicsDetails = React.lazy(() => import('./BasicsDetails'));


const GroomingAppointment = () => {

  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const groomingPackage = location?.state


  useEffect(() => {
    if (!groomingPackage) {
      navigate(`/`);
    }
    const data = {
      groomingPackage,
      petType: groomingPackage.petType
    }
    setFormData(data)
  }, [])


  const steps = 2;

  // used to handle the next button

  const handleNext = () => {
    if (currentStep === 1) {
      const hasError = firstcheckValidations(formData);
      if (hasError?.length > 0) {
        setLoading(false)
        return false;
      }
      if (currentStep < steps) {
        setCurrentStep((prevStep) => prevStep + 1);
      }
    }
  };

// used to handle the previous/back button 

  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

// used to check the validations of first step 

  const firstcheckValidations = (data) => {
    console.log(data)
    const errors = []
    if (data.fullName === undefined || data.fullName === "") {
      const error = {
        error: "Please provide your full name",
        key: "fullName"
      };
      errors.push(error);
    }
// used regex email validations for validate the input email
    const RegexEmailValidation = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;;
    if (!(RegexEmailValidation.test(data.email))) {
      const error = {
        error: "Please provide your valid email ",
        key: "email"
      };
      errors.push(error);
    }


    if (data.appointmentDate === undefined || data.appointmentDate === "") {
      const error = {
        error: "Please provide your appointment date ",
        key: "appointmentDate"
      };
      errors.push(error);
    }

    if (data.appointmentTime === undefined || data.appointmentTime === "") {
      const error = {
        error: "Please provide your appointment time",
        key: "appointmentTime"
      };
      errors.push(error);
    }
    

    const validatePhone = /^[6-9]{1}[0-9]{9}$/;
    if (!(validatePhone.test(data.phone))) {
      const error = {
        error: "Please provide your valid phone number ",
        key: "phone"
      };
      errors.push(error);
    }

    if (errors.length > 0) {

      setValidationErrors(errors);
    }
    return errors;
  }

// used to check the validations of second step
  const secondcheckValidations = (data) => {


    const errors = []
    if (data.petAge === undefined || data.petAge === "") {
      const error = {
        error: "Please provide your pet age ",
        key: "petAge"
      };
      errors.push(error);
    }
    if (data.lastVaccinationDate === undefined || data.lastVaccinationDate === "") {
      const error = {
        error: "Please provide your pet last vaccination date ",
        key: "lastVaccinationDate"
      };
      errors.push(error);
    }

    if (data.nextVaccinationDate === undefined || data.nextVaccinationDate === "") {
      const error = {
        error: "Please provide your pet next vaccination date ",
        key: "nextVaccinationDate"
      };
      errors.push(error);
    }

    if (data.healthComplaints === undefined || data.healthComplaints === "") {
      const error = {
        error: "Please provide your pet health complaints ",
        key: "healthComplaints"
      };
      errors.push(error);
    }

    if (data.healthIssues === undefined || data.healthIssues === "") {
      const error = {
        error: "Please provide your pet health issues ",
        key: "healthIssues"
      };
      errors.push(error);
    }
    if (data.petBreed === undefined || data.petBreed === "") {
      const error = {
        error: "Please provide your pet breed ",
        key: "petBreed"
      };
      errors.push(error);
    }

    if (data.recentContagiousIllness === undefined || data.recentContagiousIllness === "") {
      const error = {
        error: "Please provide your pet recent contagious illness ",
        key: "recentContagiousIllness"
      };
      errors.push(error);
    }

    if (data.groomed === undefined || data.groomed === "") {
      const error = {
        error: "Please provide has your pet been groomed before?",
        key: "groomed"
      };
      errors.push(error);
    }

    if (data.coatKnotted === undefined || data.coatKnotted === "") {
      const error = {
        error: "Please provide your pet is coat knotted ",
        key: "coatKnotted"
      };
      errors.push(error);
    }

    if (data.groomingTerms === undefined || data.groomingTerms === "") {
      const error = {
        error: "Please check grooming terms ",
        key: "groomingTerms"
      };
      errors.push(error);
    }

    if (errors.length > 0) {

      setValidationErrors(errors);
    }
    return errors;

  }

  // used to handle the input chnages
  const handleOnChange = (name, value) => {
    let errors = [];
    errors = validationErrors?.filter(err => err.key !== name);

    if (name === "phone") {
      if (value.length <= 10) {
        setFormData((prv) => ({
          ...prv,
          [name]: value
        }))
      }
      else {
        return;
      }
      setValidationErrors(errors);
    }
    if(name==="appointmentTime"){
      if(formData?.appointmentDate?.getDay()<6 && formData?.appointmentDate.getDay()>0 ){
            if((value<="20:00" && value>"10:59")){
              setFormData((prevstate) => ({
                ...prevstate,
                [name]: value
              }));
            }
       }
       else{
        if((value<="20:00" && value>"09:59")){
          setFormData((prevstate) => ({
            ...prevstate,
            [name]: value
          }));
        }
       }

  }
    else {
      setFormData((prevstate) => ({
        ...prevstate,
        [name]: value
      }));
      setValidationErrors(errors);
    }
   
    


  }
// used to final submit of form
  const onSubmit = async (e) => {
    e.preventDefault();
    toast.dismiss();
    setLoading(true);
    const hasError = secondcheckValidations(formData)
    if (hasError.length > 0) {
      setLoading(false);
      return;
    }
    try {
     await createRequestGroomingServiceApi(formData)
      setLoading(false);
      setFormData({});
      setShowModel(true)
      setCurrentStep(1);
      // showToast('success', "You request has been submitted successfully.")
      // navigate('/', { replace: true });
    } catch (error) {
      setShowModel(false)
      setLoading(false);
      if (error.status === 422) {
        setValidationErrors(error.error);
      } else {
        showToast('error', error)
      }
    }
  }

  //used to show the succes model after submit
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: submitSuccess,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <>
      <Suspense fallback={<PageLoader loading={true}></PageLoader>}>
        <Header />
        <div className="appointment-banner-section">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="heading">
                  <h1>Book an Appointment</h1>
                  <p>Appointment for grooming scheduled. Date & time to be <br />confirmed. Please provide contact information.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <Container>
          <Row className="appointment-wrapper">
            <Col lg={8}>
              <div className="appointment-form-section">

                <Col lg={12}>
                  {
                    currentStep === 1 &&
                    <BasicsDetails
                      formData={formData}
                      validationErrors={validationErrors}
                      handleOnChange={(name, val) => { handleOnChange(name, val) }}
                    />
                  }
                  {
                    currentStep === 2 &&
                    <PetDetails
                      formData={formData}
                      validationErrors={validationErrors}
                      groomingPackage={groomingPackage}
                      handleOnChange={(name, val) => { handleOnChange(name, val) }}
                    />
                  }
                  <div className="appointment-btn">
                    {currentStep > 1 && (
                      <button type="button" className="previous action-button-previous" onClick={handlePrevious} > Back</button>
                    )}
                    {currentStep < steps && (
                      <button type="button" className="next action-button" onClick={handleNext} > Next</button>
                    )}
                    {currentStep === steps && (
                      <button type="button" className="submit action-button" onClick={!(loading) ? (e)=>onSubmit(e) : undefined} >{loading ? 'Please Wait...' : "Submit"}</button>
                    )}
                  </div>
                </Col>

              </div>
            </Col>
            <Col lg={4}>

              <div className="package-box-wrapper">
                <Col lg={12}>
                  <div className="grooming-services-wrapper">
                    <div className="package-box-wrapper">
                      <div className="card-box">
                        <div className="image-section">
                          <img src={`${config.GROOMING_PLAN_IMG_URL}/${groomingPackage?.image}`} className='img-fluid' alt='' />
                        </div>
                        <div className="card-body">
                          <div className="title-section">
                            <h3>{groomingPackage?.title}</h3>
                          </div>
                          <div className="price-section-wrapper">
                            <div className="price-section">
                              <h4>₹ {groomingPackage?.selectedDuration?.salePrice}</h4>
                            </div>
                            {
                              groomingPackage?.selectedDuration?.hasDiscount && groomingPackage?.selectedDuration.discountType === "PERCENTAGE" &&
                              <div className="discount"> {groomingPackage?.selectedDuration?.discount}% <span>off</span></div>
                            }
                          </div>
                          {
                            groomingPackage?.selectedDuration?.hasDiscount &&
                            <p className="price-section-if-discount">
                              ₹
                              {
                                groomingPackage?.selectedDuration.price
                              }
                            </p>
                          }
                        </div>
                        <div className="plan-list">
                          <ul>
                            {
                              groomingPackage?.servicesIncluded.map((itm, i) => {
                                return (
                                  <li
                                    key={`servicesIncluded_${i}`}>
                                    <span>{itm.serviceTitle}</span>
                                  </li>
                                )
                              }
                              )
                            }
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </div>
            </Col>
          </Row>
          {showModel &&
            <Modal className="success-modal-wrapper" size="sm" centered show={true} >
              <Lottie options={defaultOptions}
                height={200}
                width={200} />
              <Modal.Body style={{textAlign:"center"}}>
               Thank You ! <br />
                <span className="sucessModelText">We will be in touch shortly.</span>
              </Modal.Body>
              <div className="suceessModelButton">
                <div className="review-btn" ><Link to='/'>Home</Link> </div>
              </div>

            </Modal>
          }
        </Container>
        <Footer />
      </Suspense>
    </>
  )
}
export default GroomingAppointment