import { APICore } from './apiCore';
const api = new APICore();

function addConsultVet(params) {
    const baseUrl = `/consult-vet`;
    return api.create(`${baseUrl}`, params);
}

export {
    addConsultVet
};
