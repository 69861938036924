/* eslint-disable no-unused-vars */
import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import {
  ConsultVet,
  GroomingAppointment,
  Home,
  PetProfile,
  Products,
  Services,
} from "./components";
import PageLoader from "./components/comman/PageLoader";
import PrivateRoute from "./PrivateRoute";
import GroomingServices from "./components/pages/groomingServices/GroomingServices";
import ReferAndEarn from "./components/pages/referAndEarn";
import Orders from "./components/pages/myOrders";
import Details from "./components/pages/myOrders/Details";
import ReferralHistory from "./components/pages/referralHistory";
import Invoice from "./components/pages/myOrders/Invoice";
import { useSelector } from "react-redux";
import NutritionistVet from "./components/pages/nutritionistVet";



const ProductDetails = React.lazy(() =>
  import("./components/pages/products/details")
);
const Legal = React.lazy(() =>
  import("./components/pages/LegalServices/legal")
);
const Checkout = React.lazy(() =>
  import("./components/pages/checkout/Checkout")
);
const EditProfile = React.lazy(() =>
  import("./components/pages/profile/editProfile")
);
const ContactUs = React.lazy(() =>
  import("./components/pages/contactUs/contactUs")
);
const AboutUs = React.lazy(() => import("./components/pages/aboutUs/aboutUs"));
const Faq = React.lazy(() => import("./components/pages/faq/faq"));
const Reviews = React.lazy(() => import("./components/pages/reviews/reviews"));
const OrdersCancellations = React.lazy(() =>
  import("./components/pages/ordersCancellations​/ordersCancellations​")
);
const CatGroomingService = React.lazy(() =>
  import("./components/pages/groomingServices/catGroomingService")
);
const DogGroomingService = React.lazy(() =>
  import("./components/pages/groomingServices/dogGroomingService")
);
const Wishlist = React.lazy(() =>
  import("./components/pages/wishlist/wishlist")
);
const AddressPage = React.lazy(() =>
  import("./components/pages/address/AddressPage") 
);

const AppRoutes = () => {

  let {
    currentPetProfile,
    user,
    cartItems,
    actionPerformed,
    petProfiles,
    tempToken,
    message,
    clearAction,
    userLoggedIn,
  } = useSelector((state) => ({
    currentPetProfile: state.PetProfile.currentPetProfile,
    user: state.Auth.user,
    actionPerformed: state.Auth.actionPerformed,
    tempToken: state.Auth.tempToken,
    cartItems: state.Cart.cartItems,
    petProfiles: state.Auth.petProfiles,
    userLoggedIn: state.Auth.userLoggedIn,
    message: state.Auth.message,
    clearAction: state.Auth.clearAction,
  }));



  return (
    <Suspense fallback={<PageLoader loading={true}></PageLoader>}>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/products" element={<Products />} />
        <Route
          exact
          path="/product/detail/:productId"
          element={<ProductDetails />}
        />
        <Route exact path="/services" element={<Services />} />
        <Route exact path="/pet-profile" element={<PetProfile />}  />
        <Route exact path="/edit-profile/:profileId" element={<PrivateRoute component={<EditProfile />} />} />
        <Route exact path="/wishlist" element={<PrivateRoute component={<Wishlist />} />} />
        <Route exact path="/consult-vet" element={<ConsultVet />} />
      
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/faq" element={<Faq />} />
        <Route exact path="/legal" element={<Legal />} />
        <Route exact path="/reviews" element={<Reviews />} />
        <Route exact path="/checkout" element={ <Checkout />} />
        <Route exact path="/consult-nutritionist" element={<NutritionistVet />} />

        <Route exact path="/orders" element={<PrivateRoute component={<Orders/>} />} />
        <Route exact path="/referral-history" element={<PrivateRoute component={<ReferralHistory/>} />} />
        <Route exact path="/details" element={<PrivateRoute component={<Details/>} />} />
        <Route exact path="/invoice" element={<PrivateRoute component={<Invoice/>} />} />
        {/* <Route exact path="/orders-cancellations" element={<PrivateRoute component={<OrdersCancellations/>} />} /> */}

        <Route exact path="/address" element={<PrivateRoute component={<AddressPage />} />} />
        <Route exact path="/refer-and-earn" element={<PrivateRoute component={<ReferAndEarn />} />} />
        {/* <Route
          exact
          path="/grooming-service"
          element={<GroomingServices />}
        /> */}
        <Route
          exact
          path="/grooming-service"
          element={<GroomingServices/>}
        />
        <Route
          exact
          path="/book-grooming-appointment"
          element={<GroomingAppointment />}
        />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
