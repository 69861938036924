import { APICore } from './apiCore';
const api = new APICore();


function homePageFlashBars(params) {
    const baseUrl = `/homepage_flashbar/all`;
    return api.get(`${baseUrl}`, params);
}


export {
    homePageFlashBars,
};
