// constants
import { CartActionTypes } from './constants';




// common success
export const cartApiResponseSuccess = ({ actionType, responseData }) => (
    {
        type: CartActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, responseData },
    });

export const cartApiResponseError = (actionType, error) => ({
    type: CartActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});



export const getCartItems = () => ({
    type: CartActionTypes.ITEM_LIST,
    payload: {},
});

export const addItemToCart = (payload) => ({
    type: CartActionTypes.ADD_ITEM,
    payload,
});

export const showCartItem = (id) => ({
    type: CartActionTypes.SHOW_ITEM,
    payload: { id },
});

export const updateCartItem = (item) => ({
    type: CartActionTypes.UPDATE_ITEM,
    payload: {
        item,
    },
});
export const removeCartItem = (cartId) => ({
    type: CartActionTypes.DELETE_ITEM,
    payload: {
        cartId,
    },
});
export const emptyCart = () => ({
    type: CartActionTypes.EMPTY_CART,
    payload: {},
});
export const toggleCart = (toggle) => ({
    type: CartActionTypes.TOOGLE_SHOW_CART,
    payload: {
        toggle ,
    },
});




