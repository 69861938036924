import { APICore } from "./apiCore";
const api = new APICore();

// category
function productsByCategory(categoryId) {
  const baseUrl = `/product/byCategoryId/${categoryId}`;
  return api.get(`${baseUrl}`);
}

export { productsByCategory };
