export const AuthActionTypes = {
    API_RESPONSE_SUCCESS: '@@auth/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@auth/API_RESPONSE_ERROR',
    LOGIN_USER: '@@auth/LOGIN_USER',
    LOGIN_AT_CHECKOUT: '@@auth/LOGIN_AT_CHECKOUT',
    VALIDATE_USER: '@@auth/VALIDATE_USER',
    UPDATE_USER: '@@auth/UPDATE_USER',
    LOGOUT_USER: '@@auth/LOGOUT_USER',
    SIGNUP_USER: '@@auth/SIGNUP_USER',
    FORGOT_PASSWORD: '@@auth/FORGOT_PASSWORD',
    FORGOT_PASSWORD_CHANGE: '@@auth/FORGOT_PASSWORD_CHANGE',
    RESET: '@@auth/RESET',
    CLEAR_OTP_TOKEN: '@@auth/CLEAR_OTP_TOKEN',
    CLEAR_ACTION: '@@auth/CLEAR_ACTION',
    IS_LOADING: '@@auth/IS_LOADING',
    RESEND_OTP: '@@auth/RESEND_OTP',
    REMOVE_REFERRAL_URL: '@@auth/REMOVE_REFERRAL_URL',
    GUEST_LOGIN: '@@auth/GUEST_LOGIN',
    LOGIN_AS_GUEST: '@@auth/LOGIN_AS_GUEST',
    USER_STATUS_UPDATE: '@@auth/USER_STATUS_UPDATE',
    CHECKOUT_PET_PROFILE: '@@auth/CHECKOUT_PET_PROFILE',
};