/* eslint-disable jsx-a11y/img-redundant-alt */
import { Modal } from "react-bootstrap";
import Images from "../../helpers/images";
import { useState } from "react";

const HomeDiscountModel = ({
  setCurrentStep,
  handleChange,
  scroll,
  firstTime,
  formData,
  showModel,
  setShowModel
}) => {


  return (
    <>
      {scroll && firstTime && (
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showModel}
        >
          <Modal.Body>
            <button
              onClick={() => setShowModel(false)}
              type="button"
              class="btn-close discountModel"
              aria-label="Close"
            ></button>
            <div className="displayDiscountModel">
              <div className="discountModelText">
                <div className="dicountHeading">
                  <h6 className="homediscount-model-heading">
                    BIRTHDAY MONTH CALLS FOR
                  </h6>
                  <span className="content-home-model">
                    EXTRA <span className="home-model-subheading">20% </span>OFF
                  </span>
                </div>
                <div className="home-model-bottom-content">
                  <div className="discountModelBodyText">
                    <h5>
                      Are you a &nbsp;
                      <span className="discountModelBodyText-subheading">
                        Pet Parent?
                      </span>
                    </h5>
                  </div>
                  <div className=" customers-review-section discountModelButtons">
                    <div className="review-btn discountBtn ">
                      <button
                        onClick={() => handleChange("HavingPetParent", "Yes")}
                        className={
                          formData?.HavingPetParent === "Yes"
                            ? "buttonText active"
                            : "buttonText"
                        }
                      >
                        Yes
                      </button>
                      <button
                        onClick={() => handleChange("HavingPetParent", "No")}
                        className={
                          formData?.HavingPetParent === "No"
                            ? "buttonText active"
                            : "buttonText"
                        }
                      >
                        No
                      </button>
                    </div>
                  </div>
                  <button onClick={() => setCurrentStep(2)} className="nextbtn">
                    Next
                  </button>
                </div>
              </div>
              <div className="home-img">
                <img
                  className="home-img-inner"
                  src={Images.firstDiscountModelImg}
                  alt="pet image"
                  height={348}
                  width={348}
                />
              </div>
            </div>

            {/* <h4>Centered Modal</h4>
        <p>
          Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
          dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
          consectetur ac, vestibulum at eros.
        </p> */}
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default HomeDiscountModel;
