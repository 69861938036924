import { APICore } from "./apiCore";
const api = new APICore();

function shopByCategories() {
  const baseUrl = `/shop-by-category/home-page-category`;
  return api.get(`${baseUrl}`);
}

function HomeMenu() {
  const baseUrl = `/home-menu`;
  return api.get(`${baseUrl}`);
}


export { shopByCategories,HomeMenu };
