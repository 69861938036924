/* eslint-disable no-undef */
import { all, fork, put, takeEvery, call } from "redux-saga/effects";
// apicore
import { APICore, setAuthorization } from "../../helpers/api/apiCore";
// helpers
import {
  login as loginApi,
  loginAsGuestUser as loginAsGuestUserApi,
  resendOtp as resendOtpApi,
  validateOtp as validateOtpApi,
  updateUser as updateUserApi,
  logout as logoutApi,
  guestLogin as guestLoginApi,
  forgotPassword as forgotPasswordApi,
} from "../../helpers";

// actions
import { authApiResponseSuccess, authApiResponseError } from "./actions";

// constants
import { AuthActionTypes } from "./constants";
import {
  cartApiResponseSuccess,
  petProfileApiResponseSuccess,
} from "../actions";
import { PetProfileActionTypes } from "../petProfile/constants";
import { CartActionTypes } from "../cart/constants";

const api = new APICore();

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { phone } }) {
  try {
    const response = yield call(loginApi, { phone });
    const { status, tempToken, message } = response.data;
    if (status) {
      yield put(
        authApiResponseSuccess(AuthActionTypes.LOGIN_USER, {
          tempToken,
          message,
        })
      );
    } else yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, message));
  } catch (error) {
    yield put(authApiResponseError(AuthActionTypes.LOGIN_USER,error));
  }
}

function* loginAtCheckout({ payload: { phone } }) {
  try {
    const response = yield call(loginApi, { phone });
    const { status, tempToken, message } = response.data;
    if (status) {
      yield put(
        authApiResponseSuccess(AuthActionTypes.LOGIN_AT_CHECKOUT, {
          tempToken,
          message,
        })
      );
    } else yield put(authApiResponseError(AuthActionTypes.LOGIN_AT_CHECKOUT, message));
  } catch (error) {
    yield put(authApiResponseError(AuthActionTypes.LOGIN_AT_CHECKOUT,error));
  }
}

/**
 * Resend otp
 * @param {*} payload - username and password
 */
function* resendOtp({ payload: { tempId } }) {
  try {
    const response = yield call(resendOtpApi, { tempId });
    const { status, tempToken, message } = response.data;
    if (status) {
      yield put(
        authApiResponseSuccess(AuthActionTypes.RESEND_OTP, {
          tempToken,
          message,
        })
      );
    } else yield put(authApiResponseError(AuthActionTypes.RESEND_OTP, message));
  } catch (error) {
    yield put(authApiResponseError(AuthActionTypes.RESEND_OTP, error));
  }
}
/**
 * Login the user
 * @param {*} payload - username and password
 */
function* validateOtp({ payload: { otp, tempToken, referralCode,cartItem } }) {
  try {
    const response = yield call(validateOtpApi, {
      otp,
      tempToken,
      referralCode,
      cartItem,
    });
    const {
      user,
      tokens,
      message,
      allPetProfiles,
      cartItems,
      totalReferralPoints,
    } = response.data;

    // Set login user data
    const userWithToken = {
      ...user,
      token: tokens.access.token,
      refreshToken: tokens.refresh.token,
      message,
      allPetProfiles,
      totalReferralPoints,
    };
    api.setLoggedInUser(userWithToken);
    api.setCurrentProfileStep(user?.currentProfileStep);
    api.setCurrentPetProfile(user?.currentPetProfile);
    setAuthorization(tokens.access.token);
    yield put(
      authApiResponseSuccess(AuthActionTypes.VALIDATE_USER, userWithToken)
    );
    yield put(authApiResponseSuccess(AuthActionTypes.REMOVE_REFERRAL_URL, ""));
    const responseData = {
      currentPetProfile: user?.currentPetProfile ? user?.currentPetProfile : {},
      petProfiles: allPetProfiles,
      currentProfileStep: user?.currentProfileStep
        ? user?.currentProfileStep
        : "PetUserDetails",
    };

    // Set pet Profile
    yield put(
      petProfileApiResponseSuccess({
        actionType: PetProfileActionTypes.SET_UP_PET_PROFILE,
        responseData,
      })
    );

    // update Cart
    let updatedCartItems = [];
    if (cartItems?.length > 0) {
      cartItems.forEach((item) => {
        const itemData = { ...item?.cartItem, cartId: item?.id };
        updatedCartItems = [...updatedCartItems, itemData];
      });
    }
    api.setCartItems(updatedCartItems);
    yield put(
      cartApiResponseSuccess({
        actionType: CartActionTypes.ITEM_LIST,
        responseData: { data: updatedCartItems },
      })
    );
  } catch (error) {
    yield put(
      authApiResponseError(AuthActionTypes.VALIDATE_USER, {
        error: error.error,
      })
    );
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

/** Login as guest user */
function* loginGuestUser({ payload: {} }) {
  try {
    const response = yield call(loginAsGuestUserApi, {});
    const { user, tokens, allPetProfiles, cartItems, message } = response.data;
    //set user to localStorage
    localStorage.setItem(
      "firstUser",
      JSON.stringify({
        ...user,
        token: tokens.access.token,
        refreshToken: tokens.refresh.token,
        message,
        allPetProfiles,
      })
    );
    localStorage.setItem(
      "furrfernds_user",
      JSON.stringify({
        ...user,
        token: tokens.access.token,
        refreshToken: tokens.refresh.token,
        message,
        allPetProfiles,
      })
    );
    // Set login user data
    const userWithToken = {
      ...user,
      token: tokens.access.token,
      refreshToken: tokens.refresh.token,
      message,
      allPetProfiles,
    };
    api.setLoggedInUser(userWithToken);
    api.setCurrentProfileStep(user?.currentProfileStep);
    api.setCurrentPetProfile(user?.currentPetProfile);
    setAuthorization(tokens.access.token);
    yield put(
      authApiResponseSuccess(AuthActionTypes.VALIDATE_USER, userWithToken)
    );
    yield put(authApiResponseSuccess(AuthActionTypes.REMOVE_REFERRAL_URL, ""));
    const responseData = {
      currentPetProfile: user?.currentPetProfile ? user?.currentPetProfile : {},
      petProfiles: allPetProfiles,
      currentProfileStep: user?.currentProfileStep
        ? user?.currentProfileStep
        : "PetUserDetails",
    };
    // Set pet Profile
    yield put(
      petProfileApiResponseSuccess({
        actionType: PetProfileActionTypes.SET_UP_PET_PROFILE,
        responseData,
      })
    );

    // update Cart
    let updatedCartItems = [];
    if (cartItems?.length > 0) {
      cartItems.forEach((item) => {
        const itemData = { ...item?.cartItem, cartId: item?.id };
        updatedCartItems = [...updatedCartItems, itemData];
      });
    }
    api.setCartItems(updatedCartItems);
    yield put(
      cartApiResponseSuccess({
        actionType: CartActionTypes.ITEM_LIST,
        responseData: { data: updatedCartItems },
      })
    );
  } catch (error) {
    yield put(
      authApiResponseError(AuthActionTypes.VALIDATE_USER, {
        error: error.error,
      })
    );
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}
/**
 * Login the user
 * @param {*} payload - username and password
 */
function* guestLoginOnCheckout({
  payload: { otp, tempToken, referralCode, name, email,phone,userPets},
}) {
  try {
    const cartData = api.getCartItems();
    const currentPetProfileData = api.getCurrentPetProfile();
    const response = yield call(guestLoginApi, {
      otp,
      tempToken, 
      referralCode,
      cartItems: cartData,
      name,
      email,
      phone,
      userPets,
      currentPetProfile: currentPetProfileData,
    });
    const {
      user,
      tokens,
      message,
      allPetProfiles,
      cartItems,
      totalReferralPoints,
    } = response.data;
    // Set login user data
    const userWithToken = {
      ...user,
      token: tokens.access.token,
      refreshToken: tokens.refresh.token,
      message,
      allPetProfiles,
      totalReferralPoints,
    };
    localStorage.setItem(
      "firstUser",
      JSON.stringify({
        ...user,
        token: tokens.access.token,
        refreshToken: tokens.refresh.token,
        message,
        allPetProfiles,
      })
    );
    localStorage.setItem(
      "furrfernds_user",
      JSON.stringify({
        ...user,
        token: tokens.access.token,
        refreshToken: tokens.refresh.token,
        message,
        allPetProfiles,
      })
    );
    api.setLoggedInUser(userWithToken);
    api.setCurrentProfileStep(user?.currentProfileStep);
    api.setCurrentPetProfile(user?.currentPetProfile);
    setAuthorization(tokens.access.token);
    yield put(
      authApiResponseSuccess(AuthActionTypes.VALIDATE_USER, userWithToken)
    );
    yield put(authApiResponseSuccess(AuthActionTypes.REMOVE_REFERRAL_URL, ""));

    const responseData = {
      currentPetProfile: user?.currentPetProfile ? user?.currentPetProfile : {},
      petProfiles: allPetProfiles,
      currentProfileStep: user?.currentProfileStep
        ? user?.currentProfileStep
        : "PetUserDetails",
    };

    // Set pet Profile
    yield put(
      petProfileApiResponseSuccess({
        actionType: PetProfileActionTypes.SET_UP_PET_PROFILE,
        responseData,
      })
    );

    // update Cart
    let updatedCartItems = [];
    if (cartItems?.length > 0) {
      cartItems.forEach((item) => {
        const itemData = { ...item?.cartItem, cartId: item?.id };
        updatedCartItems = [...updatedCartItems, itemData];
      });
    }
    api.setCartItems(updatedCartItems);
    yield put(
      cartApiResponseSuccess({
        actionType: CartActionTypes.ITEM_LIST,
        responseData: { data: updatedCartItems },
      })
    );
  } catch (error) {
    yield put(
      authApiResponseError(AuthActionTypes.VALIDATE_USER, {
        error: error.error,
      })
    );
    // yield put(
    //   authApiResponseError(AuthActionTypes.GUEST_LOGIN, {
    //     error: error.error,
    //   })
    // );
  }
}

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* updateUser({ payload: { name, email, pinCode } }) {
  try {
    const currentProfileStep = "PetBasicInfo";
    const response = yield call(updateUserApi, {
      name,
      email,
      pinCode,
      currentProfileStep,
    });
    const { user } = response.data;
    yield put(authApiResponseSuccess(AuthActionTypes.UPDATE_USER, user));
    const responseData = { data: "PetBasicInfo" };
    const loginUser = api.getLoggedInUser();
    const updatedUser = {
      ...loginUser,
      name,
      email,
      pinCode,
      currentProfileStep: user.currentProfileStep,
      currentPetProfile: user.currentPetProfile,
    };
    api.setLoggedInUser(updatedUser);
    api.setCurrentProfileStep("PetBasicInfo");
    yield put(
      petProfileApiResponseSuccess({
        actionType: PetProfileActionTypes.UPDATE_PROFILE_STEP,
        responseData,
      })
    );
  } catch (error) {
    yield put(
      authApiResponseError(AuthActionTypes.UPDATE_USER, {
        error: error.error,
        validationErrors: error.validationErrors,
      })
    );
  }
}

/**
 * Logout the user
 */
function* logout() {
  try {
    const response = yield call(logoutApi);
    const { message } = response.data;
    api.setLoggedInUser(null);
    api.setCurrentProfileStep(null);
    api.setCurrentPetProfile(null);
    api.setCartItems(null);
    setAuthorization(null);
    yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, { message }));
    yield put(
      cartApiResponseSuccess({
        actionType: CartActionTypes.EMPTY_CART,
        responseData: [],
      })
    );
  } catch (error) {
    yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
    yield put(
      cartApiResponseSuccess({
        actionType: CartActionTypes.EMPTY_CART,
        responseData: [],
      })
    );
  }
}
function* setIsLoading() {
  try {
    yield put(authApiResponseSuccess(AuthActionTypes.IS_LOADING));
  } catch (error) {
    yield put(authApiResponseError(AuthActionTypes.IS_LOADING));
  }
}
/**
 * Logout the user
 */
function* clearLoginStates() {
  try {
    yield put(authApiResponseSuccess(AuthActionTypes.CLEAR_ACTION));
  } catch (error) {
    yield put(authApiResponseError(AuthActionTypes.CLEAR_ACTION, error));
  }
}

/**
 * Clear Otp Token the user
 */

function* clearOtpToken() {
  yield put(authApiResponseSuccess(AuthActionTypes.CLEAR_OTP_TOKEN, {}));
}

function* forgotPassword({ payload: { email } }) {
  try {
    const response = yield call(forgotPasswordApi, { email });
    yield put(
      authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data)
    );
  } catch (error) {
    yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
  }
}

export function* watchLoginUser() {
  yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

// export function* watchLoginAtCheckout() {
//   yield takeEvery(AuthActionTypes.LOGIN_AT_CHECKOUT,loginAtCheckout);
// }

export function* watchResendOtp() {
  yield takeEvery(AuthActionTypes.RESEND_OTP, resendOtp);
}

export function* watchValidateOtp() {
  yield takeEvery(AuthActionTypes.VALIDATE_USER, validateOtp);
}

export function* watchUpdateUser() {
  yield takeEvery(AuthActionTypes.UPDATE_USER, updateUser);
}

export function* watchLogout() {
  yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchForgotPassword() {
  yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

export function* watchClearOtpToken() {
  yield takeEvery(AuthActionTypes.CLEAR_OTP_TOKEN, clearOtpToken);
}
export function* watchClearLoginStates() {
  yield takeEvery(AuthActionTypes.CLEAR_ACTION, clearLoginStates);
}
export function* watchSetIsLoading() {
  yield takeEvery(AuthActionTypes.IS_LOADING, setIsLoading);
}
export function* watchGuestLoginOnCheckout() {
  yield takeEvery(AuthActionTypes.GUEST_LOGIN, guestLoginOnCheckout);
}
export function* watchloginGuestUser() {
  yield takeEvery(AuthActionTypes.LOGIN_AS_GUEST, loginGuestUser);
}

function* authSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchValidateOtp),
    fork(watchUpdateUser),
    fork(watchLogout),
    fork(watchForgotPassword),
    fork(watchClearOtpToken),
    fork(watchClearLoginStates),
    fork(watchSetIsLoading),
    fork(watchResendOtp),
    fork(watchGuestLoginOnCheckout),
    fork(watchloginGuestUser),
    fork(watchloginGuestUser),
    // fork(watchLoginAtCheckout)
  ]);
}

export default authSaga;
