import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { hasError } from "../../../helpers/api/utils";
import catBreeds from "../../../helpers/catBreeds.json";
import dogBreeds from "../../../helpers/dogBreeds.json";
import Select from "react-select";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import PetBreed from "../petProfile/petInfo/PetBreed";

const PetBasics = ({ formData,setFormData,  validationErrors, handleOnChange }) => {


  const [breeds, setBreeds] = useState(formData.petType ? (formData.petType === "Dog" ? dogBreeds : catBreeds) : []);

  // used to change the input breed
  const onBreedChange = (petType) => {
    if (petType === "Cat") {
      setBreeds(catBreeds);
    } else {
      setBreeds(dogBreeds);
    }
    // Set the petType property instead of deleting it
  delete formData.petType;
  };
  useEffect(() => {
    formData.petBreed = '';
  }, [formData.petType]);

  const customStyles = {

    placeholder: (provided) => ({
      ...provided,
      color: '#D1D1D1',
    }),
  };
  const currentDate = new Date(); // Get the current date
  const maxDateStringForDisplay = moment(currentDate).format("DD-MM-YYYY")// Format as "dd-mm-yyyy"
  const maxDateStringForInput = moment(currentDate).format("YYYY-MM-DD");/// Format as "YYYY-MM-DD"// Format as "YYYY-MM-DD"
  return (
    <div className="step-form two" id="secondStep">
      <div className="step-form-inner">
        <Row>
          <h1>Yay, we love pet! Give us the basics about your pet.</h1>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group as={Col}>
              <Form.Label>Pet Name*</Form.Label>
              <Form.Control
                type="text"
                name="petName"
                value={formData?.petName || ""}
                onChange={(e) => {
                  handleOnChange(e.target.name, e.target.value);
                }}
                placeholder="Pet’s name"
              />
              {hasError(validationErrors, "petName")}
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group as={Col}>
              <Form.Label>Birthday*</Form.Label>
              <Form.Control
                className="discount-user-datepicker"
                type="date"
                // placeholderText={`Please select a date (not after ${maxDateStringForDisplay})`}
                placeholder="Birthday"
                name="petDob"
                value={formData?.petDob || ''}
                onChange={(e) => { handleOnChange("petDob", e.target.value) }}
                max={maxDateStringForInput}
                onKeyDown={(e) => e.preventDefault()} // Disable manual input of dates
              />
              {hasError(validationErrors, "petDob")}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={4}>
            <Form.Group as={Col}>
              <Form.Label>Pet*</Form.Label>
              <div className="spayed-or-neutered">
                <label className="rounded-0 text-white">
                  <input
                    type="radio"
                    name="petType"
                    value="Cat"
                    onChange={(e) => {
                      handleOnChange(e.target.name, e.target.value);
                      onBreedChange(e.target.value);
                    }}
                    checked={formData?.petType === "Cat"}
                    className="d-none"
                  />
                  <span className="text-center">Cat</span>
                </label>
                <label className="rounded-0 text-white">
                  <input
                    type="radio"
                    name="petType"
                    value="Dog"
                    onChange={(e) => {
                      handleOnChange(e.target.name, e.target.value);
                      onBreedChange(e.target.value);
                    }}
                    checked={formData?.petType === "Dog"}
                    className="d-none"
                  />
                  <span className="text-center">Dog</span>
                </label>
              </div>
              {hasError(validationErrors, "petType")}
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group as={Col}>
              <Form.Label>Pet's Gender*</Form.Label>
              <div className='spayed-or-neutered'>
                <label className="rounded-0 text-white">
                  <input
                    type="radio"
                    name="petGender"
                    value={"Female"}
                    onChange={(e) => {
                      handleOnChange(e.target.name, e.target.value);
                    }}
                    checked={formData?.petGender === "Female" ? "checked" : ""}
                    className="d-none"
                  />
                  <span className="text-center">Female</span>
                </label>
                <label className="rounded-0 text-white">
                  <input
                    type="radio"
                    name="petGender"
                    value={"Male"}
                    onChange={(e) => {
                      handleOnChange(e.target.name, e.target.value);
                    }}
                    checked={formData?.petGender === "Male" ? "checked" : ""}
                    className="d-none"
                  />
                  <span className="text-center">Male</span>
                </label>
              </div>
              {hasError(validationErrors, "petGender")}
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group as={Col}>
              <Form.Label>Spayed or Neutered*</Form.Label>
              <div className="spayed-or-neutered">
                <label className="rounded-0 text-white">
                  <input
                    type="radio"
                    name="surgicalSterilizationType"
                    value={"Yes"}
                    onChange={(e) => {
                      handleOnChange(e.target.name, e.target.value);
                    }}
                    checked={
                      formData?.surgicalSterilizationType === "Yes"
                        ? "checked"
                        : ""
                    }
                    className="d-none"
                  />
                  <span className="text-center">Yes</span>
                </label>
                <label className="rounded-0 text-white">
                  <input
                    type="radio"
                    name="surgicalSterilizationType"
                    value={"No"}
                    onChange={(e) => {
                      handleOnChange(e.target.name, e.target.value);
                    }}
                    checked={
                      formData?.surgicalSterilizationType === "No"
                        ? "checked"
                        : ""
                    }
                    className="d-none"
                  />
                  <span className="text-center">No</span>
                </label>
              </div>
              {hasError(validationErrors, "surgicalSterilizationType")}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group as={Col}>
              <Form.Label>Breed*</Form.Label>
              <Select
                menuPlacement="top"
                options={breeds}
                onChange={(item) => {
                  handleOnChange("petBreed", item.value);
                }}
                placeholder="Select Breed"
                styles={customStyles}
              />
              {/* <Select   menuPlacement="top" 

            options={breeds} onChange={(item) => { handleOnChange('petBreed', item.value) }}
             placeholder="Select Breed" /> */}
              {hasError(validationErrors, "petBreed")}
            </Form.Group>
          </Col>

          <Col xs={12} md={6}>
            <Form.Group as={Col}>
              <Form.Label>Weight*</Form.Label>
              <Form.Control
                type="number"
                name="petWeight"
                value={formData?.petWeight || ""}
                onChange={(e) => {
                  handleOnChange(e.target.name, e.target.value);
                }}
                placeholder="Weight  (KGs) "
              />
              {hasError(validationErrors, "petWeight")}
            </Form.Group>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default PetBasics;
// import React from 'react';
// import { Form, Col, Row } from 'react-bootstrap';
// import { hasError } from '../../../helpers/api/utils';
// import moment from 'moment';

// const PetBasics = (
//   {
//     formData,
//     validationErrors,
//     handleOnChange,
//     setFormData,
//   }
// ) => {
//   const currentDate = new Date(); // Get the current date
//   const maxDateStringForDisplay = moment(currentDate).format('DD-MM-YYYY'); // Format as "dd-mm-yyyy"
//   const maxDateStringForInput = moment(currentDate).format('YYYY-MM-DD'); // Format as "YYYY-MM-DD"

//   return (
//     <Row>
//       <Col xs={12} md={6}>
//         <Form.Group as={Col}>
//           <Form.Label>Birthday*</Form.Label>
//           <Form.Control
//             type="date"
//             max={maxDateStringForInput}
//             placeholder={`Please select a date (not after ${maxDateStringForDisplay})`}
//             name="petDob"
//             value={formData?.petDob || ''}
//             onChange={(e) => { handleOnChange("petDob", e.target.value) }}
//           />
//           {hasError(validationErrors, 'petDob')}
//         </Form.Group>
//       </Col>
//     </Row>
//   );
// };

// export default PetBasics;

