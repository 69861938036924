
const apiPrefix = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`;
const config = {
    API_URL: process.env.REACT_APP_API_URL,
    VERSION: process.env.REACT_APP_API_VERSION,
    PRODUCT_IMG_URL: `${process.env.REACT_APP_API_URL}/products`,
    CATEGORY_IMG_URL: `${process.env.REACT_APP_API_URL}/category`,
    SUBCATEGORY_IMG_URL: `${process.env.REACT_APP_API_URL}/subCategory`,
    PRODUCT_IMG_TEMP_URL: `${process.env.REACT_APP_API_URL}/temp`,
    SUBSCRIPTION_PLAN_IMG_URL: `${process.env.REACT_APP_API_URL}/subscriptionPlan`,
    GROOMING_PLAN_IMG_URL: `${process.env.REACT_APP_API_URL}/groomingPlan`,
    HOME_PAGE_CATEGORY_IMG_URL: `${process.env.REACT_APP_API_URL}/homePageCategory`,
    REVIEWS_IMG_URL: `${process.env.REACT_APP_API_URL}/reviews`,
    PET_IMG_URL: `${process.env.REACT_APP_API_URL}/petImages`,
    REFFERAL_URL_PREFIX: `${process.env.REACT_APP_URL}`,
    AMOUNT_PER_PREFRRAL: .50,
    RAZORPAY_APP_KEY: `${process.env.REACT_APP_RAZORPAY_APP_KEY}`,
    RAZORPAY_APP_SECRET: `${process.env.REACT_APP_RAZORPAY_APP_SECRET}`,
    apiPrefix,
};


export default config;
