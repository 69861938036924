import { APICore } from './apiCore';
const api = new APICore();

function getMyReferralPoints() {
    const baseUrl = `/referrals/total-referral-amount`;
    return api.get(`${baseUrl}`, {});
}
function getMyReferralHistory() {
    const baseUrl = `/referrals/history`;
    return api.get(`${baseUrl}`, {});
}

export {
    getMyReferralPoints, getMyReferralHistory
};
