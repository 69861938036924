import React, { useRef } from "react";
import Header from "../../comman/header";
import Footer from "../../comman/footer";
import { useLocation } from "react-router-dom";
import EcommerceOrderDetails from "./EcommerceOrderDetails";
import MealOrderDetails from "./MealOrderDetails";
import ReactToPrint from "react-to-print";

const Details = () => {
  const location = useLocation();
  const { orderDetail } = location?.state ;
  const componentRef = useRef();
  return (
    <>
      <Header />
      <div ref={componentRef}  className="order-details-wrapper">
        <div className="heading">
          <h2>Order details</h2>
        </div>
        {orderDetail?.orderType === 'product' && (
          <EcommerceOrderDetails
            orderDetail={orderDetail}
          />
        )}
        {orderDetail?.orderType === 'customizeMeal' && (
          <MealOrderDetails   orderDetail={orderDetail} />
        )}
        <div> 
          <ReactToPrint
              trigger={() => <div className="printButton">
              <div>Print</div>
            </div>}
              content={() => componentRef.current}
            />
            </div>

      </div>
    
      <Footer />
    </>
  );
};
export default Details;
