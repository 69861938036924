/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Images from "../../helpers/images";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Cart from "./cart";
import Login from "./login";
import Profile from "../pages/profile/profile";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "./navBar";
import { useEffect, useState } from "react";
import { showToast } from "../../helpers/api/utils";
import { clearAction, getProduct } from "../../redux/actions";
import ClickLogin from "./ClickLogin";
import { MobileOnlyView } from "react-device-detect";
import FlashBar from "./flashBar";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { getAllProducts } from "../../helpers";

import toast from "react-hot-toast";
import { debounce, filter } from "lodash";

const Header = () => {
  const {
    user,
    userLoggedIn,
    message,
    userLogout,
    actionPerformed,
    error,
    globalSearchString,
    removeReferralUrl,

  } = useSelector((state) => ({
    user: state.Auth.user,
    userLoggedIn: state.Auth.userLoggedIn,
    userLogout: state.Auth.userLogout,
    message: state.Auth.message,
    error: state.Auth.error,
    actionPerformed: state.Auth.actionPerformed,
    removeReferralUrl: state.Auth.removeReferralUrl,
    globalSearchString: state.Product.name,
    page: state.Product.page,

  }));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [login, loginSetShow] = useState(false);
  // const [showRedeem, setShowRedeem] = useState(true)
  const [searchParams, setSearchParams] = useSearchParams();
  const [product, setProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [showNoResults, setShowNoResults] = useState(false);
  const [lastScroll, setLastScroll] = useState(0);
  const [showFlashBar, setShowFlashBar] = useState(false);

  useEffect(() => {
    if (decodeURIComponent(document.cookie) === "") {
      let date = new Date();
      const afterOneYear = new Date(
        date.getFullYear() + 1,
        date.getMonth(),
        date.getDate()
      );
      date.setTime(afterOneYear.getTime());
      document.cookie =
        "isFirstTime" + " = " + "true" + "; expires = " + date.toGMTString();
      document.cookie =
        "isFirstUser" + " = " + "true" + "; expires = " + date.toGMTString();
      setShowFlashBar(true);
    } else {
      const getCookie = decodeURIComponent(document.cookie).split(";");
      const getCookieValue = getCookie[0].split("=");
      const isTrue = getCookieValue[1];
      setShowFlashBar(!isTrue)
    }

  }, [])

  const stickyHeader = () => {
    const headerHeight = document.querySelector('header').clientHeight;
    const scroll = window.scrollY;
    if (scroll > headerHeight && scroll > lastScroll) {
      document.querySelector('header').classList.add('hide-header');
    } else if (scroll < lastScroll) {
      document.querySelector('header').classList.remove('hide-header');
    }
    setLastScroll(scroll);
  };

  useEffect(() => {
    stickyHeader();
    window.addEventListener('scroll', stickyHeader);
    window.addEventListener('resize', stickyHeader);
    return () => {
      window.removeEventListener('scroll', stickyHeader);
      window.removeEventListener('resize', stickyHeader);
    };
  }, [lastScroll]);




  useEffect(() => {
    if (message?.message) {
      showToast(message?.type, message?.message);
      dispatch(clearAction());
    }
  }, [actionPerformed]);
  useEffect(() => {
    if (userLogout) {
      navigate('/');
    }
  }, [userLogout]);

  useEffect(() => {
    if (removeReferralUrl) {
      if (searchParams.has("referral")) {
        searchParams.delete("referral");
        setSearchParams(searchParams);
      }
    }
  }, [removeReferralUrl]);

  useEffect(() => {
    setName(globalSearchString);
  }, [globalSearchString]);

  useEffect(() => {
    if (error) {
      showToast("error", error);
      dispatch(clearAction());
    }
  }, [error]);

  const handleOnSearch = (item) => {

    navigate(`/product/detail/${item.id}`);
  };

  const handleClick = (e) => {
    e.preventDefault();
    {
      !(user?.phone === undefined) ? navigate("/wishlist") : loginSetShow(true);
    }
  };

  const changeState = (value) => {
    loginSetShow(value);
  };

  const formatResult = (products) => {
    return (
      <>
        <span
          style={{ display: "block", textAlign: "left", cursor: "pointer" }}
        >
          {products.name}
        </span>
      </>
    );
  };

  const getAllProduct = async () => {
    try {
      const data = await getAllProducts();
      const allProducts = data.data.map((items) => (items));
      setAllProducts(allProducts);
    } catch (error) {
      toast.dismiss();
      showToast("error", error?.error);
    }
  };

  useEffect(() => {
    getAllProduct();
  }, []);

  useEffect(() => {
    const filterProducts = allProducts?.filter((item) => (item.name.toLowerCase().includes(name.toLowerCase())));
    setProducts(filterProducts);
    handleChange();
  }, [name]);


  const debouncedSearch = debounce(async () => {
    product?.length <= 0 && setShowNoResults(true)
  }, 300);

  async function handleChange() {
    debouncedSearch();
  }

  return (
    <div className="header-wrapper">
      <header className="sticky-header">
        {showFlashBar && <FlashBar />}

        <Container className="custom-class">
          <Row className="g-0">

            <div className="product-mobile-search">
              <div className="product-search">
                <div className="search-inner">
                  <ReactSearchAutocomplete
                    onSelect={(item) => handleOnSearch(item)}
                    formatResult={formatResult}
                    onSearch={(value) => setName(value)}
                    showNoResults={showNoResults}
                    items={product}
                    inputSearchString={name}
                    placeholder="Search"

                  />

                </div>
              </div>
            </div>

            <div className="header-inner-section">
              <MobileOnlyView>
                <div className="mobile-nav">
                  <NavBar />
                </div>
              </MobileOnlyView>
              <div className="logo-section">
                <div className="header-logo">
                  <Link to="/">
                    {" "}
                    <img
                      loading="lazy"
                      src={Images.Logo}
                      className="img-fluid"
                      alt=""
                      height={"50px"}
                    />
                  </Link>
                </div>
              </div>
              <div className="product-search product-web-search">
                {/* <div className="search-inner"> */}
                <ReactSearchAutocomplete
                  onSelect={(item) => handleOnSearch(item)}
                  formatResult={formatResult}
                  onSearch={(value) => setName(value)}
                  showNoResults={showNoResults}
                  items={product}
                  inputSearchString={name}
                  placeholder="Search"

                />

                {/* <Button type="submit" onClick={(e) => handleOnSearch(e)}>
                  <i className="icon-search"></i>{" "}
                </Button> */}
                {/* </div> */}
              </div>
              <div className="header-order-section">
                <div className="Signin">
                  {!(user?.phone === undefined) ? <Profile /> : <Login />}
                </div>
                <div className="wishlist-section">
                  <Link onClick={(e) => handleClick(e)}>
                    {" "}
                    <i className="icon-favourite"></i>
                  </Link>
                  {login && (
                    <ClickLogin showPopUp={login} changeState={changeState} clickTextValue={'Wishlist'} />
                  )}
                </div>
                <div className="cart-section">
                  <Cart />
                </div>
              </div>
            </div>
          </Row>
        </Container>
        <Row className="g-0">
          <div className="nav-bar-inner">
            <NavBar />
          </div>
        </Row>
      </header>
    </div>
  );
};
export default Header;
