import { Badge, Container, Stack } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentProfile } from "../../../redux/actions";

const PetProfiles = () => {
  const dispatch = useDispatch();

  const { petProfiles, currentPetProfile, loading } = useSelector((state) => ({
    currentProfileStep: state.PetProfile.currentProfileStep,
    currentPetProfile: state.PetProfile.currentPetProfile,
    petProfiles: state.PetProfile.petProfiles,
    actionPerformed: state.PetProfile.actionPerformed,
  }));

  // used to change the Pet Profile
  const handleChangeCurrentProfile = (profile) => {
    const switchProfile ={...profile,isCompleted:"Incomplete"}
    dispatch(
      updateCurrentProfile(
        switchProfile,
        `${profile.currentStep ? profile.currentStep : "PetGender"}`,
      )
    );
  };




  return (
    <div className="pet-profiles-bar-wrapper">
      {petProfiles?.length > 0 && (
        <Container>
          <div className="profiles pet-name-wrapper">
            <Stack direction="horizontal">
              {petProfiles?.map((profile, i) => (
                <span
                  style={{ cursor: "pointer" }}
                  key={`prodile-badge-${i}`}
                  onClick={(e) => {
                    e.preventDefault();
                    !loading && handleChangeCurrentProfile(profile);
                  }}
                >
                  <Badge
                    pill
                    bg={
                      currentPetProfile?.id === profile?.id
                        ? "secondary"
                        : "light"
                    }
                    text={
                      currentPetProfile?.id === profile?.id ? "light" : "dark"
                    }
                  >
                    {profile?.name}
                  </Badge>
                </span>
              ))}
            </Stack>
          </div>
        </Container>
      )}
    </div>
  );
};

export default PetProfiles;
