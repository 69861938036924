/* eslint-disable no-unused-vars */
import { Col, Container, Dropdown, Modal, Row, Tab, Tabs } from "react-bootstrap"
import Footer from "../../comman/footer"
import Header from "../../comman/header"
import { Link, useNavigate } from "react-router-dom"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PageLoader from "../../comman/PageLoader";
import DogGroomingService from "./dogGroomingService";
import CatGroomingService from "./catGroomingService"
import { useEffect, useState } from "react"


const GroomingServices = () => {
 
  const [loading,setLoading] = useState(false);
useEffect(()=>{
 window.scrollTo(0,0)
},[])

  return (
    <>
      <Header />
      <PageLoader loading={loading} />
      <div className="grooming-services-banner-section">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="heading">
                <h1>Pet Grooming Services</h1>
                <p>Book Pet Grooming Service That Comes to You</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
 
      <div id="Dog">
      <DogGroomingService />
      </div>

      <div id="Cat">
      <CatGroomingService />
      </div>

      <Footer />
    </>
  )
}
export default GroomingServices