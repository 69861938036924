import { APICore } from './apiCore';
const api = new APICore();

function groomingServiceList(petType) {
    const baseUrl = `/grooming-services/plans/${petType}`;
    return api.get(`${baseUrl}`);
}

function createRequestGroomingService(params) {
    const baseUrl = `/request-grooming-service`;
    return api.create(`${baseUrl}`, params);
}

export {
    groomingServiceList,
    createRequestGroomingService
};
