import { APICore } from './apiCore';
const api = new APICore();

// category
function getProducts(params) {
    const baseUrl = `/product`;
    return api.get(`${baseUrl}`,params);
}

export {
    getProducts
};
