/* eslint-disable no-empty-pattern */
import { all, fork, put, takeEvery, call } from "redux-saga/effects";

// helpers
import {
  getProducts as productsApi,
 
} from "../../helpers";

// actions
import {
  productApiResponseSuccess,
  productApiResponseError,
} from "./actions";

// constants
import { ProductActionTypes } from "./constants";

/**
 * Order list
 */
function* productList({ payload: {
  params } }) {
  try {
    const response = yield call(productsApi,{...params});
    const responseData = {
      data: response.data,
      name: params.name,
      page: response.data.page,
      limit: response.data.limit,
      totalPages: response.data.totalPages,
      totalResults: response.data.totalResults,
    };
  
    yield put(
      productApiResponseSuccess({
        actionType: ProductActionTypes.PRODUCT_LIST,
        responseData,
      })
    );
  } catch (error) {
    yield put(
      productApiResponseError(ProductActionTypes.PRODUCT_LIST, error)
    );
  }
}

export function* watchOrderList() {
  yield takeEvery(ProductActionTypes.PRODUCT_LIST, productList);
}

function* productSaga() {
  yield all([
    fork(watchOrderList),
   
  ]);
}

export default productSaga;
