// constants
import { APICore } from '../../helpers/api/apiCore';
import { CartActionTypes } from './constants';

const api = new APICore();

const INIT_STATE = {
    cartItems: api.getCartItems(),
    loading: false,
    showItem: null,
    showCart: false,
    actionPerformed: false,
    message: null,
};

const Cart = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CartActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload?.actionType) {
                case CartActionTypes.ITEM_LIST: {
                    return {
                        ...state,
                        cartItems: action.payload?.responseData?.data,
                        loading: false,
                        actionPerformed: false,
                    };
                }
                case CartActionTypes.ADD_ITEM: {

                    const updatedCartItem = [...state.cartItems, action.payload?.responseData?.data];

                    return {
                        ...state,
                        cartItems: updatedCartItem,
                        actionPerformed: true,
                        message: 'Item added to cart',
                    };
                }
                case CartActionTypes.UPDATE_ITEM: {
                    return {
                        ...state,
                        cartItems: action.payload?.responseData?.data,
                        actionPerformed: true,
                        message: '',
                    };
                }
                case CartActionTypes.EMPTY_CART: {
                    return {
                        ...state,
                        cartItems: [],
                        actionPerformed: false,
                    };
                }
                case CartActionTypes.SHOW_ITEM: {

                    return {
                        ...state,
                        showItem: action.payload?.responseData?.data
                    };
                }
                case CartActionTypes.DELETE_ITEM: {
                    return {
                        ...state,
                        cartItems: action.payload?.responseData?.data,
                        showItem: null,
                        actionPerformed: true,
                        message: 'Item removed from cart',
                    };
                }
                case CartActionTypes.TOOGLE_SHOW_CART: {
                    return {
                        ...state,
                        showCart: action.payload?.responseData?.data,
                        actionPerformed:false,

                    };
                }

                default:
                    return { ...state };
            }

        case CartActionTypes.API_RESPONSE_ERROR:
            switch (action.payload?.actionType) {
                case CartActionTypes.ITEM_LIST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: false,
                    };
                }
                case CartActionTypes.ADD_ITEM: {
                    return {
                        ...state,
                        error: action.payload?.error,
                        validationError: action.payload?.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case CartActionTypes.UPDATE_ITEM: {
                    return {
                        ...state,
                        error: action.payload?.error,
                        validationError: action.payload?.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case CartActionTypes.EMPTY_CART: {
                    return {
                        ...state,
                        error: action.payload?.error,
                        validationError: action.payload?.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case CartActionTypes.SHOW_ITEM: {
                    return {
                        ...state,
                        error: action.payload?.error,
                        validationError: action.payload?.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case CartActionTypes.DELETE_ITEM: {
                    return {
                        ...state,
                        error: action.payload?.error,
                        validationError: action.payload?.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default Cart;
