
import Logo from '../assets/images/logo.svg';
import LogoWhite from '../assets/images/logo_white.svg';
import Cart from '../assets/icons/cart.svg';
import Slider1 from '../assets/images/slider/slide_1.webp';
import Slider2 from '../assets/images/slider/slide_2.webp';
import Slider3 from '../assets/images/slider/slide_3.webp';
import Slider4 from '../assets/images/slider/slide_4.webp';
import Slider5 from '../assets/images/slider/slide_5.webp';
import catFood from '../assets/images/cat_food.webp';
import catLover from '../assets/images/cat_lover.webp';
import labradorDog from '../assets/images/labrador_dog.webp';
import accessories from '../assets/images/category/accessories.webp';
import catsFood from '../assets/images/category/cat_food.webp';
import catHealth from '../assets/images/category/cat_health.webp';
import catTreats from '../assets/images/category/cat_treats.webp';
import dogFood from '../assets/images/category/dog_food.webp';
import dogHealth from '../assets/images/category/dog_health.webp';
import dogTreats from '../assets/images/category/dog_treats.webp';
import mealPlansCat from '../assets/images/category/meal_plans_cat.webp';
import mealPlansDog from '../assets/images/category/meal_plans_dog.webp';
import toysCats from '../assets/images/category/toys_cats.webp';
import product1 from '../assets/images/products/product1.webp';
import product2 from '../assets/images/products/product2.webp';
import product3 from '../assets/images/products/product3.webp';
import product4 from '../assets/images/products/product4.webp';
import eye from '../assets/icons/eye.svg';
import cart from '../assets/icons/cart.svg';
import favourate from '../assets/icons/favourate.svg';
import realFood from '../assets/icons/real_food.svg';
import richnutrition from '../assets/icons/rich_nutrition.svg';
import usda from '../assets/icons/usda.svg';
import preservativefree from '../assets/icons/preservative_free.svg';
import madeFresh from '../assets/icons/made_fresh.svg';
import vet from '../assets/icons/vet.svg';
import bowl1 from '../assets/images/bowl_1.webp';
import bowl2 from '../assets/images/bowl_2.webp';
import Dog from '../assets/images/review/dog.webp';
import person1 from '../assets/images/review/1.webp';
import person2 from '../assets/images/review/2.webp';
import person3 from '../assets/images/review/3.webp';
import person4 from '../assets/images/review/4.webp';
import person5 from '../assets/images/review/5.webp';
import person6 from '../assets/images/review/6.webp';
import quotes from '../assets/icons/quotes.svg';
import quotesDwon from '../assets/icons/quotes_dwon.svg';
import Dogbuild from '../assets/images/workPlan/build.svg';
import Dogfall from '../assets/images/workPlan/fall.svg';
import Dogtry from '../assets/images/workPlan/try.svg';
import whatsapp from '../assets/icons/whatsapp.svg';
import instagram from '../assets/icons/instagram.svg';
import facebook from '../assets/icons/facebook.svg';
import Fb from '../assets/icons/social/fb.svg';
import Insta from '../assets/icons/social/insta.png';
import Link from '../assets/icons/social/link.svg';
import WhatsApp from '../assets/icons/social/whatsapp.svg';
import dogFooter from '../assets/images/dog_footer.webp';
import petFoodBanner from '../assets/images/products/pet_food_banner.webp';
import profileImg from '../assets/images/profile/profile_img.png';
import downFill from '../assets/icons/down_fill.svg';
import edit from '../assets/icons/edit.svg';
import petProfile from '../assets/icons/pet_profile.svg';
import logoutFill from '../assets/icons/logout_fill.svg';
import location from '../assets/icons/location.svg';
import email from '../assets/images/contactUs/email.svg';
import phone from '../assets/images/contactUs/phone.svg';
import contactDogCat from '../assets/images/contactUs/dog_cat.webp';
import huskyPuppies from '../assets/images/aboutUs/husky_puppies.webp';
import infoCutPourServe from '../assets/images/aboutUs/info_cut_pour_serve.svg';
import faqDog from '../assets/images/faq/faq_dog.webp';
import fullService from '../assets/images/groomingServices/full_service.webp';
import fullsServiceCat from '../assets/images/groomingServices/full_service_cat.webp';
import spa from '../assets/images/groomingServices/spa.webp';
import spaCat from '../assets/images/groomingServices/spa_cat.webp';
import trans from '../assets/images/groomingServices/trans.webp';
import transCat from '../assets/images/groomingServices/trans_cat.webp';
import locationIcon from '../assets/icons/location_icon.svg';
import starBlue from '../assets/icons/star_blue.svg';
import Cat from '../assets/images/petProfile/cat.webp';
import Dog1 from '../assets/images/petProfile/dog.webp';
import Doginfo from '../assets/images/petProfile/doginfo.webp';
import Catinfo from '../assets/images/petProfile/catinfo.webp';
import femaleDog from '../assets/images/petProfile/female_dog.webp';
import maleDog from '../assets/images/petProfile/male_dog.webp';
import femaleCat from '../assets/images/petProfile/female_cat.webp';
import maleCat from '../assets/images/petProfile/male_cat.webp';
import breed1 from '../assets/images/petProfile/breed_1.webp';
import breed2 from '../assets/images/petProfile/breed_2.webp';
import dogBirthday from '../assets/images/petProfile/dog_birthday.webp';
import dogBirthday2 from '../assets/images/petProfile/dog_birthday_2.webp';
import ChubbyCat from '../assets/images/petProfile/chubby_cat.webp';
import justRightCat from '../assets/images/petProfile/just_right_cat.webp';
import skinnyCat from '../assets/images/petProfile/skinny_cat.webp';
import Chubby from '../assets/images/petProfile/chubby.webp';
import justRight from '../assets/images/petProfile/just_right.webp';
import skinny from '../assets/images/petProfile/skinny.webp';
import catCoco from '../assets/images/petProfile/cat_coco.webp';
import dogCoco from '../assets/images/petProfile/dog_coco.webp';
import catDoctor from '../assets/images/petProfile/cat_doctor.webp';
import spmple from '../assets/images/petProfile/spmple.webp';
import lamb from '../assets/images/petProfile/lamb.webp';
import beefMillet from '../assets/images/petProfile/beef_millet.webp';
import Recommended from '../assets/images/petProfile/recommended.webp';
import firstDiscountModelImg from '../assets/images/PetDiscountBanner/birthday_1.webp';
import secondDiscountModelImg from '../assets/images/PetDiscountBanner/birthday_2.webp';
import thirdDiscountModelImg from '../assets/images/PetDiscountBanner/birthday_3.webp';
import faqIcon from '../assets/icons/faq-icon.svg';
import menu from '../assets/icons/menu.svg';
import orderHistory from '../assets/icons/order_history.svg';
import referral from '../assets/icons/referral.svg';
import cross from '../assets/icons/cross.svg';
import securityPolicy from '../assets/icons/1.png';
import deliveryPolicy from '../assets/icons/2.png';
import invoice from '../assets/icons/bill.png';
import returnPolicy from '../assets/icons/3.png';
import checkBox from '../assets/icons/checkbox.png';
import check from '../assets/icons/check.png';
import packet from '../assets/icons/packet.png';
import referAndEarn from '../assets/images/refer_and_earn.png';
import Copy from '../assets/icons/copy.png';
import detailLink from '../assets/icons/detailLink.png';
import catProfile from '../assets/icons/cat_profile.png';
import dogProfile from '../assets/icons/dog_profile.png';
import animalCare from '../assets/icons/animal_care.png';
// import placeholdeBg from '../assets/images/review/placeholder_bg.png';
import placeholdeBg from '../assets/images/review/placeholder_bg1.png';
import deleteIcon from '../assets/icons/delete.png';
import activeIcon from '../assets/icons/active-profile.svg';
import olympianIcon from "../assets/icons/olympian.svg";
import wailksIcon from "../assets/icons/walks.svg";
import walkspinkIcon from "../assets/icons/walks_pink.svg";
import activepinkIcon from '../assets/icons/active_pink.svg';
import olympianpinkIcon from '../assets/icons/olympian_pink.svg';
import userIcon from '../assets/icons/user.png';


const Images = {
    Logo,
    checkBox,
    check,
    secondDiscountModelImg,
    thirdDiscountModelImg,
    orderHistory,
    referral,
    firstDiscountModelImg,
    deleteIcon,
    LogoWhite,
    userIcon,
    Cart,
    invoice,
    Slider1,
    Slider2,
    Slider3,
    Slider4,
    Slider5,
    catFood,
    catLover,
    labradorDog,
    accessories,
    placeholdeBg,
    catsFood,
    catHealth,
    catTreats,
    dogFood,
    dogHealth,
    dogTreats,
    mealPlansCat,
    mealPlansDog,
    toysCats,
    product1,
    product2,
    product3,
    
    product4,
    eye,
    cart,
    favourate,
    realFood,
    usda,
    richnutrition,
    madeFresh,
    preservativefree,
    vet,
    bowl1,
    bowl2,
    Dog,
    quotes,
    quotesDwon,
    person1,
    person2,
    person3,
    person4,
    person5,
    person6,
    Dogbuild,
    Dogfall,
    Dogtry,
    whatsapp,
    instagram,
    facebook,
    Link,
    Fb,
    Insta,
    WhatsApp,
    dogFooter,
    petFoodBanner,
    profileImg,
    downFill,
    edit,
    petProfile,
    location,
    email,
    phone,
    logoutFill,
    contactDogCat,
    huskyPuppies,
    infoCutPourServe,
    faqDog,
    fullService,
    fullsServiceCat,
    spa,
    spaCat,
    trans,
    transCat,
    locationIcon,
    starBlue,
    Cat,
    Dog1,
    animalCare,
    Doginfo,
    Catinfo,
    femaleDog,
    maleDog,
    faqIcon,
    femaleCat,
    maleCat,
    breed1,
    breed2,
    dogBirthday,
    dogBirthday2,
    Chubby,
    justRight,
    skinny,
    ChubbyCat,
    justRightCat,
    skinnyCat,
    catCoco,
    dogCoco,
    catDoctor,
    spmple,
    lamb,
    beefMillet,
    Recommended,
    menu,
    cross,
    securityPolicy,
    deliveryPolicy,
    returnPolicy,
    packet,
    referAndEarn,
    Copy,
    detailLink,
    catProfile,
    dogProfile,
    activeIcon,
    olympianIcon,
    wailksIcon,
    walkspinkIcon,
    activepinkIcon ,
    olympianpinkIcon


}
export default Images;
