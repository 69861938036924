import React, { Suspense } from "react";
import { useState, useEffect } from "react";
import { Modal, Row } from "react-bootstrap";
import { addConsultVet as addConsultVetApi } from "../../../helpers";
import { showToast } from "../../../helpers/api/utils";
import PageLoader from "../../comman/PageLoader";
import Images from "../../../helpers/images";
import { toast } from "react-hot-toast";
import submitSuccess from "../../../helpers/submitSuccess.json";
import OwnerDetails from "./OwnerDetails";
import PetBasics from "./PetBasics";
import PetDetails from "./PetDetails";
import Lottie from "react-lottie";
import { Link } from "react-router-dom";
import moment from "moment";
import { addNutritionistVet } from "../../../helpers/api/nutritionist-vet";

const Header = React.lazy(() => import("../../comman/header"));

// const OwnerDetails = React.lazy(() => import('./OwnerDetails'));
// const PetBasics = React.lazy(() => import('./PetBasics'));
// const PetDetails = React.lazy(() => import('./PetDetails'));

const NutritionistVet = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const steps = 3;
 

  //used to handle the next button

  const handleNext = () => {
    if (currentStep === 1) {
      const hasError = firstcheckValidations(formData);
      if (hasError?.length > 0) {
        setLoading(false);
        return false;
      }
      if (currentStep < steps) {
        setCurrentStep((prevStep) => prevStep + 1);
      }
    }

    if (currentStep === 2) {
      const hasError = secondcheckValidations(formData);

      if (hasError?.length > 0) {
        setLoading(false);
        return false;
      }
      if (currentStep < steps) {
        setCurrentStep((prevStep) => prevStep + 1);
      }
    }
    //  const secondhasError = secondcheckValidations(formData);
    //  if (secondhasError?.length > 0) {
    //   setLoading(false)
    //   return false;
    // }
  };
  //used to handle the back button

  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  //used to handle the chnages in input fields

  const handleOnChange = (name, value) => {
    let errors = [];
    errors = validationErrors?.filter((err) => err.key !== name);
    if (name === "phone") {
      if (value.length <= 10) {
        setFormData((prv) => ({
          ...prv,
          [name]: value,
        }));
      } else {
        return;
      }
      setValidationErrors(errors);
    }

    if (name === "pinCode") {
      if (value.length <= 6) {
        setFormData((prv) => ({
          ...prv,
          [name]: value,
        }));
      }
      setValidationErrors(errors);
    }
    // if (name === 'pinCode') {
    //   if (!/^[1-9]\d*$/.test(value)){
    //     const error = {
    //       error: "Please provide a valid pincode",
    //       key: "pinCode",
    //     };
    //     setValidationErrors(errors);
    //   }
    // }
    if (name === 'pinCode') {
      if (!/^[1-9]\d*$/.test(value)){
        const error = {
          error: "Please provide a valid pincode",
          key: "pinCode",
        };
        errors.push(error);
      }
    }
   

    if (name === "allergies" && value?.includes("None")) {
      setFormData((prv) => ({
        ...prv,
        allergies: ["None"],
      }));
    }
    if (name === "pinCode") {
      if (value.length <= 6) {
        setFormData((prevstate) => ({
          ...prevstate,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevstate) => ({
        ...prevstate,
        [name]: value,
      }));
      setValidationErrors(errors);
    }
   
    // setDate();
    
    localStorage.setItem('consult-vet-form', JSON.stringify(formData));
   
  }
 
 

  //used to final submit the form 

  const onSubmit = async () => {
    setLoading(true);
    const hasError = thirdcheckValidations(formData);
    toast.dismiss();  
    if (hasError.length > 0) {
      setLoading(false);
      return;
    }
    try {
      await addNutritionistVet(formData);
      setLoading(true);
      setFormData({});
      setShowModel(true);
      setCurrentStep(1);
      setLoading(false);
    } catch (error) {
      setShowModel(false);
      setLoading(false);
      if (error.status === 422) {
        setValidationErrors([error?.error]);
        showToast("error", "Please Check form values again.");
      } else {
        showToast("error", error);
      }
    }
    localStorage.removeItem('consult-vet-form');
    console.log(formData)
   
  }

  useEffect(() => {
    const storedFormData = localStorage.getItem("consult-vet-form");
    if (storedFormData) {
     
      setFormData(JSON.parse(storedFormData));

    }

   
  }, []);

  //used to check the validation on first step

  const firstcheckValidations = (data) => {
    const errors = [];
    if (data.firstName === undefined || data.firstName === "") {
      const error = {
        error: "Please provide your first name",
        key: "firstName",
      };
      errors.push(error);
    }

    const RegexEmailValidation = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (!RegexEmailValidation.test(data.email)) {
      const error = {
        error: "Please provide your valid email ",
        key: "email",
      };
      errors.push(error);
    }

    if (data.state === undefined || data.state === "") {
      const error = {
        error: "Please provide your state ",
        key: "state",
      };
      errors.push(error);
    }
    

    if (
      data.pinCode === undefined ||
      data.pinCode === "" ||
      data.pinCode.length < 6
    ) {
      const error = {
        error: "Please provide your pin code ",
        key: "pinCode",
      };
      errors.push(error);
    }

    const validatePhone = /^[6-9]{1}[0-9]{9}$/;
    if (!validatePhone.test(data.phone)) {
      const error = {
        error: "Please provide your valid phone number ",
        key: "phone",
      };
      errors.push(error);
    }
    // if (data.phone == undefined) {
    //   const error = {
    //     error: "Please provide your phone number ",
    //     key: "phone"
    //   };
    //   errors.push(error);
    // }
    if (data.city === undefined || data.city === "") {
      const error = {
        error: "Please provide your city ",
        key: "city",
      };
      errors.push(error);
    }
    if (data.lastName === undefined || data.lastName === "") {
      const error = {
        error: "Please provide your last name ",
        key: "lastName",
      };
      errors.push(error);
    }

    if (errors.length > 0) {
      setValidationErrors(errors);
    }
    return errors;
  };

  //used to check the validation on second step

  const secondcheckValidations = (data) => {
    const errors = [];
    if (data.petName === undefined || data.petName === "") {
      const error = {
        error: "Please provide your pet name ",
        key: "petName",
      };
      errors.push(error);
    }
    if (data.petType === undefined || data.petType === "") {
      const error = {
        error: "Please provide your pet type ",
        key: "petType",
      };
      errors.push(error);
    }

    if (data.petGender === undefined || data.petGender === "") {
      const error = {
        error: "Please provide your pet gender ",
        key: "petGender",
      };
      errors.push(error);
    }
    if (data.petBreed === undefined || data.petBreed === "") {
      const error = {
        error: "Please provide your  pet breed ",
        key: "petBreed",
      };
      errors.push(error);
    }

    if (data.petDob === undefined || data.petDob === "") {
      const error = {
        error: "Please provide your  pet birtday ",
        key: "petDob",
      };
      errors.push(error);
    }

    if (data.petWeight === undefined || data.petWeight === "") {
      const error = {
        error: "Please provide your pet weight ",
        key: "petWeight",
      };
      errors.push(error);
    }
    if (
      data.surgicalSterilizationType === undefined ||
      data.surgicalSterilizationType === ""
    ) {
      const error = {
        error: "Please provide your surgical sterilization type ",
        key: "surgicalSterilizationType",
      };
      errors.push(error);
    }

    if (errors.length > 0) {
      setValidationErrors(errors);
    }
    return errors;
  };

  // used to check the validation on last step of submission

  const thirdcheckValidations = (data) => {
    let errors = [];
    if (data.eatingHabit === undefined || data.eatingHabit === "") {
      const error = {
        error: "Please provide your pet eating habit ",
        key: "eatingHabit",
      };
      errors.push(error);
    }
    // if (data.healthComplaints === undefined || data.healthComplaints === "") {
    //   const error = {
    //     error: "Please provide your pet health complaints ",
    //     key: "healthComplaints"
    //   };
    //   errors.push(error);
    // }
    // if (data?.allergies?.length <= 0 || data?.allergies === undefined) {
    //   const error = {
    //     error: "Please provide your pet allergies",
    //     key: "allergies"
    //   };
    //   errors.push(error);
    // }

    // if ((data?.healthComplaints?.includes("Other"))) {
    //   if (data?.otherHealthProblem === undefined || data?.otherHealthProblem === "") {
    //     const error = {
    //       error: "Please provide your pet other health problem",
    //       key: "healthComplaints"
    //     };
    //     errors.push(error);
    //   }
    // }

    // if ((data?.allergies?.includes("Other"))) {
    //   if (data?.specifyAllergies === undefined || data?.specifyAllergies === "") {
    //     const error = {
    //       error: "Please provide your pet other specify allergies",
    //       key: "allergies"
    //     };
    //     errors.push(error);
    //   }
    // }

    if (errors.length > 0) {
      setValidationErrors(errors);
    }
    return errors;
  };

  // used for show the success model after submit
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: submitSuccess,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // const activeStepWithError = async () => {
  //   const owner = ['firstName', 'lastName', 'email', 'phone', 'state', 'city', 'pinCode']
  //   console.log(validationErrors);
  // }

  return (
    <>
      <Suspense fallback={<PageLoader loading={loading}></PageLoader>}>
        <Header />
        <div className="consult-vet-wrapper-section">
          <Row className="g-0">
            <div className="consult-vet-wrapper">
              <Row className="g-0">
                <div className="consult-vet-form">
                  <div className="step-section">
                    <div className="logo">
                      <img
                        src={Images.LogoWhite}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <ul id="progressbar">
                      <div
                        className={
                          currentStep === 1
                            ? "active progressbar-inner"
                            : "progressbar-inner"
                        }
                      >
                        <li id="account"></li>
                        <span>Human profile</span>
                      </div>
                      <div
                        className={
                          currentStep === 2
                            ? "active progressbar-inner"
                            : "progressbar-inner"
                        }
                      >
                        <li id="account"></li>
                        <span>Pet basics</span>
                      </div>
                      <div
                        className={
                          currentStep === 3
                            ? "active progressbar-inner"
                            : "progressbar-inner"
                        }
                      >
                        <li id="account"></li>
                        <span>Pet details</span>
                      </div>
                    </ul>
                  </div>
                  <div className="form-section">
                    {currentStep === 1 && (
                      <OwnerDetails
                        formData={formData}
                        validationErrors={validationErrors}
                        handleOnChange={(name, val) => {
                          handleOnChange(name, val);
                        }}
                      />
                    )}
                    {currentStep === 2 && (
                      <PetBasics
                        formData={formData}
                     
                        validationErrors={validationErrors}
                        handleOnChange={(name, val) => {
                          handleOnChange(name, val);
                        }}
                      />
                    )}
                    {currentStep === 3 && (
                      <PetDetails
                        formData={formData}
                        validationErrors={validationErrors}
                        handleOnChange={(name, val) => {
                          handleOnChange(name, val);
                        }}
                      />
                    )}
                    <div className="button-section">
                      {currentStep > 1 && (
                        <button
                          type="button"
                          className="previous action-button-previous"
                          onClick={handlePrevious}
                        >
                          {" "}
                          Back
                        </button>
                      )}
                      {currentStep < steps && (
                        <button
                          type="button"
                          className="next action-button"
                          onClick={handleNext}
                        >
                          {" "}
                          Next
                        </button>
                      )}
                      {currentStep === steps && (
                        <button
                          type="button"
                          className="submit action-button"
                          value="Submit"
                          onClick={!loading ? onSubmit : undefined}
                        >
                          {loading ? "Please Wait..." : "Submit"}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </Row>
            </div>
          </Row>
        </div>
        {showModel && (
          <Modal
            className="success-modal-wrapper"
            size="sm"
            centered
            show={true}
          >
            <Lottie options={defaultOptions} height={200} width={200} />
            <Modal.Body>
              <p>
                {" "}
                Thank You ! <span>We will be in touch shortly.</span>{" "}
              </p>
            </Modal.Body>
            <div className="suceessModelButton">
              <div className="review-btn">
                <Link to="/"> Home</Link>{" "}
              </div>
            </div>
          </Modal>
        )}
      </Suspense>
    </>
  );
};
export default NutritionistVet;
