import { toast } from "react-hot-toast";

const downloadFile = ({ data, filename, mime, bom }) => {
    var blobData = typeof bom !== 'undefined' ? [bom, data] : [data];
    var blob = new Blob(blobData, { type: mime || 'application/octet-stream' });

    var blobURL =
        window.URL && window.URL.createObjectURL
            ? window.URL.createObjectURL(blob)
            : window.webkitURL.createObjectURL(blob);
    var tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    
    if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tempLink);
    tempLink.click();

    // Fixes "webkit blob resource error 1"
    setTimeout(function () {
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(blobURL);
    }, 200);
};

const getSizePageList = ({ pageLength }) => {
    return [
        {
            text: '10',
            value: 10,
        },
        {
            text: '25',
            value: 25,
        },
        {
            text: '50',
            value: 50,
        },
        {
            text: 'All',
            value: pageLength ? pageLength : 0,
        },
    ];
}
const defualtSizePageList = [
    {
        text: '10',
        value: 10,
    },
    {
        text: '25',
        value: 25,
    },
    {
        text: '50',
        value: 50,
    },
];



const states = [
    { label: 'ACTIVE', value: 'ACTIVE' },
    { label: 'INACTIVE', value: 'INACTIVE' },
];
const petType = [
    { label: 'Dog', value: 'Dog' },
    { label: 'Cat', value: 'Cat' },
];
const hasSubscriptionType = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
];
const subscriptionType = [
    { label: 'General', value: 'General' },
    { label: 'Product', value: 'Product' },
];

const hasError = (errors, key) => {
    if(errors === undefined){
        return null;
    }
    if(typeof(errors) === 'string' ){
        return  null;
    }
    const hasError = errors?.findIndex(err => err.key === key);
    if (hasError === -1)
        return null;
    else
        return <small className="text-danger pet-basic-info-error">{errors[hasError]?.error}</small>;
}
const showToast = (type, message) => {
    const customStyle = {
        style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
        },
    }
    switch (type) {
        case 'success':
            toast.success(message, customStyle);
            break;
        case 'error':
            toast.error(message, customStyle);
            break;

        default:
            toast(message, customStyle);
            break;
    }
}

const isPositveIntegerNumber = (string) => {
    if (string) {
        return (/^[0-9]\d*$/.test(string))
    }
    return true;
}

export { downloadFile, getSizePageList, hasError, isPositveIntegerNumber, states, defualtSizePageList, petType, hasSubscriptionType, subscriptionType, showToast };
