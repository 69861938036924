import React, { forwardRef, useRef, useState } from "react";
import { Container, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import InlineLoader from "../../comman/InlineLoader";
import moment from "moment";
import Images from "../../../helpers/images";
import ReactToPrint from "react-to-print";
import Details from "./Details";

const EcommerceOrders = ({ orders, loading }) => {
  const totalOrders = orders ? orders.length : 0;

  return (
    <>
      <div className="order-history-data">
        <Container>
          <Row>
            <div className="card-box">
              <h4>
                Here are the orders you've placed since your account was
                created. Total Orders: {totalOrders}
              </h4>
              <div
                className="table-responsive"
                style={{ position: "relative", width: "100%", minHeight: 150 }}
              >
                <Table bordered>
                  <thead>
                    <tr>
                      <th>Order reference</th>
                      <th>Date</th>
                      <th>Total price </th>
                      <th>Payment</th>
                      <th>Status</th>
                      <th>Invoice</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <InlineLoader loading={loading} />
                    {orders?.length > 0 &&
                      orders?.map((item) => (
                        <tr key={`order-${item?.id}`}>
                          <td>{item?.orderRefNo} </td>
                          <td>
                            {moment(item?.createdAt).format("DD-MM-YYYY")}
                          </td>
                          <td>₹ {item.totalAmount>0 ? (item?.totalAmount).toFixed(2):0}</td>
                          <td>COD</td>
                          <td>
                            <div className="status-bg">{item.status}</div>
                          </td>
                          
                          <td>
                            <Link to="/invoice" state={{ orderDetail: item }}>
                              <img
                                className="img-fluid"
                                src={Images.invoice}
                                alt="bill"
                                height={"25px"}
                                width={"25px"}
                              />
                            </Link>
                          </td>

                          <td>
                            <ul className="action-btn">
                              <li>
                                {" "}
                                <Link
                                  to="/details"
                                  state={{ orderDetail: item }}
                                >
                                  Details
                                </Link>{" "}
                              </li>
                              {/* <li><span>Reorder</span> </li> */}
                            </ul>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default EcommerceOrders;

