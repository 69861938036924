/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

// actions
import { loginUser, validateUser, clearOtpToken, isLoading, resendOtp, clearAction } from '../../redux/actions';

import { useSelector, useDispatch } from 'react-redux';
import { toast } from "react-hot-toast";
import { showToast } from "../../helpers/api/utils";
import OtpInput from 'react-otp-input';
import { debounce } from "lodash";
import Images from "../../helpers/images";

const Login = () => {

    const [searchParams] = useSearchParams();
    const referralCode = searchParams.get("referral") ? searchParams.get("referral") : '';
    const dispatch = useDispatch();

    const [login, loginSetShow] = useState(false);
    const loginModalClose = () => {
        loginSetShow(false);
        setCurrentStep(1)
        dispatch(clearOtpToken());
        setContinueButtonDisabled(true);
    }
    const showLogin = () => loginSetShow(true);
    const [phone, setPhone] = useState("");
    const [toc, setToc] = useState(true);
    const [otp, setOtp] = useState("");
    const [seconds, setSeconds] = useState(0)
    const [currentStep, setCurrentStep] = useState(1)
    const [continueButtonDisabled, setContinueButtonDisabled] = useState(false);
    const [cancelButtonDisabled, setCancelButtonDisabled] = useState(false);

    const { loading, tempToken,user,message,cartItems } = useSelector((state) => ({
        user: state.Auth.user,
        message: state.Auth.message,
        tempToken: state.Auth.tempToken,
        error: state.Auth.error,
        loading: state.Auth.loading,
        cartItems: state.Cart.cartItems,
        userLoggedIn: state.Auth.userLoggedIn,
        actionPerformed: state.Auth.actionPerformed,
    }));

      /*
       handle form submission
    */

    const handelNumber = (e) => {
        e.preventDefault();
        if (e.nativeEvent.data === "+" || e.nativeEvent.data === "-" || e.nativeEvent.data === ".") {
            return;
        }
        if (e.target.value.length <= 10) {
            setPhone(e.target.value)
        }


    }

    const handleOtp = (val) => {
        if (val.length <= 4) {
            setOtp(val);
        }
    }
    useEffect(() => {
        toast.dismiss();
        if (otp.length === 4) {
            handleSubmitOtp();
        }
    }, [otp]);

    const handleEnterPress = (e, key) => {
        if (e.keyCode === 13) {
            key === "PHONE" ? onSubmit(e) : handleSubmitOtp(e);
        }
    };


    const onSubmit = (e) => {
        e.preventDefault()
        toast.dismiss()
        setSeconds(30)

        if (!phone) {
            showToast('error', 'Please enter mobile number.');
            return;
        }
        const validatePhone = /^[6-9]{1}[0-9]{9}$/
        if (!(validatePhone.test(phone))) {
            showToast('error', 'Please enter valid mobile number.')
            return;
        }

        if (!toc) {
            showToast('error', 'Please agree to the terms and conditions.');
            return;
        }
        setCurrentStep(2)
        dispatch(loginUser(phone));
    };


console.log(cartItems);

    const debouncedSearch = debounce(async () => {  
        dispatch(isLoading());
        dispatch(validateUser(otp, tempToken ,referralCode,cartItems));
      }, 1000);
      
      async function handleSubmitOtp() {
        toast.dismiss();
        debouncedSearch();
        setCancelButtonDisabled(true);
      }

    // const handleSubmitOtp = () => {
    //     ;
    // };

    const handleResendOtp = (e) => {
        e.preventDefault();
        toast.dismiss();
        setSeconds(30);
        dispatch(isLoading());
        dispatch(resendOtp(tempToken));
    }


    useEffect(()=>{
        setCurrentStep(1);
    },[])


    useEffect(() => {
        const Timer = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1)
            }
        }, 1000)
        return () => clearInterval(Timer);

    }, [onSubmit, handleResendOtp], [seconds])

const handleSignIn=()=>{
    dispatch(clearAction());
    showLogin();
}

    return (
        <>
            <div onClick={handleSignIn}  className="login-btn"><img src={Images.userIcon} className='img-fluid' alt='Sign in' title="Sign in" /> </div>
            {/* <div onClick={handleSignIn}  className="login-btn">Sign in </div> */}
            <Modal size="sm" className="login-modal" centered show={login} onHide={loginModalClose}>
                <Modal.Body>
                    <div className="login-wrapper">
                        {currentStep===1 && (
                            <>
                                <div className="heading">
                                    <h2>Enter mobile number</h2>
                                </div>
                                <Form>
                                    <div className="form-grup">
                                        <div className="phone-number">
                                            <input type="number" value={phone} onKeyDown={(e) => handleEnterPress(e, "PHONE")} onChange={(e) => { handelNumber(e) }} className="form-control" placeholder="Enter your mobile number" maxlength="10" />
                                            <label className="code">+91</label>

                                        </div>
                                    </div>
                                    <div className="form-grup checkbox-wrapper">
                                        <input type="checkbox" id="termsandconditions" checked={toc ? 'checked' : ''} onChange={(e) => setToc(!toc)} />
                                        <label htmlFor="termsandconditions">I agree to the <Link to='/legal?title=privacy-policy'>terms and conditions.</Link></label>
                                    </div>
                                    <div className="btn-section">
                                        <div onClick={loginModalClose} className="cancelbtn"><span>Cancel</span></div>
                                        <div onClick={(e) => { !loading ? onSubmit(e) : e.preventDefault() }} className="sendbtn"><span>{loading ? 'Wait..'  :  'Send'}</span></div>
                                    </div>
                                </Form>
                            </>
                        )} { currentStep===2 && (
                            <>
                                <div className="heading">
                                    <h2>Enter your code</h2>
                                    <p>We sent an SMS with a 4-digit code to <b>{phone}</b></p>
                                </div>
                                <Form>
                                    <div className="form-grup">
                                        <div className="phone-number otp-screen">
                                            <OtpInput
                                                shouldAutoFocus={true}
                                                onKeyDown={(e) => handleEnterPress(e, "OTP")}
                                                value={otp}
                                                onChange={(val) => handleOtp(val)}
                                                numInputs={4}
                                                inputStyle={false}
                                                inputType="number"
                                                renderInput={(props) => <input {...props} />}
                                            />
                                            {/* <input type="number" value={otp} onChange={(e) => handleOtp(e.target.value)} className="otp" maxLength={4} /> */}
                                        </div>
                                    </div>
                                    <div className="time-wrapper">
                                        {seconds > 0 && (<p>Resend in <span>{seconds}</span></p>)}
                                        {seconds === 0 && (<p style={{ cursor: 'pointer' }} onClick={handleResendOtp}> Resend Otp</p>)}
                                    </div>
                                    <div className="btn-section">
                                        <div   disabled={cancelButtonDisabled} onClick={loginModalClose} className="cancelbtn"><span>Cancel</span></div>
                                        <div    disabled={continueButtonDisabled} onClick={(e) => { !loading ? handleSubmitOtp(e) : e.preventDefault() }} className="sendbtn"><span>{loading ? 'Wait..' : 'Continue'}</span></div>
                                    </div>
                                </Form>
                            </>
                        )}
                    </div>

                    {tempToken !== ''}
                </Modal.Body>
            </Modal>
        </>
    )
}
export default Login
