// constants
import { ProductActionTypes } from './constants';

// common success
export const productApiResponseSuccess = ({ actionType, responseData }) => (
    {
        type: ProductActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, responseData },
    });

export const productApiResponseError = (actionType, error) => ({
    type: ProductActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});
export const getProduct = (params) => ({
    type: ProductActionTypes.PRODUCT_LIST,
    payload: {params},
    
}
);


