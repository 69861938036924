import { APICore } from './apiCore';
const api = new APICore();


function discountCoupon(params){
    console.log(params);
    const baseUrl = `/auth/discountCoupon`;
    return api.create(`${baseUrl}`,params);
}

export {
    discountCoupon,
};
