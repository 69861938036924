// constants
import { APICore } from "../../helpers/api/apiCore";
import { PetProfileActionTypes } from "./constants";

const api = new APICore();

const INIT_STATE = {
  petProfiles: api.getLoggedInUser()?.allPetProfiles,
  currentProfileStep: api.getCurrentProfileStep(),
  currentPetProfile: api.getCurrentPetProfile(),
  message: {},
  actionPerformed: false,
  loading: false,
};


const PetProfile = (state = INIT_STATE, action) => {
  switch (action.type) {
    case PetProfileActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload?.actionType) {
        case PetProfileActionTypes.PET_PROFILE_LIST: {
          return {
            ...state,
            petProfiles: action.payload?.responseData?.data,
            page: action.payload?.responseData?.page,
            limit: action.payload?.responseData?.limit,
            totalPages: action.payload?.responseData?.totalPages,
            totalResults: action.payload?.responseData?.totalResults,
          };
        }
        case PetProfileActionTypes.ADD_PET_PROFILE: {
          return {
            ...state,
            petProfiles: action.payload?.responseData?.data,
            currentProfileStep:
              action.payload?.responseData?.currentProfileStep,
            currentPetProfile: action.payload?.responseData?.currentPetProfile,
            message: { type: "success", message: "Pet Profile created" },
            actionPerformed: true,
          };
        }
        case PetProfileActionTypes.UPDATE_PET_PROFILE: {
            const currentProfile = action.payload?.responseData?.data;
            const user = api.getLoggedInUser();
         
          const updatedProfiles = user?.allPetProfiles?.map((item) =>
            item.id === currentProfile?.id ? currentProfile : item
          );
          api.setLoggedInUser({ ...user, allPetProfiles: updatedProfiles });
          return {
            ...state,
            petProfiles: updatedProfiles,
            currentProfileStep: action.payload?.responseData?.nextPetProfileStep,
            currentPetProfile: currentProfile,
            message: {
              type: "success",
              message: "Pet Profile updated successfully",
            },
            actionPerformed: true,
            loading: true,
          };
        }
        case PetProfileActionTypes.UPDATE_CURRENT_PROFILE: {
          return {
            ...state,
            currentProfileStep: action.payload?.responseData?.step,
            currentPetProfile: action.payload?.responseData?.profile,
            petProfiles: action.payload?.responseData?.updatedProfiles,
            actionPerformed: true,
            loading: true,
          };
        }
        case PetProfileActionTypes.UPDATE_ALL_PET_PROFILE: {
          return {
            ...state,
            petProfiles: action.payload?.responseData?.profiles,
            loading: false,
          };
        }

        case PetProfileActionTypes.DELETE_PET_PROFILE: {
          return {
            ...state,
            petProfiles: action.payload?.responseData?.updatedPetProfiles,
            message: { type: "success", message: "Pet Profile deleted" },
            actionPerformed: true,
          };
        }
        case PetProfileActionTypes.UPDATE_PROFILE_STEP: {
          return {
            ...state,
            currentProfileStep: action?.payload?.responseData?.data,
            loading: true,
            message: {  },
            actionPerformed: true,
          };
        }
        case PetProfileActionTypes.UPDATE_STEP: {
          return {
            ...state,
            currentProfileStep:
              action?.payload?.responseData?.currentStep,
          };
        }
      
        case PetProfileActionTypes.SET_UP_PET_PROFILE: {
          return {
            ...state,
            currentProfileStep:
              action?.payload?.responseData?.currentProfileStep,
            currentPetProfile: action?.payload?.responseData?.currentPetProfile,
            petProfiles: action?.payload?.responseData?.petProfiles,
            message: action?.payload?.responseData?.ShowUpdatedMessage
              ? { type: "success", message: "Pet Profile updated" }
              : {},
            actionPerformed: action?.payload?.responseData?.ShowUpdatedMessage
              ? true
              : "",
            loading: true,
          };
        }

        case PetProfileActionTypes.ADD_MEAL_PLAN_PET_PROFILE: {
          return {
            ...state,
            petProfiles: action.payload?.responseData?.allProfiles,
            currentPetProfile: action.payload?.responseData?.currentPetProfile,
            currentProfileStep:
            action.payload?.responseData?.currentProfileStep,
            message: { type: "success", message: "Pet Profile updated" },
            actionPerformed: true,
            loading: true,
          };
        }

        case PetProfileActionTypes.PROCEED_TO_CHECKOUT: {
          return {
            ...state,
            petProfiles: action.payload?.responseData?.allProfiles,
            currentPetProfile: action.payload?.responseData?.currentPetProfile,
            currentProfileStep:'Checkout',
            message: { type: "success", message: "Pet Profile updated" },
            actionPerformed: true,
            loading: true,
          };
        }
        case PetProfileActionTypes.RESET_ACTION: {
          return {
            ...state,
            message: {},
            actionPerformed: false,
            loading: false,
            error: [],
          };
        }
        case PetProfileActionTypes.API_RESPONSE_ERROR:
            switch (action.payload?.actionType) {
                case PetProfileActionTypes.PET_PROFILE_LIST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: false,
                    };
                }
                case PetProfileActionTypes.UPDATE_PET_PROFILE: {
                    return {
                        ...state,
                        error: action.payload?.error,
                        validationError: action.payload?.error,
                        loading: false,
                        message: { type: "success", message: "Pet Profile updated" },
                        actionPerformed: false,
                    };
                }
                case PetProfileActionTypes.UPDATE_CURRENT_PROFILE: {
                    return {
                        ...state,
                        error: action.payload?.error,
                        validationError: action.payload?.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case PetProfileActionTypes.UPDATE_ALL_PET_PROFILE: {
                    return {
                        ...state,
                        error: action.payload?.error,
                        validationError: action.payload?.error,
                        hasErrors: true,
                        loading: false,
                        submitted: true,
                    };
                }
                case PetProfileActionTypes.IS_LOADING: {
                  return {
                      ...state,
                      loading: true,
                  };
              }
                default:
                    return { ...state };
            }
        default:
          return { ...state };
      }

    case PetProfileActionTypes.API_RESPONSE_ERROR:
      switch (action.payload?.actionType) {
        case PetProfileActionTypes.PET_PROFILE_LIST: {
          return {
            ...state,
            error: action.payload.error,
            hasErrors: true,
            loading: true,
            submitted: false,
          };
        }
        case PetProfileActionTypes.UPDATE_PET_PROFILE: {
          return {
            ...state,
            error: action.payload?.error,
            validationError: action.payload?.error,
            hasErrors: true,
            loading: true,
            submitted: true,
          };
        }
        case PetProfileActionTypes.UPDATE_CURRENT_PROFILE: {
          return {
            ...state,
            error: action.payload?.error,
            validationError: action.payload?.error,
            hasErrors: true,
            loading: true,
            submitted: true,
          };
        }
        case PetProfileActionTypes.UPDATE_ALL_PET_PROFILE: {
          return {
            ...state,
            error: action.payload?.error,
            validationError: action.payload?.error,
            hasErrors: true,
            loading: false,
            submitted: true,
          };
        }
        default:
          return { ...state };
      }
    default:
      return { ...state };
  }
};

export default PetProfile;
