import { Dropdown } from "react-bootstrap"
import config from "../../../../config"


const PackageItem = (
    {
        item,
        packageShow,
        handleOnSelectGroomingPlanDuration,
        onPlanSelect
    }
) => {
   // used to get the selected duration plan of grooming 
    const getSelectedDurationPlan = (durationPlans) => {
        const selectedDurationPlan = durationPlans?.find(item => item.isSelected);
        if (selectedDurationPlan === undefined) {
            return durationPlans[0]
        } else return selectedDurationPlan;
    }

    return (
        <div className="card-box">
            <div className="image-section">
                <img src={`${config.GROOMING_PLAN_IMG_URL}/${item.image}`} className='img-fluid' alt='' />
            </div>
            <div className="card-body">
                <div className="title-section">
                    <h3>{item.title}</h3>
                </div>
                <div className="price-section-wrapper">
                    <div className="price-section">
                        <h4>
                            ₹ 
                            {
                                getSelectedDurationPlan(item.durations).salePrice
                            }
                        </h4>
                        <div className="select-price">
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic"> for {getSelectedDurationPlan(item.durations).durationTitle} </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {
                                        item.durations.map((itm, i) => {
                                            return (
                                                <Dropdown.Item
                                                    onClick={(e) => { e.preventDefault(); handleOnSelectGroomingPlanDuration(item, itm) }}
                                                    key={`durations_${i}`}>
                                                    <div className="plan-select-wrapper">
                                                        <div className="price">
                                                            <h5>₹ {itm.salePrice}
                                                                {
                                                                    itm.hasDiscount &&
                                                                    <span className="original-price"> ₹({itm.price})</span>
                                                                }
                                                                <span> for {itm.durationTitle} </span></h5>
                                                        </div>
                                                        {
                                                            itm?.hasDiscount && itm?.discountType === "PERCENTAGE" &&
                                                            <div className="plan-price-discount">
                                                                <h5>{itm?.discount}% </h5>
                                                                <p>off</p>
                                                            </div>
                                                        }
                                                    </div>
                                                </Dropdown.Item>
                                            )
                                        }
                                        )
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    {
                        getSelectedDurationPlan(item?.durations).hasDiscount && getSelectedDurationPlan(item?.durations).discountType === "PERCENTAGE" &&
                        <div className="discount"> {getSelectedDurationPlan(item?.durations).discount}% <span>off</span></div>
                    }
                </div>
                {
                    getSelectedDurationPlan(item?.durations).hasDiscount &&
                    <p className="price-section-if-discount">
                        ₹
                        {
                            getSelectedDurationPlan(item?.durations).price
                        }
                    </p>
                }
            </div>
            <div className="plan-list">
                <ul>
                    {
                        item.servicesIncluded.map((itm, i) => {
                            return (
                                <li
                                    key={`servicesIncluded_${i}`}>
                                    <span>{itm.serviceTitle}</span>
                                </li>
                            )
                        }
                        )
                    }
                </ul>
            </div>
            {
                item.servicesIncluded.length > 3 &&
                <div className="see-more-btn"><span onClick={() => packageShow(item)}>See More</span></div>
            }
            <div className="btn_link" onClick={() => onPlanSelect(item)}><span>Book A Groomer</span></div>
        </div>
    )
}
export default PackageItem