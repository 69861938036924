import React from 'react';
import {
 
    FacebookShareButton,
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
    TelegramShareButton,
    WhatsappShareButton,
    FacebookIcon,
    TelegramIcon,
    WhatsappIcon,
    
} from "react-share";
import { showToast } from '../../helpers/api/utils';
import { CopyToClipboard } from 'react-copy-to-clipboard';



const ShareIt = ({ share, shareUrl, title }) => {
    
    const handleCopy = () =>{
        showToast('success','Link has been copied')
	}
   
     return (
        <>
            <div className='social_share' style={{ display: share ? 'block' : 'none' }}>
                <div className="Demo__container">
                    <div className="social-network">
                        <div className='copy-btn-custom  social-network__share-button'>
                            <CopyToClipboard text={shareUrl}
                                onCopy={ handleCopy}>
                                <span><i className="fa fa-copy "></i></span>
                            </CopyToClipboard>
                        </div>
                    </div>
                    <div className="social-network">
                        <WhatsappShareButton
                            url={shareUrl}
                            title={title}
                            separator=":: "
                            className="social-network__share-button"
                        >
                            <WhatsappIcon size={32} round />
                        </WhatsappShareButton>
                    </div>
                    <div className="social-network">
                        <FacebookMessengerShareButton url={shareUrl} appId={process.env.REACT_APP_FACEBOOK_APP_ID} className="social-network__share-button">
                            <FacebookMessengerIcon size={32} round />
                        </FacebookMessengerShareButton>
                    </div>
                    <div className="social-network">
                        <TelegramShareButton url={shareUrl} className="social-network__share-button">
                            <TelegramIcon size={32} round />
                        </TelegramShareButton>
                    </div>
                    <div className="social-network">
                        <FacebookShareButton
                            url={shareUrl}
                            quote={title}
                            className="social-network__share-button"
                        >
                            <FacebookIcon size={32} round />
                        </FacebookShareButton>
                    </div>
                    {/* <div className="social-network">
                        <TwitterShareButton
                            url={shareUrl}
                            title={title}
                            className="social-network__share-button"
                        >
                            <TwitterIcon size={32} round />
                        </TwitterShareButton>

                    </div> */}
                   


                    {/* <div className="social-network">
                    <InstapaperShareButton
                        url={shareUrl}
                        title={title}
                        className="social-network__share-button"
                    >
                        <InstapaperIcon size={32} round />
                    </InstapaperShareButton>
                </div> */}
                  
                    {/* <div className="social-network">
                    <LinkedinShareButton url={shareUrl} className="social-network__share-button">
                        <LinkedinIcon size={32} round />
                    </LinkedinShareButton>
                </div> */}
                   
                </div>
            </div>
        </>
    )

}
export default ShareIt;