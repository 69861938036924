/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Nav, Row, Tab , Pagination} from "react-bootstrap";
import EcommerceOrders from "./EcommerceOrders";
import MealPlanOrders from "./MealPlanOrders";
import { showToast } from "../../../helpers/api/utils";
import toast from "react-hot-toast";
import { customerReview, orderList } from "../../../helpers";
const Footer = React.lazy(() => import("../../comman/footer"));
const Header = React.lazy(() => import("../../comman/header"));



const Orders = () => {
  const [activeTab, setactiveTab] = useState("product");
  const [page, setPage] = useState(1);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState();

  const handleSelect = (eventKey) => {
    setactiveTab(eventKey);
  };

  useEffect(() => {
    if (1 >= page || totalPages >= page) {
      fetchData();
    }
  }, [page, activeTab]);

  //handleShowNextPageOfOrders

  // handleShowNextPageOfOrders
  const handleNextBtn = async () => {
    try {
      if (page < totalPages) {
        setPage(page + 1);
      } 
      // else {
      //   showToast("info", "No more pages available");
      // }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const handleOnPrevious = () => {
    try {
      if (page > 1) {
        setPage(page - 1);
      } 
      // else {
      //   showToast("info", "Already on the first page");
      // }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  //used to get the order list

  const fetchData = async () => {
    try {
      setLoading(true);
      const { data } = await orderList({ page: page, orderType: activeTab,  sortBy: 'createdAt:desc',limit:10 });
      setTotalPages(data.totalPages);
      data?.results.forEach((order, i) => {
        order?.orderInfo.forEach((item, k) => {
          const hasReviewed = data?.reviews?.find(
            (review) =>
              review?.orderId === order.id && review?.itemId === item.id
          );
          item["hasReviewed"] = hasReviewed === undefined ? false : true;
          item["comment"] =
            hasReviewed !== undefined ? hasReviewed?.comment : "";
          item["stars"] = hasReviewed !== undefined ? hasReviewed?.stars : 0;
        });
      });
      setOrders(data?.results);
      setLoading(false);
    } catch (error) {
      showToast("error", error?.error);
      setLoading(false);
    }
  };
  

  return (
    <>
      <Header />
      <div className="order-history-wrapper">
        <Container>
          <Row>
            <Col md={12}>
              <Nav
                as="ul"
                variant="pills"
                className="navtab-bg"
                activeKey={activeTab}
                onSelect={handleSelect}
              >
                <Nav.Item as="li">
                  <Nav.Link eventKey="product">Ecommerce Orders</Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link eventKey="customizeMeal">Meal Plan Orders</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane
                  active={activeTab === "product"}
                  eventKey="EcommerceOrders"
                >
                  <EcommerceOrders orders={orders} loading={loading} />
                </Tab.Pane>
                <Tab.Pane
                  active={activeTab === "customizeMeal"}
                  eventKey="MealPlanOrders"
                >
                  <MealPlanOrders orders={orders} loading={loading} />
                </Tab.Pane>
                </Tab.Content>
              
                <div className="ordersNavigateBtn">
                 
               <Pagination className="ordersNavigateBtn">
                  <Pagination.Prev
                    onClick={handleOnPrevious}
                    disabled={page === 1}
                  />
                  <Pagination.Item active>
                    {page}
                  </Pagination.Item>
                  <Pagination.Next
                    onClick={handleNextBtn}
                    disabled={totalPages===0 || totalPages === page}
                  />
            
                </Pagination>
                </div>
          
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};
export default Orders;
