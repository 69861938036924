import { APICore } from "./apiCore";
const api = new APICore();

function orderList(params) {
  const user = api.getLoggedInUser(params);
  params = { ...params, user: user.id };
  const baseUrl = `/order`;
  return api.get(`${baseUrl}`, params);
}
function createOrder(params) {
  const baseUrl = `/order`;
  return api.create(`${baseUrl}`, params);
}
function verifyPayment(params) {
  const baseUrl = `/order/verify-payment`;
  return api.create(`${baseUrl}`, params);
}
function cancelPayment(params) {
  const baseUrl = `/order/cancel-payment`;
  return api.create(`${baseUrl}`, params);
}


export { orderList, createOrder, verifyPayment, cancelPayment };
