import { APICore } from "./apiCore";
const api = new APICore();

// category
function wishlist(params) {
  const baseUrl = `/wishlist`;

  return api.get(`${baseUrl}`, {});
}

function addWishlist(params) {
  const baseUrl = `/wishlist`;
  return api.create(`${baseUrl}`, params);
}

function deleteWishlist(id) {
  const baseUrl = `/wishlist/${id}`;
  return api.delete(`${baseUrl}`);
}

export { wishlist, addWishlist, deleteWishlist };
