// apicore
import { APICore } from "../../helpers/api/apiCore";

// constants
import { AuthActionTypes } from "./constants";

const api = new APICore();

const INIT_STATE = {
  user: api.getLoggedInUser(),
  petProfiles: api.getLoggedInUser()?.allPetProfiles,
  tempToken: api.getLoggedInUser()?.id,
  loading: false,
  message: {},
  actionPerformed: false,
  userLoggedIn: false,
  validationError: [],
};

const Auth = (state = INIT_STATE, action) => {
  switch (action.type) {
    case AuthActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case AuthActionTypes.LOGIN_USER: {
          const data = {
            ...state,
            tempToken: action.payload.data.tempToken,
            userLoggedIn: false,
            message: { type: "success", message: action.payload.data.message },
            actionPerformed: true,
            loading: false,
            validationError: [],
          };
          return data;
        }
        case AuthActionTypes.LOGIN_AT_CHECKOUT: {
          const data = {
            ...state,
            tempToken: action.payload.data.tempToken,
            userLoggedIn: false,
            message: { type: "success", message: action.payload.data.message },
            actionPerformed: false,
            loading: false,
            validationError: [],
          };
          return data;
        }
        
        case AuthActionTypes.RESEND_OTP: {
          const data = {
            ...state,
            userLoggedIn: false,
            message: { type: "success", message: action.payload.data.message },
            actionPerformed: true,
            loading: true,
            validationError: [],
          };
          return data;
        }
        case AuthActionTypes.VALIDATE_USER: {
          return {
            ...state,
            user: action.payload.data,
            userLoggedIn: true,
            tempToken: null,
            message: { type: "success", message: action.payload.data.message },
            actionPerformed: true,
            loading: false,
            validationError: [],
            // ShowUpdatedMessage:false
          };
        }
        case AuthActionTypes.GUEST_LOGIN: {
          return {
            ...state,
            user: action.payload.data,
            userLoggedIn: true,
            tempToken: null,
            message: { type: "success", message: action.payload.data.message },
            actionPerformed: true,
            loading: false,
            validationError: [],
          };
        }
        case AuthActionTypes.UPDATE_USER: {
          return {
            ...state,
            user: action.payload.data,
            userLoggedIn: true,
            actionPerformed: true,
            loading: true,
            message: { type: "success", message: action.payload.data.message },
            validationError: [],
          };
        }
        case AuthActionTypes.SIGNUP_USER: {
          return {
            ...state,
            loading: false,
            userSignUp: true,
          };
        }
        case AuthActionTypes.LOGOUT_USER: {
          return {
            ...state,
            user: null,
            status: "INACTIVE",
            userLogout: true,
            tempToken: "",
            userLoggedIn: false,
            loading: true,
            message: { type: "success", message: action.payload.data.message },
            actionPerformed: true,
          };
        }
        case AuthActionTypes.FORGOT_PASSWORD: {
          return {
            ...state,
            resetPasswordSuccess: action.payload.data,
            loading: false,
            passwordReset: true,
            actionPerformed: true,
            message: "",
          };
        }
        case AuthActionTypes.CLEAR_OTP_TOKEN: {
          return {
            ...state,
            tempToken: null,
            actionPerformed: false,
            loading: false,
            message: {},
            validationError: [],
          };
        }
        case AuthActionTypes.CLEAR_ACTION: {
          return {
            ...state,
            actionPerformed: false,
            loading: false,
            userLogout: false,
            message: {},
            validationError: [],
            error: false,
          };
        }
        case AuthActionTypes.IS_LOADING: {
          return {
            ...state,
            loading: true,
          };
        }
        case AuthActionTypes.REMOVE_REFERRAL_URL: {
          return {
            ...state,
            removeReferralUrl: true,
          };
        }
        default:
          return { ...state };
      }

    case AuthActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case AuthActionTypes.LOGIN_USER: {
          return {
            ...state,
            error: action.payload.error,
            userLoggedIn: false,
            loading: false,
          };
        }
        case AuthActionTypes.RESEND_OTP: {
          return {
            ...state,
            error: action.payload.error,
            userLoggedIn: false,
            loading: false,
          };
        }
        case AuthActionTypes.SIGNUP_USER: {
          return {
            ...state,
            registerError: action.payload.error,
            userSignUp: false,
            loading: false,
          };
        }
        case AuthActionTypes.VALIDATE_USER: {
          
          return {
            ...state,
            error: action.payload.error.error,
            loading: false,
            passwordReset: false,
            message: {},
            actionPerformed: false,
            validationError: action.payload?.error,
            hasErrors: true,
            submitted: true,
            // ShowUpdatedProfile:false
          };
        }
        case AuthActionTypes.GUEST_LOGIN: {
    
          return {
            ...state,
            error: action.payload.error.error,
            loading: false,
            passwordReset: false,
            message:  action.payload.error.error,
            actionPerformed: false,
            validationError: action.payload?.error,
            hasErrors: true,
            submitted: true,
            // ShowUpdatedProfile:false
          };
        }

        case AuthActionTypes.UPDATE_USER: {
          return {
            ...state,
            error: action.payload.error.error,
            loading: false,
            passwordReset: false,
            validationError: [],
            hasErrors: true,
            submitted: true,
          };
        }
        case AuthActionTypes.FORGOT_PASSWORD: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
            passwordReset: false,
          };
        }
        default:
          return { ...state };
      }

    // case AuthActionTypes.LOGIN_USER:
    //   return { ...state, loading: true, userLoggedIn: false };
    case AuthActionTypes.LOGOUT_USER:
      return { ...state, loading: true, userLogout: false };
    case AuthActionTypes.RESET:
      return {
        ...state,
        loading: false,
        error: false,
        userSignUp: false,
        userLoggedIn: false,
        passwordReset: false,
        passwordChange: false,
        resetPasswordSuccess: null,
        message: {},
        actionPerformed: false,
      };
    default:
      return { ...state };
  }
};

export default Auth;
