import { Modal } from "react-bootstrap";
import Images from "../../helpers/images";
import { useState } from "react";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import DiscountModel from "../../helpers/discountModel.json";

const ThanksDiscountModel = ({showModel,setShowModel}) => {


  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: DiscountModel,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModel}
      >
        <Modal.Body>
          <button
            onClick={() => setShowModel(false)}
            type="button"
            class="btn-close discountModel"
            aria-label="Close"
          ></button>
          <div className="displayDiscountModel">
            <div className="discountModelText">
              <div className="dicountHeading">
                <h1  className="thanks-discount-heading" >
                  Thank You !
                </h1>
              </div>
              <div className="lottie-style">
                <Lottie style={{position:"absolute"}}  options={defaultOptions} height={"50%"} width={"40%"} />
              </div>
             <div className="thanks-model-bottom-content">
              <div className="discountModelBodyText ">
                <div className="lastPage">
                  Your birthday discounts <br />
                  are on the way!
                </div>
              </div>
              <div className=" customers-review-section discountModelButtons">
                <div className="review-btn discountBtn ">
                  <Link to={"/products"}> Shop Now</Link>
                </div>
              </div>
              </div>
            </div>

            <div>
              <img className="thanks-img-inner "
                src={Images.thirdDiscountModelImg}
                alt="pet image"
                height={348}
                width={348}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ThanksDiscountModel;
