import { APICore } from './apiCore';
const api = new APICore();

// category
function addressList(params) {
    const baseUrl = `/address`;
    return api.get(`${baseUrl}`, params);
}

function addAddress(params) {
    const baseUrl = `/address`;
    return api.create(`${baseUrl}`, params);

}
function updateAddress(params, id) {
    const baseUrl = `/address/${id}`;
    return api.updatePatch(`${baseUrl}`, params);
}
function deleteAddress(id) {
    const baseUrl = `/address/${id}`;
    return api.delete(`${baseUrl}`);

}



export {
    addressList,
    addAddress,
    updateAddress,
    deleteAddress
};
