import { Col, Form, Row } from "react-bootstrap";
import { hasError } from "../../../helpers/api/utils";
import Select from "react-select";
import petHealthConditionsJson from "../../../helpers/petHealthConditions.json";

const PetDetails = ({ formData, validationErrors, handleOnChange }) => {
  //Define all the pet-allergies here
  const options = [
    { value: "Food allergies", label: "Food allergies" },
    { value: "Environmental allergies", label: "Environmental allergies" },
    { value: "Flea allergies", label: "Flea allergies" },
    { value: "Contact allergies", label: "Contact allergies" },
    { value: "Medication allergies", label: "Medication allergies" },
    { value: "Other", label: "Other" }

  ];

  const selectedItems = (items) => {
    return items?.map((item) => ({ label: item, value: item }));
  };

  //used to check has any specific allergy
  const checkHasSpecifyAllergies = () => {
    const hasOther = formData?.allergies?.find((item) => item === "Other");
    if (hasOther === undefined) {
      return false;
    }
    return true;
  };

  //used to check has any health problem
  const checkHasotherHealthProblem = () => {
    const hasOther = formData?.healthComplaints?.find(
      (item) => item === "Other"
    );
    if (hasOther === undefined) {
      return false;
    }
    return true;
  };
  const customStyles = {
  
    placeholder: (provided) => ({
      ...provided,
      color: '#D1D1D1', 
    }),
  };


  return (
    <div className="step-form three" id="thirdStep">
      <div className="step-form-inner">
        <Row>
          <h1>Thanks! Now give us all the details about your pet.</h1>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <Form.Label>Eating style of your pet*</Form.Label>
            <div className="spayed-or-neutered">
              <label className="rounded-0 text-white">
                <input
                  type="radio"
                  name="eatingHabit" 
                  value={"Very picky"}
                  onChange={(e) => {
                    handleOnChange(e.target.name, e.target.value);
                  }}
                  checked={
                    formData?.eatingHabit === "Very picky" ? "checked" : ""
                  }
                  className="d-none"
                />
                <span className="text-center">Very picky</span>
              </label>
              <label className="rounded-0 text-white">
                <input
                  type="radio"
                  name="eatingHabit"
                  value={"Can be picky"}
                  onChange={(e) => {
                    handleOnChange(e.target.name, e.target.value);
                  }}
                  checked={
                    formData?.eatingHabit === "Can be picky" ? "checked" : ""
                  }
                  className="d-none"
                />
                <span className="text-center">Can be picky</span>
              </label>
              <label className="rounded-0 text-white">
                <input
                  type="radio"
                  name="eatingHabit"
                  value={"Good eater"}
                  onChange={(e) => {
                    handleOnChange(e.target.name, e.target.value);
                  }}
                  checked={
                    formData?.eatingHabit === "Good eater" ? "checked" : ""
                  }
                  className="d-none"
                />
                <span className="text-center">Good eater</span>
              </label>
              <label className="rounded-0 text-white">
                <input
                  type="radio"
                  name="eatingHabit"
                  value={"Will eat anything"}
                  onChange={(e) => {
                    handleOnChange(e.target.name, e.target.value);
                  }}
                  checked={
                    formData?.eatingHabit === "Will eat anything"
                      ? "checked"
                      : ""
                  }
                  className="d-none"
                />
                <span className="text-center">Will eat anything</span>
              </label>
            </div>

            {hasError(validationErrors, "eatingHabit")}
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}   className="health-comlatints">
            <Form.Label >Health complaints</Form.Label>
            <Select
              className="health-comlatints"
              name="healthComplaints"
              options={petHealthConditionsJson}
              isMulti={true}
              styles={customStyles}
              value={
                formData?.healthComplaints
                  ? selectedItems(formData?.healthComplaints)
                  : []
              }
              onChange={(values) => {
                const updatedValues = values?.map((item) => item.value);
                handleOnChange("healthComplaints", updatedValues);
              }}
            >
            </Select>
          
            <Row>
              <div className="pet-breed-select">
                {checkHasotherHealthProblem() && (
                  <Form.Control
                    value={formData?.otherHealthProblem}
                    onChange={(e) => {
                      handleOnChange(e.target.name, e.target.value);
                    }}
                    className="pet-number"
                    name="otherHealthProblem"
                    type="text"
                    placeholder="Enter Other Health Conditions"
                  />
                )}
              </div>
            </Row>
            {/* {hasError(validationErrors, 'healthComplaints')} */}
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} className="health-Allergies">
            <Form.Label>Allergies</Form.Label>
            <Select
          
              name="allergies"
              options={options}
              isMulti={true}
              styles={customStyles}
              value={
                formData?.allergies ? selectedItems(formData?.allergies) : []
              }
              onChange={(values) => {
                if (!(values?.label === "None")) {
                  const updatedValues = values?.map((item) => item.value);
                  handleOnChange("allergies", updatedValues);
                } else {
                  handleOnChange("allergies", values?.label);
                }
              }}
            />

        
            <Row>
              <div className="pet-allergies-select">
                {checkHasSpecifyAllergies() && (
                  <Form.Control
                    value={formData?.specifyAllergies}
                    onChange={(e) => {
                      handleOnChange("specifyAllergies", e.target.value);
                    }}
                    className="pet-number"
                    name="specifyAllergies"
                    type="text"
                    placeholder="Enter Other Allergies"
                  />
                )}
              </div>
            </Row>
            {/* {hasError(validationErrors, 'allergies')} */}
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}  className="consult-vet-other">
            <Form.Label>Other</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              type="text"
              name="other"
              value={formData?.other || ""}
              onChange={(e) => {
                handleOnChange(e.target.name, e.target.value);
              }}
              placeholder="Other"
            />
            {hasError(validationErrors, "other")}
          </Form.Group>
        </Row>
      </div>
    </div>
  );
};

export default PetDetails;
