export const CartActionTypes  = {
    API_RESPONSE_SUCCESS    : '@@cart/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR      : '@@cart/API_RESPONSE_ERROR',
    ITEM_LIST               : '@@cart/ITEM_LIST',
    ADD_ITEM                : '@@cart/ADD_ITEM',
    SHOW_ITEM               : '@@cart/SHOW_ITEM',
    UPDATE_ITEM             : '@@cart/UPDATE_ITEM',
    DELETE_ITEM             : '@@cart/DELETE_ITEM',
    TOOGLE_SHOW_CART        : '@@cart/TOOGLE_SHOW_CART',
    EMPTY_CART              : '@@cart/EMPTY_CART',
};
