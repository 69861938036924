/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../../helpers/api/utils";
import { resetAction } from "../../../redux/actions";
import PetUserDetails from "./PetUserDetails";


const PetGender = React.lazy(() => import('./petInfo/PetGender'));
const PetBasicInfo = React.lazy(() => import('./petInfo/PetBasicInfo'));
// const PetUserDetails = React.lazy(() => import('./PetUserDetails'));
const PetBreed = React.lazy(() => import('./petInfo/PetBreed'));
const PetBirthday = React.lazy(() => import('./petInfo/PetBirthday'));
const PetBodyType = React.lazy(() => import('./petInfo/PetBodyType'));
const PetWeight = React.lazy(() => import('./petInfo/PetWeight'));
const PetLifestyle = React.lazy(() => import('./petInfo/PetLifestyle'));
const PetHealthConditions = React.lazy(() => import('./petInfo/PetHealthConditions'));
const PetAllergies = React.lazy(() => import('./petInfo/PetAllergies'));
const PetProfileStatusBar = React.lazy(() => import('./PetProfileStatusBar'));
const PetRecipesPlan = React.lazy(() => import('./petRecipes/PetRecipesPlan'));
const CheckoutPage = React.lazy(() => import('../checkout/CheckoutPage'));
const GeneratingPlan = React.lazy(() => import('./petRecipes/GeneratingPlan'));

const PetProfile = () => {
  const { currentProfileStep, actionPerformed, message } = useSelector((state) => ({
    currentProfileStep: state.PetProfile.currentProfileStep,
    actionPerformed: state.PetProfile.actionPerformed,
    message: state.PetProfile.message,
  }));

console.log(currentProfileStep);

  const dispatch = useDispatch();
  useEffect(() => {
      if (message?.message !== undefined){
        // showToast(message?.type, message?.message);
         dispatch(resetAction());
       }
  }, [actionPerformed]);


console.log(currentProfileStep);

  return (
    <>
      {/* <Suspense fallback={<PageLoader loading={true}></PageLoader>}> */}
      <PetProfileStatusBar />
      {currentProfileStep === 'PetUserDetails' && (
        <PetUserDetails />
      )}
      {currentProfileStep === 'PetBasicInfo' && (
        <PetBasicInfo />
      )}
      {currentProfileStep === 'PetGender' && (
        <PetGender />
      )}
      {currentProfileStep === 'PetBreed' && (
        <PetBreed />
      )}
      {currentProfileStep === 'PetBirthday' && (
        <PetBirthday />
      )}
      {currentProfileStep === 'PetBodyType' && (
        <PetBodyType />
      )}
      {currentProfileStep === 'PetWeight' && (
        <PetWeight />
      )}
      {currentProfileStep === 'PetLifestyle' && (
        <PetLifestyle />
      )}
      {currentProfileStep === 'PetHealthConditions' && (
        <PetHealthConditions />
      )}
      {currentProfileStep === 'PetAllergies' && (
        <PetAllergies />
      )}
      {currentProfileStep === 'GeneratingPlan' && (
        <GeneratingPlan />
      )}
      {currentProfileStep === 'PetRecipesPlan' && (
        <PetRecipesPlan />
      )}

      {currentProfileStep === 'Checkout' && (
        <CheckoutPage checkoutType={'petProfile'} />
      )}

      {/* </Suspense> */}
    </>
  )
}
export default PetProfile