import { APICore } from "./apiCore";
const api = new APICore();

// getAllproduct
function getAllProducts(params) {
  const baseUrl = `product/all-products`;
  return api.get(`${baseUrl}`,params);
}

export { getAllProducts };
