import { Link } from "react-router-dom"
import Images from "../../../helpers/images"
import { useDispatch, useSelector } from "react-redux";
import { addPetProfiles, clearAction, deletePetProfile, loginAsGuestUser, logoutUser, resetAction, resetAuth, updateStep } from "../../../redux/actions";
import { useEffect, useState } from "react";
import config from "../../../config";
import { APICore } from "../../../helpers/api/apiCore";
import Swal from "sweetalert2";
import { showToast } from "../../../helpers/api/utils";


const Profile = () => {

    const api = new APICore


    const dispatch = useDispatch();
    

    const { petProfiles ,petProfileUpdated ,message} = useSelector((state) => ({
        petProfileUpdated: state.PetProfile.actionPerformed,
        petProfiles: state.PetProfile.petProfiles,
        message: state.PetProfile.message,
    }));

    useEffect(() => {
        if(petProfileUpdated && message){
            showToast(message?.type, message?.message);
        }
    }, [petProfileUpdated])

    const logout = () => {
        dispatch(logoutUser());
        api.getLoggedInUser();
        dispatch(loginAsGuestUser());
        dispatch(clearAction());


    };
    const removePetProfile = (id) => {
       
        Swal.fire({
            title: "Are you sure you want to delete this pet profile?  Please note that this action will permanently remove all associated data from our system",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Remove",
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                dispatch(deletePetProfile(id));
                // Swal.fire("Saved!", "", "success");
            } else if (result.isDenied) {
                Swal.fire("Changes are not saved", "", "info");
            }
        });
    }


    return (
        <div className="profile-section">
            <div className="dropdown">
                <div className="profile-photo">
                    <div className="profile">
                        <img src={Images.catCoco} className='img-fluid' alt='' />
                    </div>
                    <div className="arrow">
                        <img src={Images.downFill} className='img-fluid' alt='' />
                    </div>
                </div>
                <div className="dropdown-content">
                    {petProfiles?.length > 0 && (
                        <div className={`profile-container ${petProfiles.length >= 6 ? 'scrollable' : ''}`}>
                            {petProfiles.map((item) => (
                                <div key={`pet-profile-bar-${item?.id}`} className="profile-section">
                                    <div key={`pet-profile-bar-${item?.id}`} className="profile-section">
                                        <div className="profile">
                                            <img src={item?.image ? (config.PET_IMG_URL + '/' + item?.image) : (item?.petType === 'Dog' ? Images.dogProfile : Images.catProfile)} className='img-fluid' alt='' />
                                        </div>
                                        <div className="name-section">
                                            <div className="name">
                                                <h4>{item?.name} <img src={Images.deleteIcon} onClick={() => { removePetProfile(item.id) }} className='img-fluid' style={{ width: "25px", paddingLeft: '10px', cursor: 'pointer' }} alt='' /> </h4>
                                                <Link to={`/edit-profile/${item?.id}`}> <span> Edit Pet Profile</span>
                                                    <div className="icon"><img src={Images.edit} className='img-fluid' alt='' /> </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    {/* {petProfiles?.length > 0 && petProfiles?.map((item) => (
                      
                    ))} */}
                    <div className="profile-action">
                        <ul>
                            <li> <Link onClick={() => petProfiles?.length > 0 && dispatch(updateStep('PetBasicInfo'))} className="profile-item-list" to='/pet-profile'><div className="icon"><img src={Images.petProfile} className='img-fluid' alt='' /> </div> <span>Add Pet Profile </span> </Link></li>
                            <li><Link className="profile-item-list" to='/address'><div className="icon"> &nbsp;<img src={Images.location} className='img-fluid' alt='' /> </div> <span>Manage Addresses </span> </Link></li>
                            <li><Link className="profile-item-list" to='/orders'><div className="icon"> &nbsp;<img src={Images.orderHistory} className='img-fluid' alt='' /> </div> <span>Order History </span> </Link></li>
                            <li> <Link className="profile-item-list" to='/referral-history'><div className="icon">&nbsp;<img src={Images.referral} className='img-fluid' alt='' /> </div> <span>Referral History </span> </Link></li>
                            <li> <div onClick={logout} className="profile-item-list"> <div className="icon ">&nbsp;<img src={Images.logoutFill} className='img-fluid' alt='' /> </div> <span>Sign Out </span></div>  </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Profile
