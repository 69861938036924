import { combineReducers } from 'redux';

import Auth from './auth/reducers';
import PetProfile from './petProfile/reducers';
import Cart from './cart/reducers';
import Product from './product/reducers';


export default combineReducers({
    Auth,
    PetProfile,
    Cart,Product
});
