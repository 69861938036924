import { APICore } from './apiCore';
const api = new APICore();

// account
function login(params) {
    const baseUrl = `/auth/login`;
    return api.create(`${baseUrl}`, params);

}
function resendOtp(params) {
    const baseUrl = `/auth/resendOtp`;
    return api.create(`${baseUrl}`, params);

}

function validateOtp(params) {
    const baseUrl = `/auth/validate-otp`;
    return api.create(`${baseUrl}`, params);
}
function guestLogin(params) {
    const baseUrl = `/auth/guest-checkout`;
    return api.create(`${baseUrl}`, params);
}

function loginAsGuestUser(){
    const baseUrl = `/auth/guestLogin`;
    return api.get(`${baseUrl}`,);
}

function updateUser(params) {
    const baseUrl = `/auth/update-user-profile`;
    return api.create(`${baseUrl}`, params);
}

function logout() {
    const { refreshToken } = api.getLoggedInUser();
    const baseUrl = '/auth/logout';
    return api.create(`${baseUrl}`, {refreshToken});
}

function signup(params) {
    const baseUrl = '/register/';
    return api.create(`${baseUrl}`, params);
}

function forgotPassword(params) {
    const baseUrl = '/forget-password';
    return api.create(`${baseUrl}`, params);
}
function checkEmailValidation(params) {
    const baseUrl = '/auth/checkEmailValidation';
    return api.create(`${baseUrl}`, params);
}

function getDelhiveryCharge(params) {
    const baseUrl = '/auth/get-delivery-charge';
    return api.create(`${baseUrl}`, params);
}




export {checkEmailValidation, login, logout, signup, forgotPassword ,validateOtp ,updateUser ,guestLogin,resendOtp,loginAsGuestUser ,getDelhiveryCharge};
