export const PetProfileActionTypes = {
  API_RESPONSE_SUCCESS: "@@petProfile/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR: "@@petProfile/API_RESPONSE_ERROR",
  PET_PROFILE_LIST: "@@petProfile/PET_PROFILE_LIST",
  ADD_PET_PROFILE: "@@petProfile/ADD_PET_PROFILE",
  SHOW_PET_PROFILE: "@@petProfile/SHOW_PET_PROFILE",
  UPDATE_PET_PROFILE: "@@petProfile/UPDATE_PET_PROFILE",
  DELETE_PET_PROFILE: "@@petProfile/DELETE_PET_PROFILE",
  UPDATE_PROFILE_STEP: "@@petProfile/UPDATE_PROFILE_STEP",
  ADD_MEAL_PLAN_PET_PROFILE: "@@petProfile/ADD_MEAL_PLAN_PET_PROFILE",
  SET_UP_PET_PROFILE: "@@petProfile/SET_UP_PET_PROFILE",
  RESET_ACTION: "@@petProfile/RESET_ACTION",
  IS_LOADING_PET_PROFILE: "@@petProfile/IS_LOADING_PET_PROFILE",
  UPDATE_STEP: "@@petProfile/UPDATE_STEP",
  UPDATE_CURRENT_PROFILE: "@@petProfile/UPDATE_CURRENT_PROFILE",
  UPDATE_ALL_PET_PROFILE: "@@petProfile/UPDATE_ALL_PET_PROFILE",
  PROCEED_TO_CHECKOUT: "@@petProfile/PROCEED_TO_CHECKOUT",
  
};
