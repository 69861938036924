import { APICore } from './apiCore';
const api = new APICore();


function applyCoupon(params) {
    const baseUrl = `/coupon/apply-coupon`;
    return api.create(`${baseUrl}`, params);
}


export {
    applyCoupon,
};
