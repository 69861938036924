import { APICore } from "./apiCore";
const api = new APICore();

function shippingPriceCalculator(params) {
   //  ?
  // const baseUrl = `/order`;
  return api.get(`https://serviceability.shiprocket.in/open/courier/serviceability`, params);
}


export { shippingPriceCalculator };
