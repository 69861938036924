import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HomeMenu } from "../../helpers";
import { showToast } from "../../helpers/api/utils";
import { HashLink } from "react-router-hash-link";

const NavBar = () => {

    const [click, setClick] = useState(false);
    const [data, setData] = useState();
   
    const handleClick = () => {
        setClick(!click);
        document.body.classList.toggle('body-custom');

    };
    const closeMobileMenu = () => {
        setClick(false);
        document.body.classList.toggle('body-custom');
    };
  


    useEffect(() => {
        async function getData() {
            try {
                const { data } = await HomeMenu();
                setData(data?.result);
            }
            catch (error) {
                console.log(error)
                showToast(error?.error)
            }

        }
        getData();
    }, []);

 

    return (
        <div className="nav-section">   
            <nav>
                <ul className={click ? "nav-options active" : "nav-options pb-2 m-0"}>
                <li className="dropdown" onClick={closeMobileMenu}> <Link to='/'>Home</Link></li>
                    {data?.map((item) => (
                        <li key={`dog-cat${item.petType}`} className="dropdown"> <span> {item?.petType} <i className="icon-down"></i></span>
                            <ul className="dropdown_menu dropdown_menu--animated">
                                {item?.items?.map((categories,i) => (
                                <li key={`categories-${categories?.id}`} className="dropdown_item-1" onClick={closeMobileMenu}> <Link to={`/products?category=${categories?.id}`} > {categories?.name}</Link>
                                 {categories?.subCategories?.map((subCategory ,index)=>(
                                     <ul  key={`subCategories-${subCategory}`}>
                                     <li className="dropdown_item-1"> <Link to={`/products?sub-category=${item?.items[i].subCategoryIds[index]}`} > - {subCategory} </Link></li>
                                 </ul>
                                 ))}
                                   
                                </li>))}
                                <li className="dropdown_item-2" onClick={closeMobileMenu}><HashLink to={`/grooming-service#${item?.petType}`}>Grooming</HashLink></li>
                            </ul>
                        </li>
                    ))}

                    <li className="dropdown" onClick={closeMobileMenu}> <Link to='/grooming-service'>Grooming Studio </Link></li>
                    <li className="dropdown" onClick={closeMobileMenu}> <Link to='/consult-nutritionist'>Consult a Nutritionist </Link></li>
                    <li className="dropdown" onClick={closeMobileMenu}> <Link to='/pet-profile'>Build your Profile </Link></li>
                    <li className="dropdown" onClick={closeMobileMenu}> <Link to='/about-us'>About Us </Link></li>
                </ul>
                <div className="mobile-menu" onClick={handleClick}>
                    {click ? (
                        <div className="menu-icon">
                            <i className="icon-close"></i>
                        </div>

                    ) : (
                        <div className="menu-icon">
                            <i className="icon-side_bar"></i>
                        </div>
                    )}
                </div>
            </nav>
        </div>
    )
}
export default NavBar
