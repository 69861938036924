import jwtDecode from 'jwt-decode';
import axios from 'axios';
import config from '../../config';
import { loginAsGuestUser } from '../../redux/actions';
import { useDispatch } from 'react-redux';


// content type
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.baseURL = config.apiPrefix;

// intercepting to capture errors
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        let message;
        let validationErrors = [];

        if (error && error.response && error.response.status === 404) {
            // window.location.href = '/not-found';
        } else if (error && error.response && error.response.status === 403) {
            window.location.href = '/access-denied';
        } else {
            switch (error?.response?.status) {
                case 401:
                    message = 'Please authenticate';
                    break;
                case 422:
                    message = 'Please provide all required data';
                    validationErrors = error.response.data.errors;
                    break;
                case 403:
                    message = 'Access Forbidden';
                    break;
                case 404:
                    message = 'Sorry! the data you are looking for could not be found';
                    break;
                default: {
                    message =
                        error?.response && error?.response.data ? error.response.data['message'] : error?.message || error;
                }
            }
            return Promise.reject({ error: message, status: error?.response?.status ? error?.response?.status : 500 ,validationErrors });
        }

    }
);

const AUTH_SESSION_KEY = 'furrfernds_user';

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
    if (token) axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    else delete axios.defaults.headers.common['Authorization'];
};
const getFirstUserFromCookie = () => {
        const user = localStorage.getItem('firstUser');
        return JSON.parse(user);

};

const getUserFromCookie = () => {
    const user = localStorage.getItem(AUTH_SESSION_KEY);
    localStorage.setItem('firstUser', user);
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : getFirstUserFromCookie();
};


class APICore {
   
    /**
     * Fetches data from given url
     */
    get = (url, params) => {

        let response;
        if (params) {
            Object.keys(params).forEach(key => {
                if (params[key] === '')
                    delete params[key];
            });
            var queryString = params
                ? Object.keys(params).map((key) => key + '=' + params[key]).join('&')
                : '';
            response = axios.get(`${url}?${queryString}`, params);
        } else {
            response = axios.get(`${url}`, params);
        }
        return response;
    };

    getById = (url) => {

        let response;

        response = axios.get(`${url}`);

        return response;
    };

    getFile = (url, params) => {
        let response;
        if (params) {
            var queryString = params
                ? Object.keys(params)
                    .map((key) => key + '=' + params[key])
                    .join('&')
                : '';
            response = axios.get(`${url}?${queryString}`, { responseType: 'blob' });
        } else {
            response = axios.get(`${url}`, { responseType: 'blob' });
        }
        return response;
    };

    getMultiple = (urls, params) => {
        const reqs = [];
        let queryString = '';
        if (params) {
            queryString = params
                ? Object.keys(params)
                    .map((key) => key + '=' + params[key])
                    .join('&')
                : '';
        }

        for (const url of urls) {
            reqs.push(axios.get(`${url}?${queryString}`));
        }
        return axios.all(reqs);
    };

    /**
     * post given data to url
     */
    create = (url, data) => {
        const request = axios.post(url, data);
        return request;
    };

    /**
     * Updates patch data
     */
    updatePatch = (url, data) => {
        return axios.patch(url, data);
    };

    /**
     * Updates data
     */
    update = (url, data) => {
        return axios.put(url, data);
    };

    /**
     * Deletes data
     */
    delete = (url) => {
        return axios.delete(url);
    };

    /**
     * post given data to url with file
     */
    createWithFile = (url, data) => {
        const formData = new FormData();
        for (const k in data) {
            if (k === 'images') {
                for (const image in data[k]) {
                    formData.append('images', data[k][image]);
                }
            } else
                formData.append(k, data[k]);
        }




        const config = {
            headers: {
                ...axios.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post(url, formData, config);
    };

    /**
     * post given data to url with file
     */
    updateWithFile = (url, data) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }
        const config = {
            headers: {
                ...axios.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axios.patch(url, formData, config);
    };

    /**
     * post given data to url with file
     */
    uploadFile = (url, data) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }
        const config = {
            headers: {
                ...axios.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post(url, formData, config);
    };
    /**
     * post given data to url with files
     */
    uploadMultipleFile = (url, formData) => {
        const config = {
            headers: {
                ...axios.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post(url, formData, config);
    };
    /**
     * post given data to url with file
     */
    importFile = (url, data) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }
        const config = {
            headers: {
                'Content-Type': 'application/vnd.ms-excel',
                'Authorization': axios.defaults.headers.common['Authorization'],

            },
        };
        return axios.post(url, formData, config);
    };

    isUserAuthenticated = () => {
        const user = this.getLoggedInUser();


        if (!user) {
            return false;
        }
        const decoded = jwtDecode(user.token);
        // const decoded: any = jwtDecode(user.token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            console.warn('access token expired');
            axios.post('/auth/refresh-tokens', { refreshToken: user.refreshToken }).then(res => {
                const tokens = res.data
                const userWithToken = { ...user, token: tokens.access.token, refreshToken: tokens.refresh.token };
                this.setLoggedInUser(userWithToken);
                setAuthorization(tokens.access.token)
            });
            return false;
        } else {
            return true;
        }
    };

    setLoggedInUser = (session) => { 

        if (session) localStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
        if (session) localStorage.setItem('firstUser', JSON.stringify(session));
        else {
            localStorage.removeItem(AUTH_SESSION_KEY);
        }    
    };


    getCurrentProfileStep = () => {
        let currentStep = localStorage.getItem('CurrentProfileStep');
        currentStep = JSON.parse(currentStep);
        if (currentStep) {
            return currentStep;
        } else {
            return 'PetUserDetails';
        }
    };
    setCurrentProfileStep = (session) => {
        if (session) localStorage.setItem('CurrentProfileStep', JSON.stringify(session));
        else {
            localStorage.removeItem('CurrentProfileStep');
        }
    };
    setCurrentPetProfile = (session) => {
        if (session) localStorage.setItem('CurrentPetProfile', JSON.stringify(session));
        else {
            localStorage.removeItem('CurrentPetProfile');
        }
    };

    getCurrentPetProfile = () => {
        let currentProfile = localStorage.getItem('CurrentPetProfile');
        currentProfile = JSON.parse(currentProfile);
        if (currentProfile) {
            return currentProfile;
        } else {
            return {};
        }
    };

    setCartItems = (session) => {
        if (session) localStorage.setItem('cartItems', JSON.stringify(session));
        else {
            localStorage.removeItem('cartItems');
        }
    };

    getCartItems = () => {
        let cartItems = localStorage.getItem('cartItems');
        
        cartItems = JSON.parse(cartItems);
        if (cartItems) {
            return cartItems;
        } else {
            return [] ;
            
        }
    };

x

    /**
     * Returns the logged in user
     */
    getLoggedInUser = () => {
            return getUserFromCookie();
    };

    setUserInSession = (modifiedUser) => {
        let userInfo = localStorage.getItem(AUTH_SESSION_KEY);
        if (userInfo) {
            const { token, user } = JSON.parse(userInfo);
            this.setLoggedInUser({ token, ...user, ...modifiedUser });
        }
    };
}

/*
Check if token available in session
*/
let user = getUserFromCookie();
if (user) {
    const { token } = user;
    if (token) {
        setAuthorization(token);
    }
}

export { APICore, setAuthorization };
