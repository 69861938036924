import { Col, Form, Row } from 'react-bootstrap';
import { hasError } from '../../../helpers/api/utils';

const OwnerDetails = (
  {
    formData,
    validationErrors,
    handleOnChange
  }
) => {
  
  return (
    <div className='step-form one' id="firstStep">
      <div className='step-form-inner'>
        <Row>
          <h1>Hello! Please tell us a little bit about yourself.</h1>
        </Row>
        <Row>
          <Col  md={6} xs={12}>
          <Form.Group as={Col}>
            <Form.Label>First name*</Form.Label>
            <Form.Control
              type="text"
              name="firstName"
              value={formData?.firstName || ''}
              onChange={(e) => { handleOnChange(e.target.name, e.target.value) }}
              placeholder="Your first name" />

            {hasError(validationErrors,'firstName')}
          </Form.Group>
        </Col>
        <Col md={6} xs={12}>
          <Form.Group as={Col}>
            <Form.Label>Last name*</Form.Label>
            <Form.Control
              type="text"
              name="lastName"
              value={formData?.lastName || ''}
              onChange={(e) => { handleOnChange(e.target.name, e.target.value) }}
              placeholder="Your last name" />

            {hasError(validationErrors, 'lastName')}
          </Form.Group>
          </Col>
        </Row>
        <Row>
        <Col md={6} xs={12}>
          <Form.Group as={Col}>
            <Form.Label>Email*</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData?.email || ''}
              onChange={(e) => { handleOnChange(e.target.name, e.target.value) }}
              placeholder="Email" />
            {hasError(validationErrors, 'email')}
          </Form.Group>
          </Col>
          <Col md={6} xs={12}>
          <Form.Group as={Col}>
            <Form.Label>Phone*</Form.Label>
            <div className='phone-number'>
              <Form.Control
                type="text"
                name="phone"
                value={formData?.phone || ''}
                onChange={(e) => { handleOnChange(e.target.name, e.target.value) }}
                placeholder="" />
              <div className='phone-code'><span>+91</span> </div>
            </div>
            {hasError(validationErrors, 'phone')}
          </Form.Group>
          </Col>
        </Row>
        <Row>
        <Col md={4} xs={12}>
          <Form.Group as={Col}>
            <Form.Label>State*</Form.Label>
            <Form.Control
              type="text"
              name="state"
              value={formData?.state || ''}
              onChange={(e) => { handleOnChange(e.target.name, e.target.value) }}
              placeholder="State" />
            {hasError(validationErrors, 'state')}
          </Form.Group>
          </Col>
          <Col md={4} xs={12}>
          <Form.Group as={Col} >
            <Form.Label>City*</Form.Label>
            <Form.Control
              type="text"
              name="city"
              value={formData?.city || ''}
              onChange={(e) => { handleOnChange(e.target.name, e.target.value) }}
              placeholder="City" />
            {hasError(validationErrors, 'city')}
          </Form.Group>
          </Col>
          <Col md={4}  xs={12}>
          <Form.Group as={Col} >
            <Form.Label>Pin Code*</Form.Label>
            <Form.Control
              type="number"
              name="pinCode"
              value={formData?.pinCode || ''}
              onChange={(e) => { handleOnChange(e.target.name, e.target.value) }}
              placeholder="Pin Code" />
            {hasError(validationErrors, 'pinCode')}
          </Form.Group>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default OwnerDetails;
