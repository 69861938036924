import { Button, Col, Container, Row } from "react-bootstrap";
import Images from "../../../../helpers/images";
import Select from 'react-select'
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { hasError, showToast } from "../../../../helpers/api/utils";
import { updatePetProfile, updateStep } from "../../../../redux/actions";

import dogBreeds from "../../../../helpers/dogBreeds.json"
import catBreeds from "../../../../helpers/catBreeds.json"
import PetProfiles from "../PetProfiles";

const PetBreed = () => {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)

  const { currentPetProfile } = useSelector((state) => ({
    currentPetProfile: state.PetProfile.currentPetProfile,
  }));

  useEffect(() => {
    if (currentPetProfile?.petType === 'Dog') {
      setOptions(dogBreeds)
    } else {
      setOptions(catBreeds)
    }
  }, [])

  const [formData, setFormData] = useState({
    breed: currentPetProfile?.breed ? currentPetProfile?.breed : '',
  });
  const [options, setOptions] = useState('Dog');
  const [validationErrors, setValidationErrors] = useState([]);

  const handleOnChange = (name, value) => {
    let errors = [];

    errors = validationErrors.filter(err => err.key !== name);
    setFormData((prv) => ({
      ...prv,
      [name]: value
    }))
    setValidationErrors(errors);
  }

  /**
   * Handles form validations
   */

  const checkValidations = (data) => {
    // console.log(data);
    const errors = []
    if (data.breed === '') {
      const error = {
        error: "Please provide your pet breed",
        key: "breed"
      };
      errors.push(error);
    }

    if (errors.length > 0) {

      setValidationErrors(errors);
    }
    return errors;
  }


  const handleSubmit = (e) => {
    setLoading(true)
    e.preventDefault();
    const hasError = checkValidations(formData);
    if (hasError.length > 0) {
      setLoading(false)
      return false;
    }
    dispatch(updatePetProfile(currentPetProfile?.id, formData, 'PetBirthday'));

  }
  const onClickBack = (e) => {
    dispatch(updateStep('PetGender'));
  }

  return (
    <>
      <div className="pet-info-section">
        <Container>
          <Row>
        <PetProfiles />
            
          </Row>
          <Row>
            <Col lg={12}>
              <div className="pet-info-inner">
                <div className="pet-info-form pet-breed-wrapper">
                  <div className="heading">
                    <h1>What breed is {currentPetProfile?.name}?</h1>
                  </div>
                  <Row>
                    <div className="pet-breed-select"> <Select value={formData?.breed ? { label: formData?.breed, value: formData?.breed } : []} onChange={(item) => { handleOnChange('breed', item.value) }} options={options} /></div>
                    <div className="text-center mt-1">
                      {hasError(validationErrors, `breed`)}
                    </div>
                  </Row>
                  <Row>
                    <div className="continue-btn" onClick={(e) => { !(loading) && handleSubmit(e) }} ><Button>{loading ? 'Please Wait...' : 'Continue'}</Button></div>
                    <div onClick={(e) => { onClickBack(e) }} className="back-btn"><Button ><i className="icon-forward"></i> Back</Button></div>
                  </Row>
                </div>
                <Row>
                  <div className="breed-images-section">
                    <div className="breed1"><img src={Images.breed1} className='img-fluid' alt='' /></div>
                    <div className="breed2"><img src={Images.breed2} className='img-fluid' alt='' /></div>
                  </div>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};


export default PetBreed;
