import { APICore } from './apiCore';
const api = new APICore();

function addNutritionistVet(params) {
    const baseUrl = `/nutritionist-vet`;
    return api.create(`${baseUrl}`, params);
}

export {
    addNutritionistVet
};
