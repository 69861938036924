import { APICore } from './apiCore';
const api = new APICore();

// category
function categories(params) {
    const baseUrl = `/category/allCategories`;
    return api.get(`${baseUrl}`, params);
}



export {
    categories
};
