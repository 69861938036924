import { APICore } from './apiCore';
const api = new APICore();

function subscribeEmail(params) {
    const baseUrl = `/subscribe-user-request`;
    return api.create(`${baseUrl}`,params);
}

export {
    subscribeEmail
}