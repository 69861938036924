// constants
import { AuthActionTypes } from './constants';



// common success
export const authApiResponseSuccess = (actionType, data) => ({
    type: AuthActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const authApiResponseError = (actionType, error) => ({
    type: AuthActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const loginUser = (phone) => ({
    type: AuthActionTypes.LOGIN_USER,
    payload: { phone },
});
export const loginAtCheckout = (phone) => ({
    type: AuthActionTypes.LOGIN_AT_CHECKOUT,
    payload: { phone },
});
export const loginAsGuestUser = () => ({
    type: AuthActionTypes.LOGIN_AS_GUEST,
    payload:{ }
});

export const validateUser = (otp, tempToken, referralCode,cartItem) => ({
    type: AuthActionTypes.VALIDATE_USER,
    payload: { otp, tempToken, referralCode,cartItem },
});

export const guestLogin = (otp, tempToken, referralCode, name, email,phone,userPets) => ({
    type: AuthActionTypes.GUEST_LOGIN,
    payload: { otp, tempToken, referralCode, name, email,phone,userPets},
});


export const updateUser = (profileData) => ({
    type: AuthActionTypes.UPDATE_USER,
    payload: profileData
});

export const logoutUser = () => ({
    type: AuthActionTypes.LOGOUT_USER,
    payload: {},
});

export const signupUser = (fullname, email, password) => ({
    type: AuthActionTypes.SIGNUP_USER,
    payload: { fullname, email, password },
});

export const forgotPassword = (email) => ({
    type: AuthActionTypes.FORGOT_PASSWORD,
    payload: { email },
});

export const resetAuth = () => ({
    type: AuthActionTypes.RESET,
    payload: {},
});

export const clearOtpToken = () => ({
    type: AuthActionTypes.CLEAR_OTP_TOKEN,
    payload: {},
});
export const clearAction = () => ({
    type: AuthActionTypes.CLEAR_ACTION,
    payload: {},
});
export const isLoading = () => ({
    type: AuthActionTypes.IS_LOADING,
    payload: {},
});

export const resendOtp = (tempId) => ({
    type: AuthActionTypes.RESEND_OTP,
    payload: { tempId },
});



