/* eslint-disable no-lone-blocks */
import { Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Cart from "./cart";
import { useState } from "react";
import { useSelector } from "react-redux";
import ClickLogin from "./ClickLogin";
const BottomBar = () => {
  const { user, userLoggedIn } = useSelector((state) => ({
    user: state.Auth.user,
    userLoggedIn: state.Auth.userLoggedIn,
  }));

  const navigate = useNavigate();

  const [login, loginSetShow] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    {
      userLoggedIn || user ? navigate("/wishlist") : loginSetShow(true);
    }
  };

  const changeState = (value) => {
    loginSetShow(value);
  };

  return (
    <div className="bottombar-wrapper">
      <Row className="g-0">
        <div className="bottom-nav">
          <ul>
            <li>
              {" "}
              <div className="nav-item">
                <Link to="/">
                  <i className="icon-home"></i> <span>Home</span>
                </Link>
              </div>
            </li>
            <li>
              {" "}
              <div className="nav-item">
                {" "}
                <Link to="/products">
                  <i className="icon-cart"></i> <span>Shop</span>
                </Link>
              </div>
            </li>
            <li>
              {" "}
              <div className="nav-item">
                <Link onClick={(e) => handleClick(e)}>
                  {" "}
                  <i className="icon-dog_paw"></i> <span>Wishlist</span>
                </Link>
              </div>
            </li>
            {login && (
              <ClickLogin showPopUp={login} changeState={changeState} />
            )}
            <li className="cart-btn">
              <div className="nav-item">
                {" "}
                <Cart /> <span>Cart</span>{" "}
              </div>
            </li>
          </ul>
        </div>
      </Row>
    </div>
  );
};

export default BottomBar;
