import React, { useEffect, useRef, useState } from "react";
import Header from "../../comman/header";
import Footer from "../../comman/footer";
import { useLocation, useNavigate } from "react-router-dom";
import EcommerceOrderDetails from "./EcommerceOrderDetails";
import MealOrderDetails from "./MealOrderDetails";
import ReactToPrint from "react-to-print";

const Invoice = () => {
  const location = useLocation();
  const { orderDetail } = location?.state ;
  const componentRef = useRef();
  const navigate = useNavigate();
  const [show,setShow]= useState(false);
  
  useEffect(()=>{
    <ReactToPrint
    removeAfterPrint={setShow(false)}
    print={window.print()}
    content={() => componentRef.current}
  />
  !show && navigate('/orders')
  
  },[])
  return (
    <>
      <div ref={componentRef}  className="order-details-wrapper">
        <div className="heading">
          <h2>Order details</h2>
        </div>
        {orderDetail?.orderType === 'product' && (
          <EcommerceOrderDetails
            orderDetail={orderDetail}
          />
        )}
        {orderDetail?.orderType === 'customizeMeal' && (
          <MealOrderDetails   orderDetail={orderDetail} />
        )}
        <div> 
         
            </div>

      </div>
    
   
    </>
  );
};
export default Invoice;
