export const categorySliderConfig = {
    dots: true,
    arrow: false,
    autoplay: true,
    infinite: true,
    rows: 2,
    speed: 800,
    slidesToShow: 5,
    slidesToScroll: 5,
    draggable: true,
    cssEase: 'ease-in-out',
    touchThreshold: 100,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScrol1: 1,
            },
        },
        {
            breakpoint: 1008,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                centerMode: false,
                centerPadding: '0px',
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: false,
                centerPadding: '0px',
            },
        },
    ],
};
export const productSliderConfig = {
    dots: false,
    arrows: true,
    autoplay: false,
    infinite: false,
    rows: 2,
    speed: 800,
    slidesToShow: 2,
    slidesToScroll: 2,
    draggable: true,
    cssEase: 'ease-in-out',
    touchThreshold: 100,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 2,
                slidesToScrol1: 2,
            },
        },
        {
            breakpoint: 1008,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                rows: 1,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                rows: 1,
            },
        },
    ],
};

export const homeSliderConfig = {
    dots: true,
   
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
};

export const homeFlashBarSliderConfig = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToScroll: 1,
    vertical:true,
   
};

export const testimonialsSliderConfig = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 2,
                slidesToScrol1: 1,
            },
        },
        {
            breakpoint: 1008,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: false,
                centerPadding: '0px',
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false,
                centerPadding: '0px',
            },
        },
    ],
    
};
export const groomingPackagesSliderConfig = {
    dots: false,
    arrow: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    autoplay: false,
    autoplaySpeed: 10000,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 2,
                slidesToScrol1: 1,
            },
        },
        {
            breakpoint: 1008,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: false,
                centerPadding: '0px',
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false,
                centerPadding: '0px',
            },
        },
    ],
};