import React, { useEffect, useState } from "react";
import { Badge, Container, Row, Table } from "react-bootstrap";

import { showToast } from "../../../helpers/api/utils";
import { getMyReferralHistory, orderList } from "../../../helpers";
import InlineLoader from "../../comman/InlineLoader";
import moment from "moment";

const Footer = React.lazy(() => import("../../comman/footer"));
const Header = React.lazy(() => import("../../comman/header"));

const ReferralHistory = () => {


  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  //used to get the order list 

  const fetchData = async () => {
    try {
      setLoading(true);
      const { data } = await getMyReferralHistory();
      setHistory(data.referralHistory);

      setLoading(false);
    } catch (error) {
      showToast("error", error?.error);
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <div className="order-history-wrapper">
        <Container>
          <Row>
            <div className="card-box">
              <h4>My Referral history</h4>
              <div className="table-responsive" >
                <Table bordered >
                  <thead>
                    <tr>
                      <th>Referral Points</th>
                      <th>Description</th>
                      <th>Transaction type</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>


                    <InlineLoader loading={loading} />
                    {history?.length > 0 && history?.map(item => (
                      <tr key={`history-${item?.id}`}>
                        <td>
                          <Badge bg={`${item.transactionType === 'Credit' ?"success" :'danger' }`} >{item?.points}</Badge>
                        </td>
                        <td>{item?.description}</td>
                        <td>
                          <Badge bg={`${item.transactionType === 'Credit' ?   'success' : 'danger'}`} >{item.transactionType}</Badge></td>
                         
                        <td>{moment(item?.createdAt).format('DD-MM-YYYY h:m a')}</td>
                      </tr>
                    ))}

                  </tbody>
                </Table>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};
export default ReferralHistory;
