/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// actions
import { loginUser, validateUser, clearOtpToken } from "../../redux/actions";

import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import { showToast } from "../../helpers/api/utils";
import OtpInput from "react-otp-input";

const ClickLogin = ({ showPopUp, changeState,clickTextValue }) => {
  const dispatch = useDispatch();

  const [login] = useState(showPopUp);
  const [continueButtonDisabled, setContinueButtonDisabled] = useState(false);
  const [cancelButtonDisabled, setCancelButtonDisabled] = useState(false);
  const [currentStep, setCurrentStep] = useState(1)
  const navigate = useNavigate();

  const loginModalClose = () => {
    changeState(false);
    dispatch(clearOtpToken());
    setCurrentStep(1)
    setContinueButtonDisabled(true)
  };
  // const showLogin = () => loginSetShow(true);
  const [phone, setPhone] = useState("");
  const [toc, setToc] = useState(true);
  const [otp, setOtp] = useState("");
  const [seconds, setSeconds] = useState(0);

  const { loading, tempToken, user } = useSelector(
    (state) => ({
      user: state.Auth.user,
      message: state.Auth.message,
      tempToken: state.Auth.tempToken,
      error: state.Auth.error,
      loading: state.Auth.loading,
      userLoggedIn: state.Auth.userLoggedIn,
      actionPerformed: state.Auth.actionPerformed,
      // ShowUpdatedMessage: state.Auth.ShowUpdatedMessage,
    })
  );

  /*
       handle form submission
    */


       useEffect(()=>{
        setCurrentStep(1);
    },[])

    
  const handelNumber = (e) => {
    e.preventDefault();
    if(e.nativeEvent.data=== "+" || e.nativeEvent.data=== "-" || e.nativeEvent.data==="."){
      return;
}
    if (e.target.value.length <= 10) {
      setPhone(e.target.value);
    }
  };

  const handleOtp = (val) => {
    if (val.length <= 4) {
      setOtp(val);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    toast.dismiss();
    setSeconds(30);

    if (!phone) {
      showToast("error", "Please enter mobile number.");
      return;
    }
    const validatePhone = /^[6-9]{1}[0-9]{9}$/;
    if (!validatePhone.test(phone)) {
      showToast("error", "Please enter valid mobile number.");
      return;
    }

    if (!toc) {
      showToast("error", "Please agree to the terms and conditions.");
      return;
    }

    dispatch(loginUser(phone));
  };

  const handleSubmitOtp = (e) => {
    e.preventDefault();
    toast.dismiss();
     dispatch(validateUser(otp, tempToken));
     setCancelButtonDisabled(true);
  };

  useEffect(() => {
    if (user?.status==="ACTIVE") {
      changeState(false);
      clickTextValue==="referAndEarn" ? navigate("/refer-and-earn"): clickTextValue==="Wishlist" ?navigate("/wishlist"):navigate("/pet-profile") 
    }
  }, [handleSubmitOtp]);

  useEffect(
    () => {
      const Timer = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
      }, 1000);
      return () => clearInterval(Timer);
    },
    [onSubmit], [seconds]
  );

  return (
    <>
      {/* <div onClick={showLogin} className="login-btn">Sign in </div> */}

      <Modal
        size="sm"
        className="login-modal"
        centered
        show={login}
        onHide={loginModalClose}
      >
        <Modal.Body>
          <div className="login-wrapper">
            {currentStep===1 &&  (
              <>
                <div className="heading">
                  <h2>Enter mobile number</h2>
                </div>
                <Form>
                  <div className="form-grup">
                    <div className="phone-number">
                      <input
                        type="number"
                        value={phone}
                        onChange={(e) => {
                          handelNumber(e);
                        }}
                        className="form-control"
                        placeholder="Enter your mobile number"
                        maxLength="10"
                      />
                      <label className="code">+91</label>
                    </div>
                  </div>
                  <div className="form-grup checkbox-wrapper">
                    <input
                      type="checkbox"
                      id="termsandconditions"
                      checked={toc ? "checked" : ""}
                      onChange={(e) => setToc(!toc)}
                    />
                    <label htmlFor="termsandconditions">
                      I agree to the <Link to="/legal?title=privacy-policy">terms and conditions.</Link>
                    </label>
                  </div>
                  <div className="btn-section">
                    <div onClick={loginModalClose} className="cancelbtn">
                      <span>Cancel</span>
                    </div>
                    <div
                      onClick={(e) => {
                        !loading ? onSubmit(e) : e.preventDefault();
                      }}
                      className="sendbtn"
                    >
                      <span>{loading ? "Wait.." : "Send"}</span>
                    </div>
                  </div>
                </Form>
              </>
            )}  { currentStep===2 &&(
              <>
                <div className="heading">
                  <h2>Enter your code</h2>
                  <p>
                    We sent an SMS with a 4-digit code to <b>{phone}</b>
                  </p>
                </div>
                <Form>
                  <div className="form-grup">
                    <div className="phone-number otp-screen">
                      <OtpInput
                        shouldAutoFocus={true}
                        value={otp}
                        onChange={(val) => handleOtp(val)}
                        numInputs={4}
                        inputStyle={false}
                        inputType="number"
                        renderInput={(props) => <input {...props} />}
                      />
                      {/* <input type="number" value={otp} onChange={(e) => handleOtp(e.target.value)} className="otp" maxLength={4} /> */}
                    </div>
                  </div>
                  <div className="time-wrapper">
                    <p>
                      Resend in <span>{seconds}</span>
                    </p>
                  </div>
                  <div className="btn-section">
                    <div  disabled={cancelButtonDisabled} onClick={loginModalClose} className="cancelbtn">
                      <span>Cancel</span>
                    </div>
                    <div
                       disabled={continueButtonDisabled} onClick={(e) => {
                        !loading ? handleSubmitOtp(e) : e.preventDefault();
                      }}
                      className="sendbtn"
                    >
                      <span>{loading ? "Wait.." : "Continue"}</span>
                    </div>
                  </div>
                </Form>
              </>
            )}
          </div>

          {tempToken !== ""}
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ClickLogin;
