/* eslint-disable no-empty-pattern */
import { all, fork, put, takeEvery, call } from "redux-saga/effects";

// helpers
import {
  petProfiles as petProfilesApi,
  updatePetProfile as updatePetProfileApi,
  deletePetProfile as deletePetProfileApi,
  updateUser as updateUserApi,
  addPetProfile as addPetProfileApi,
  updateCurrentProfile as updateCurrentProfileApi,
  addMealToPetProfile as addMealToPetProfileApi,
  proceedToCheckout as proceedToCheckoutApi,
} from "../../helpers";

// actions
import {
  petProfileApiResponseSuccess,
  petProfileApiResponseError,
} from "./actions";

// constants
import { PetProfileActionTypes } from "./constants";
import { APICore } from "../../helpers/api/apiCore";

const api = new APICore();

/**
 * PetProfile list
 */
function* petProfileList() {
  try {
    const response = yield call(petProfilesApi, {});
    const responseData = {
      data: response.data.results,
      page: response.data.page,
      limit: response.data.limit,
      totalPages: response.data.totalPages,
      totalResults: response.data.totalResults,
    };
    yield put(
      petProfileApiResponseSuccess({
        actionType: PetProfileActionTypes.PET_PROFILE_LIST,
        responseData,
      })
    );
  } catch (error) {
    yield put(
      petProfileApiResponseError(PetProfileActionTypes.PET_PROFILE_LIST, error)
    );
  }
}

/**
 * Update PetProfile
 */
function* updatePetProfile({ payload: { id, params, nextPetProfileStep } }) {
  try {
    const response = yield call(
      updatePetProfileApi,
      { ...params, nextPetProfileStep },
      id
    );
    const responseData = {
      data: response.data.data,
      nextPetProfileStep: nextPetProfileStep,
    };

    api.setCurrentPetProfile(response.data.data);
    api.setCurrentProfileStep(nextPetProfileStep);
    yield put(
      petProfileApiResponseSuccess({
        actionType: PetProfileActionTypes.UPDATE_PET_PROFILE,
        responseData,
      })
    );
  } catch (error) {
    yield put(
      petProfileApiResponseError(
        PetProfileActionTypes.UPDATE_PET_PROFILE,
        error
      )
    );
  }
}
/**
 * DELETE PetProfile
 */
function* deletePetProfile({ payload: { petProfileId } }) {
  try {
    const response = yield call(
      deletePetProfileApi, petProfileId
    );
    if (response.data.status) {

      const user = api.getLoggedInUser();
      let currentProfile = api.getCurrentPetProfile();
      if (currentProfile.id === petProfileId) {
        currentProfile = {};
        api.setCurrentPetProfile(currentProfile);
      }
      const updatedPetProfiles = user.allPetProfiles.filter(item => item.id !== petProfileId);
      api.setLoggedInUser({
        ...user,
        currentPetProfile: currentProfile,
        allPetProfiles: updatedPetProfiles
      });
      const responseData = {
        petProfileId,
        updatedPetProfiles
      };
      console.log(responseData);
      yield put(
        petProfileApiResponseSuccess({
          actionType: PetProfileActionTypes.DELETE_PET_PROFILE,
          responseData,
        })
      );
    }



    // /api.setCurrentProfileStep(nextPetProfileStep);

  } catch (error) {
    yield put(
      petProfileApiResponseError(
        PetProfileActionTypes.DELETE_PET_PROFILE,
        error
      )
    );
  }
}
/**
 * Update Meal Plans for currentProfile
 */
function* addMealPlansForCurrentProfile({
  payload: { id, params, nextPetProfileStep },
}) {
  try {
    const currentProfile = api.getCurrentPetProfile();
    const updatedPetProfile = {
      ...currentProfile,
      meals: params,
      isCompleted: "Complete",
    };
    const response = yield call(addMealToPetProfileApi, {
      currentPetProfile: updatedPetProfile,
    });
    const responseData = {
      allProfiles: response?.data?.allProfiles,
      currentPetProfile: response?.data?.currentPetProfile,
      currentProfileStep: response?.data?.currentProfileStep,
    };
    const user = api.getLoggedInUser();
    const updatedUser = {
      ...user,
      currentPetProfile: responseData?.currentPetProfile,
      currentProfileStep: responseData?.currentProfileStep,
      allPetProfiles: responseData?.allProfiles,
    };
    api.setLoggedInUser(updatedUser);
    api.setCurrentPetProfile(responseData?.currentPetProfile);
    api.setCurrentProfileStep(responseData?.currentProfileStep);

    yield put(
      petProfileApiResponseSuccess({
        actionType: PetProfileActionTypes.ADD_MEAL_PLAN_PET_PROFILE,
        responseData,
      })
    );
  } catch (error) {
    yield put(
      petProfileApiResponseError(
        PetProfileActionTypes.ADD_MEAL_PLAN_PET_PROFILE,
        error
      )
    );
  }
}

/*
PROCEED TO CHECKOUT (SKIP OTHER PET-PROFILES)
*/

function* proceedToCheckout({
  payload: { id, params, nextPetProfileStep },
}) {
  try {
    const currentProfile = api.getCurrentPetProfile();
    const updatedPetProfile = {
      ...currentProfile,
      meals: params,
      isCompleted: "Complete",
    };
    const response = yield call(proceedToCheckoutApi, {
      currentPetProfile: updatedPetProfile,
    });
    const responseData = {
      allProfiles: response?.data?.allProfiles,
      currentPetProfile: response?.data?.currentPetProfile,
      currentProfileStep: response?.data?.currentProfileStep,
    };

    const user = api.getLoggedInUser();
    const updatedUser = {
      ...user,
      currentPetProfile: responseData?.currentPetProfile,
      currentProfileStep: responseData?.currentProfileStep,
      allPetProfiles: responseData?.allProfiles,
    };

    api.setLoggedInUser(updatedUser);
    api.setCurrentPetProfile(responseData?.currentPetProfile);
    api.setCurrentProfileStep(nextPetProfileStep);

    yield put(
      petProfileApiResponseSuccess({
        actionType: PetProfileActionTypes.PROCEED_TO_CHECKOUT,
        responseData,
      })
    );
  } catch (error) {
    yield put(
      petProfileApiResponseError(
        PetProfileActionTypes.PROCEED_TO_CHECKOUT,
        error
      )
    );
  }
}

/**
 * Create PetProfile
 */
function* createPetProfile({ payload }) {
  const CurrentProfileOfPet = api.getCurrentPetProfile();
  try {
    const response = yield call(addPetProfileApi, {
      ...payload,
      currentProfileStep: CurrentProfileOfPet.currentStep || "PetGender",
    });
    const responseData = {
      data: response.data.data,
      currentPetProfile: response.data.user.currentPetProfile,
      currentProfileStep: response.data.user.currentProfileStep,
    };
    const loginUser = api.getLoggedInUser();
    const updatedUser = { ...loginUser, allPetProfiles: response.data.data };
    api.setLoggedInUser(updatedUser);
    api.setCurrentPetProfile(response.data.user.currentPetProfile);
    api.setCurrentProfileStep(response.data.user.currentProfileStep);
    yield put(
      petProfileApiResponseSuccess({
        actionType: PetProfileActionTypes.ADD_PET_PROFILE,
        responseData,
      })
    );
  } catch (error) {
    yield put(
      petProfileApiResponseError(PetProfileActionTypes.ADD_PET_PROFILE, error)
    );
  }
}
/**
 * Create PetProfile
 */
function* moveToGiveProfileStep({ payload }) {
  try {
    yield call(updateUserApi, {
      currentProfileStep: payload.nextPetProfileStep,
    });
    const responseData = {
      currentProfileStep: payload.nextPetProfileStep,
      currentPetProfile: api.getCurrentPetProfile(),
      petProfiles: api.getLoggedInUser()?.allPetProfiles,
    };
    // console.log(responseData);
    api.setCurrentProfileStep(payload.nextPetProfileStep);
    yield put(
      petProfileApiResponseSuccess({
        actionType: PetProfileActionTypes.SET_UP_PET_PROFILE,
        responseData,
      })
    );
  } catch (error) {
    yield put(
      petProfileApiResponseError(
        PetProfileActionTypes.SET_UP_PET_PROFILE,
        error
      )
    );
  }
}
/**
 * Create PetProfile
 */
function* resetActions({ }) {
  try {
    yield put(
      petProfileApiResponseSuccess({
        actionType: PetProfileActionTypes.RESET_ACTION,
      })
    );
  } catch (error) {
    yield put(
      petProfileApiResponseError(PetProfileActionTypes.RESET_ACTION, error)
    );
  }
}
/**
 * Create PetProfile
 */
function* updateStep({ payload }) {
  try {
    const responseData = {
      currentStep: payload.step,
    };
    api.setCurrentProfileStep(payload.step);
    yield put(
      petProfileApiResponseSuccess({
        actionType: PetProfileActionTypes.UPDATE_STEP,
        responseData,
      })
    );
  } catch (error) {
    yield put(
      petProfileApiResponseError(PetProfileActionTypes.RESET_ACTION, error)
    );
  }
}
/**
 * Create PetProfile
 */
function* makeLoading({ }) {
  try {
    const responseData = {};
    yield put(
      petProfileApiResponseSuccess({
        actionType: PetProfileActionTypes.IS_LOADING_PET_PROFILE,
        responseData,
      })
    );
  } catch (error) {
    yield put(
      petProfileApiResponseError(
        PetProfileActionTypes.IS_LOADING_PET_PROFILE,
        error
      )
    );
  }
}

/**
 * Update PetProfile
 */
function* updateCurrentProfile({ payload: { step, profile } }) {
  try {
    yield call(updateCurrentProfileApi, { profile, step });
    const user = api.getLoggedInUser();
    const allProfiles = user?.allPetProfiles;
    const updatedProfiles = allProfiles.map((item) =>
      item.id === profile.id ? profile : item
    );
    const updatedUser = { ...user, allPetProfiles: updatedProfiles };
    api.setLoggedInUser(updatedUser);
    api.setCurrentPetProfile(profile);
    api.setCurrentProfileStep(step);
    const responseData = {
      step,
      profile,
      updatedProfiles,
    };

    yield put(
      petProfileApiResponseSuccess({
        actionType: PetProfileActionTypes.UPDATE_CURRENT_PROFILE,
        responseData,
      })
    );
  } catch (error) {
    yield put(
      petProfileApiResponseError(
        PetProfileActionTypes.UPDATE_CURRENT_PROFILE,
        error
      )
    );
  }
}
/**
 * Update allProfiles
 */
function* updateAllCurrentProfile({ payload: { profiles } }) {
  try {
    const responseData = {
      profiles,
    };
    yield put(
      petProfileApiResponseSuccess({
        actionType: PetProfileActionTypes.UPDATE_ALL_PET_PROFILE,
        responseData,
      })
    );
  } catch (error) {
    yield put(
      petProfileApiResponseError(
        PetProfileActionTypes.UPDATE_ALL_PET_PROFILE,
        error
      )
    );
  }
}


export function* watchPetProfileList() {
  yield takeEvery(PetProfileActionTypes.PET_PROFILE_LIST, petProfileList);
}

export function* watchCreatePetProfile() {
  yield takeEvery(PetProfileActionTypes.ADD_PET_PROFILE, createPetProfile);
}

export function* watchUpdatePetProfile() {
  yield takeEvery(PetProfileActionTypes.UPDATE_PET_PROFILE, updatePetProfile);
}

export function* watchDeletePetProfile() {
  yield takeEvery(PetProfileActionTypes.DELETE_PET_PROFILE, deletePetProfile);
}

export function* watchAddMealPlansForCurrentProfile() {
  yield takeEvery(
    PetProfileActionTypes.ADD_MEAL_PLAN_PET_PROFILE,
    addMealPlansForCurrentProfile
  );
}
export function* watchMoveToGiveProfileStep() {
  yield takeEvery(
    PetProfileActionTypes.SET_UP_PET_PROFILE,
    moveToGiveProfileStep
  );
}
export function* watchResetActions() {
  yield takeEvery(PetProfileActionTypes.RESET_ACTION, resetActions);
}
export function* watchUpdateStep() {
  yield takeEvery(PetProfileActionTypes.UPDATE_STEP, updateStep);
}
export function* watchMakeLoading() {
  yield takeEvery(PetProfileActionTypes.IS_LOADING_PET_PROFILE, makeLoading);
}
export function* watchUpdateCurrentProfile() {
  yield takeEvery(
    PetProfileActionTypes.UPDATE_CURRENT_PROFILE,
    updateCurrentProfile
  );
}

export function* watchUpdateAllCurrentProfile() {
  yield takeEvery(
    PetProfileActionTypes.UPDATE_ALL_PET_PROFILE,
    updateAllCurrentProfile
  );
}
export function* watchProceedToChekout() {
  yield takeEvery(
    PetProfileActionTypes.PROCEED_TO_CHECKOUT,
    proceedToCheckout
  );
}

function* petProfileSaga() {
  yield all([
    fork(watchPetProfileList),
    fork(watchUpdatePetProfile),
    fork(watchCreatePetProfile),
    fork(watchAddMealPlansForCurrentProfile),
    fork(watchMoveToGiveProfileStep),
    fork(watchResetActions),
    fork(watchMakeLoading),
    fork(watchUpdateStep),
    fork(watchUpdateCurrentProfile),
    fork(watchUpdateAllCurrentProfile),
    fork(watchProceedToChekout),
    fork(watchDeletePetProfile),
  ]);
}

export default petProfileSaga;
