import React, { useEffect, useState } from "react";
import HomeDiscountModel from "./HomeDiscoutModel";
import DiscountUserDetails from "./DiscountUserDetails";
import ThanksDiscountModel from "./ThanksDiscountModel";
import { useDispatch, useSelector } from "react-redux";
import { loginAsGuestUser } from "../../redux/actions";
import { discountCoupon } from "../../helpers";
import { showToast } from "../../helpers/api/utils";
import { APICore } from "../../helpers/api/apiCore";

export default function DiscountModel() {
  const [currentStep, setCurrentStep] = useState(1);
  const [scroll, setScroll] = useState(false);
  const [formData, setFormData] = useState({ HavingPetParent: "Yes" });
  const [validationErrors, setValidationErrors] = useState([]);
  const [firstTime, setIsFirstTime] = useState(true);
  const [showModel, setShowModel] = useState(true);
  const [loading,  setLoading] = useState(false);
  const steps = 3;
  //  HomeDiscountModel Handling


  const checkScrollPosition = () => {
    if (window.scrollY > 1200) {
      setScroll(true);
    }
  };

  const { user } = useSelector((state) => ({
    user: state.Auth.user,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener("scroll", checkScrollPosition);

    if (decodeURIComponent(document.cookie) === "") {
      let date = new Date();
      const afterOneYear = new Date(
        date.getFullYear() + 1,
        date.getMonth(),
        date.getDate()
      );
      dispatch(loginAsGuestUser());
      date.setTime(afterOneYear.getTime());
      document.cookie =
        "isFirstTime" + " = " + "true" + "; expires = " + date.toGMTString();
      document.cookie =
        "isFirstUser" + " = " + "true" + "; expires = " + date.toGMTString();
    } else {
      const getCookie = decodeURIComponent(document.cookie).split(";");
      const getCookieValue = getCookie[0].split("=");
      const isTrue = getCookieValue[1];
      setIsFirstTime(!isTrue);
    }

    return () => {
      window.removeEventListener("scroll", checkScrollPosition);
    };
  }, []);

  const handleSubmit = async () => {
    setLoading(true)
    const { data } = await discountCoupon({ phone: formData?.phone });
    if (!data?.message[0]) {
      setShowModel(false);
      showToast("success", data?.message[1]);
      setLoading(false)
      return ;  
    }
    else{
      setLoading(false)
      setCurrentStep(3);
    }
  };
  const handleNext = () => {
    if (currentStep === 2) {
      const hasError = firstcheckValidations(formData);
      if (hasError?.length > 0) {
        return false;
    } else {
       handleSubmit();
      }
    }
    else {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const firstcheckValidations = (data) => {
    const errors = [];
    if (data.petDob === undefined || data.petDob === "") {
      const error = {
        error: "Please provide your  pet birtday ",
        key: "petDob",
      };
      errors.push(error);
    }
    const validatePhone = /^[6-9]{1}[0-9]{9}$/;
    if (!validatePhone.test(data.phone)) {
      const error = {
        error: "Please provide your valid phone number ",
        key: "phone",
      };
      errors.push(error);
    }
    if (errors.length > 0) {
      setValidationErrors(errors);
    }
    return errors;
  };
  const handleChange = (name, value) => {
    let errors = [];
    errors = validationErrors?.filter((err) => err.key !== name);
    if (name === "phone") {
      if (value.length <= 10) {
        setFormData((prv) => ({
          ...prv,
          [name]: value,
        }));
      } else {
        return;
      }
      setValidationErrors(errors);
    } else {
      setFormData((prevstate) => ({
        ...prevstate,
        [name]: value,
      }));
      setValidationErrors(errors);
    }
  };

  return (
    <>
      {currentStep === 1 && (
        <HomeDiscountModel
          setCurrentStep={setCurrentStep}
          handleChange={handleChange}
          scroll={scroll}
          firstTime={firstTime}
          formData={formData}
          setShowModel={setShowModel}
          showModel={showModel}
        />
      )}
      {currentStep === 2 && (
        <DiscountUserDetails
          setCurrentStep={setCurrentStep}
          handleChange={handleChange}
          formData={formData}
          validationErrors={validationErrors}
          handleNext={handleNext}
          setShowModel={setShowModel}
          showModel={showModel}
          loading={loading}  
          setLoading={ setLoading}
        />
      )}
      {currentStep === 3 && showModel && (
        <ThanksDiscountModel
          setShowModel={setShowModel}
          showModel={showModel}
        />
      )}
    </>
  );
}
